import theme from "@/config/theme";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import {
  Button,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  ListItemIcon,
  Divider
} from "@material-ui/core";
import { Menu, NavigateNext, Close } from "@material-ui/icons";
import { useState } from "react";
import * as React from "react";
import styled from "styled-components";
import { AccountMenu } from "./AccountMenu";
import { NavLink } from "./NavLink";
import { SiteLink } from "./SiteLink";

interface Props {
  collapse?: "responsive" | boolean;
  side?: "left" | "right";
}

const NavContainer = styled.nav`
  & > *:not(:last-child) {
    margin-right: 24px;
    display: inline-block;
  }
`;

const navItems = [
  { href: "/", label: "Book Helpers" },
  {
    href: "https://hallopr.com/features",
    label: "How it works",
    target: "_blank"
  },
  {
    href: "https://hallopr.com/get-to-know-hallo",
    label: "About Hallo",
    target: "_blank"
  }
];

const loginNavItems = [
  { href: "/auth/sign-in", label: "Sign in" },
  { href: "/auth/join", label: "Join" }
];

const DrawerList = styled(List)`
  && {
    max-width: 100vw;
    width: 300px;
  }
`;

const ListNavItem = ({ item }: any) => {
  return (
    <ListItem component={SiteLink} button href={item.href} target={item.target}>
      <ListItemText primary={item.label} />
    </ListItem>
  );
};

export const Navigation = ({
  collapse = "responsive",
  side = "right"
}: Props) => {
  const user = useCurrentUser();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [openNav, setOpenNav] = useState(false);

  if (collapse === "responsive" ? isMobileScreen : collapse) {
    return (
      <>
        <Drawer anchor={side} open={openNav} onClose={() => setOpenNav(false)}>
          <DrawerList>
            <ListItem>
              <IconButton onClick={() => setOpenNav(false)}>
                <Close />
              </IconButton>
            </ListItem>
            <Divider component="li" />
            {navItems.map(item => (
              <ListNavItem key={item.href} item={item} />
            ))}
            {user == null && (
              <>
                {loginNavItems.map(item => (
                  <ListNavItem key={item.href} item={item} />
                ))}
              </>
            )}
          </DrawerList>
          {user != null && (
            <>
              <Divider />
              <AccountMenu />
            </>
          )}
        </Drawer>
        <IconButton
          onClick={() => setOpenNav(true)}
          color="inherit"
          size="medium"
        >
          <Menu />
        </IconButton>
      </>
    );
  }

  return (
    <NavContainer>
      <NavLink href="https://hallopr.com/features" target="_blank">
        How it works
      </NavLink>
      <NavLink href="https://hallopr.com/get-to-know-hallo" target="_blank">
        About Hallo
      </NavLink>
      {user != null ? (
        <AccountMenu />
      ) : (
        <>
          <NavLink href="/auth/sign-in">Sign In</NavLink>
          <Button
            href="/auth/join"
            variant="contained"
            color="primary"
            component={SiteLink}
          >
            Join
          </Button>
        </>
      )}
    </NavContainer>
  );
};
