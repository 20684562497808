import {
  Button,
  CardActions,
  CardContent,
  Typography,
  LinearProgress
} from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { firebaseApp } from "@/config/firebase";
import { useAsyncCallback } from "@/hooks/useAsyncCallback";
import { useRequireUser } from "@/hooks/useCurrentUser";
import { makeRule, rules, useForm } from "@/hooks/useForm";
import { withProps } from "../utils/withProps";
import { TextFormField } from "./TextFormField";
import { SuccessMessage } from "./SuccessMessage";
import { SiteDialog } from "@/components/SiteDialog";
import firebase = require("firebase/app");

interface Props {
  onClose: () => void;
}

const resetEmail = (currentPassword: string, newEmail: string) => {
  const user = firebaseApp.auth().currentUser;
  const cred = (firebase as any).default.auth.EmailAuthProvider.credential(
    user.email,
    currentPassword
  );
  return user.reauthenticateWithCredential(cred).then(
    () => {
      const user = firebaseApp.auth().currentUser;
      return user.updateEmail(newEmail).then(() => {
        return firebaseApp
          .firestore()
          .doc(`users/${user.uid}`)
          .update({ email: newEmail });
      });
    },
    () => {
      return "password-incorrect";
    }
  );
};

const Container = styled(CardContent)`
  width: 400px;
  max-width: 100vw;
  display: flex;
  flex-grow: 1;
  margin-bottom: auto;
  flex-direction: column;
  align-items: stretch;
`;

const Header = styled(Typography)`
  && {
    margin-bottom: 16px;
  }
`;

const variant = "outlined";
const margin = "normal";

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const resetEmailFields = {
  password: {
    field: withProps(TextFormField, {
      type: "password",
      label: "Current Password",
      variant,
      margin
    }),
    getErrors: rules(
      makeRule(
        "Please enter a your current password",
        password => password != ""
      )
    )
  },
  newEmail: {
    field: withProps(TextFormField, {
      label: "New Email",
      variant,
      margin
    }),
    getErrors: rules(
      makeRule("Please enter a valid email", (email: string) =>
        emailRegex.test(email)
      )
    )
  }
};

const defaultValue = { newEmail: "", password: "" };

export const ChangeEmail = ({ onClose }: Props) => {
  useRequireUser();
  const [status, setStatus] = useState<
    "loading" | "password-incorrect" | "success" | null
  >(null);

  const [fields, value, errors, { setShowErrors }] = useForm(
    resetEmailFields,
    defaultValue
  );

  const onSubmit = useAsyncCallback(async () => {
    if (errors.length > 0) {
      setShowErrors(true);
    } else {
      setStatus("loading");

      const result = await resetEmail(value.password, value.newEmail);

      if (result === "password-incorrect") {
        setStatus("password-incorrect");
      } else {
        setStatus("success");
      }
    }
  }, [setShowErrors, errors]);

  return (
    <SiteDialog open={true}>
      {status == "success" ? (
        <CardContent>
          <SuccessMessage
            title="Done. Your email has been updated."
            message={
              <Button color="primary" onClick={onClose}>
                Close
              </Button>
            }
          />
        </CardContent>
      ) : (
        <>
          <Container>
            <Header variant="h6">Change your email</Header>
            {status === "password-incorrect" && (
              <Typography variant="caption" gutterBottom color="error">
                Your current password is incorrect.
              </Typography>
            )}
            {fields.password}
            {fields.newEmail}
          </Container>
          <CardActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              disabled={status === "loading"}
              style={{ marginLeft: "auto" }}
              onClick={onSubmit}
              color="primary"
            >
              Save
            </Button>
          </CardActions>
          {status === "loading" && <LinearProgress />}
        </>
      )}
    </SiteDialog>
  );
};
