import React from "react";
import styled from "styled-components";
import {
  Card,
  CardMedia,
  Typography,
  CardContent,
  CardActions,
  Button,
  CardActionArea,
  Paper,
  Chip,
  Grid
} from "@material-ui/core";
import { SiteLink } from "./SiteLink";

const ImageContainer = styled(CardMedia)`
  padding-top: 56.25%;
`;

const ServiceTypeTag = styled(Paper).attrs({ elevation: 2 })`
  && {
    background-color: #fff;
    border-radius: 0 8px 8px 0px;
    display: inline-block;
    padding: 4px 16px;
    margin-bottom: 16px;
  }
`;

const CardHeader = styled(Grid)`
  margin-bottom: 8px;
`;

const ServiceChip = styled(Chip)`
  margin-left: 8px;
`;

export const ProviderSearchResult = ({ provider }) => {
  return (
    <Card>
      <CardActionArea component={SiteLink} href={`/service/${provider.id}`}>
        <ImageContainer
          image={provider.featureImages && provider.featureImages[0]}
        />
        <CardContent>
          <CardHeader container alignItems="center">
            <Typography variant="h5" component="h2">
              {provider.name}
            </Typography>

            <ServiceChip size="small" label={provider.serviceShortName} />
          </CardHeader>

          <Typography variant="body2" color="textSecondary" component="p">
            {provider.serviceDescription}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          color="primary"
          component={SiteLink}
          href={`/book-appointment/${provider.id}`}
        >
          Book Appointment
        </Button>
      </CardActions>
    </Card>
  );
};
