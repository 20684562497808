import * as React from "react";
import { Dialog, useMediaQuery } from "@material-ui/core";
import theme from "@/config/theme";

export const SiteDialog = ({ open, ...props }) => {
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <Dialog
      {...props}
      fullScreen={fullScreen}
      open={open === false ? false : true}
    />
  );
};
