import * as React from "react";
import { PropsOf } from "@/utils/types";
import { TextField, FormFieldGroup, Checkbox } from "@material-ui/core";
import styled, { css } from "styled-components";
import { makeFormField } from "../utils/makeFormField";

const normalMargin = css`
  && {
    margin-bottom: ${(props: PropsOf<typeof TextField>) =>
      props.helperText ? "0" : "20px"};
    margin-top: 8px;
  }
`;

const Field = styled(Checkbox)`
  ${props => props.margin === "normal" && normalMargin}
`;

export const CheckboxFormField = makeFormField<
  string,
  PropsOf<typeof Checkbox>
>(
  ({
    onSetPristine,
    margin = "dense",
    errors,
    isError,
    value,
    onChange,
    ...props
  }) => {
    return (
      <FormFieldGroup>
        <Field
          error={isError}
          onBlur={() => onSetPristine(false)}
          helperText={isError && errors[0]}
          margin={margin}
          checked={value}
          onChange={({ target: { checked } }) =>
            onChange({ target: { value: checked } })
          }
          {...props}
        />
      </FormFieldGroup>
    );
  }
);
