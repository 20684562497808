import { useCallback, useState } from "react";

export const useAsyncCallback = <Args extends any[]>(
  callback: (...args: Args) => Promise<any>,
  memo: any[]
) => {
  const [error, setError] = useState();

  if (error !== undefined) {
    throw error;
  }

  return useCallback((...args: Args) => {
    return Promise.resolve(callback(...args)).catch(e => {
      setError(e);
    });
  }, memo);
};
