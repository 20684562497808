import * as React from "react";

interface Props {
  fallback: () => React.ReactNode;
}

export class ErrorBoundary extends React.Component<Props> {
  state = {
    error: false
  };

  static getDerivedStateFromError(error: any) {
    console.error(error);
    return { error: true };
  }

  render() {
    if (this.state.error) {
      return this.props.fallback();
    }

    return this.props.children;
  }
}
