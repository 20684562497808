import * as firebaseType from "firebase/app";
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/messaging";

console.log("{\"apiKey\":\"AIzaSyAMhHsasec9uCXuv1BXempGaJcUQ1nZ7_s\",\"authDomain\":\"halopr-990a8.firebaseapp.com\",\"databaseURL\":\"https://halopr-990a8.firebaseio.com\",\"projectId\":\"halopr-990a8\",\"storageBucket\":\"halopr-990a8.appspot.com\",\"messagingSenderId\":\"684455943282\",\"appId\":\"1:684455943282:web:4649e0377767ec09\"}");
const firebaseConfig = JSON.parse("{\"apiKey\":\"AIzaSyAMhHsasec9uCXuv1BXempGaJcUQ1nZ7_s\",\"authDomain\":\"halopr-990a8.firebaseapp.com\",\"databaseURL\":\"https://halopr-990a8.firebaseio.com\",\"projectId\":\"halopr-990a8\",\"storageBucket\":\"halopr-990a8.appspot.com\",\"messagingSenderId\":\"684455943282\",\"appId\":\"1:684455943282:web:4649e0377767ec09\"}" || "");

export const firebaseApp: firebaseType.app.App =
  firebase.apps[0] || firebase.initializeApp(firebaseConfig);

if (window.location.host.startsWith("localhost")) {
  firebaseApp.functions().useFunctionsEmulator("http://localhost:5000");
}

const firebaseFunction = (name: string) => {
  return firebaseApp.functions().httpsCallable(name);
};

export const verifyReferralCode = firebaseFunction(
  "appFunctions-verifyReferralCode"
);

export const generateReferralCode = firebaseFunction(
  "appFunctions-generateReferralCode"
);

export const getStripeExpressLink = firebaseFunction(
  "appFunctions-getStripeExpressLink"
);

export const getStripeExpressBalances = firebaseFunction(
  "appFunctions-getStripeExpressBalances"
);

export const createServiceProvider = firebaseFunction(
  "appFunctions-createServiceProvider"
);

export const providerConnectStripe = firebaseFunction(
  "appFunctions-providerConnectStripe"
);

export const providerDenyAppointment = firebaseFunction(
  "appFunctions-providerDenyAppointment"
);

export const providerConfirmAppointment = firebaseFunction(
  "appFunctions-providerConfirmAppointment"
);

export const confirmAppointmentPayment = firebaseFunction(
  "appFunctions-confirmAppointmentPayment"
);

export const confirmAppointmentUpdate = firebaseFunction(
  "appFunctions-confirmAppointmentUpdate"
);

export const providerConfirmAppointmentCompleted = firebaseFunction(
  "appFunctions-providerConfirmAppointmentCompleted"
);

export const auth = () => {
  return firebase.auth();
};

export const firestore = () => {
  return firebase.firestore();
};
