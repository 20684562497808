import { useNewService } from "@/config/collections/service";
import { awaitData } from "@/hooks/awaitData";
import * as React from "react";
import { EditServiceDialog } from "@/components/EditServiceDialog";

interface Props {
  providerId: string;
  onClose: () => void;
}

export const CreateServiceDialog = ({ providerId, onClose }: Props) => {
  const service = useNewService(providerId);

  return awaitData(service, {
    loading() {
      return null;
    },

    data(service) {
      return <EditServiceDialog service={service} onClose={onClose} />;
    }
  });
};
