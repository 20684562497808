import * as React from "react";
import { useBookingContext } from "@/hooks/booking";
import { SuccessMessage } from "./SuccessMessage";

export const BookedSuccess = () => {
  const {
    booking: { provider }
  } = useBookingContext();

  return (
    <SuccessMessage
      title="All set. Thank you for using HALLÒ!"
      message={
        <>
          We've sent the details of your order to {provider.name}, you will
          receive a notification once the order has been confirmed by{" "}
          {provider.name}
        </>
      }
    />
  );
};
