import { useEffect } from "react";
import * as React from "react";
import styled from "styled-components";
import { makeRule, useFormControlled } from "@/hooks/useForm";
import { withProps } from "../utils/withProps";
import { SelectFormField } from "./SelectFormField";
import { statesOptions } from "./states";
import { TextFormField } from "./TextFormField";
import { Grid } from "@material-ui/core";
import { Address } from "@/config/collections/provider";

interface Props {
  showErrors?: boolean;
  value?: Address;
  onChange: (value: Address) => void;
  onSetValid: (isValid: boolean) => void;
}

const TextFieldRow = styled.div`
  display: flex;
  justify-content: stretch;

  & > * {
    flex: 1;
  }

  & > *:not(:last-child) {
    margin-right: 16px;
  }
`;

const addressForm = {
  address1: {
    field: withProps(TextFormField, {
      label: "Address Line 1",
      variant: "outlined"
    }),
    getErrors: makeRule(
      "Please enter a valid street address",
      (address: string) => address.length > 0
    )
  },

  address2: {
    field: withProps(TextFormField, {
      label: "Address Line 2",
      variant: "outlined"
    })
  },

  city: {
    field: withProps(TextFormField, { label: "City", variant: "outlined" }),
    getErrors: makeRule(
      "Please enter a city",
      (city: string) => city.length > 0
    )
  },

  state: {
    field: withProps(SelectFormField, {
      label: "State or Territory",
      options: statesOptions,
      variant: "outlined"
    }),
    getErrors: makeRule(
      "We currently only serve in Purto Rico.",
      (state: string) => state === "PR"
    )
  },

  zipCode: {
    field: withProps(TextFormField, { label: "Zip Code", variant: "outlined" }),
    getErrors: makeRule(
      "Please enter a valid 5 digit zip code",
      (zip: string) => /^[0-9]{5}$/.test(zip)
    )
  }
};

const emptyAddress = {
  address1: "",
  address2: "",
  city: "",
  state: "PR",
  zipCode: ""
};

export const AddressForm = ({
  value = emptyAddress,
  onChange,
  showErrors = false,
  onSetValid
}: Props) => {
  const [fields, address, errors, { setShowErrors }] = useFormControlled(
    addressForm,
    {
      value: value,
      onChange: onChange
    }
  );

  const isValid = errors.length === 0;

  useEffect(() => {
    setShowErrors(showErrors);
  }, [showErrors]);

  useEffect(() => {
    onSetValid(isValid);
  }, [isValid]);

  return (
    <Grid container direction="column">
      {fields.address1}
      {fields.address2}
      {fields.city}
      <TextFieldRow>
        {fields.state}
        {fields.zipCode}
      </TextFieldRow>
    </Grid>
  );
};
