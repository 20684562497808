import { StepProps } from "@/components/providerSetup/steps";
import { CardTitle, StepHeader } from "@/components/providerSetup/styles";
import { SiteLink } from "@/components/SiteLink";
import { ServiceProvider } from "@/config/collections/provider";
import { ServicePortal } from "@/layouts/ServicePortal";
import { join } from "@/utils/routes";
import { Button, CardActions, Divider, LinearProgress, List, ListItem, ListItemText } from "@material-ui/core";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import * as React from "react";
import { useState } from "react";
import * as ReactDOM from "react-dom";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import styled from "styled-components";
import Notifications from './notifications';
import Payments from './payments';

const BackButton = styled(Button)`
  && {
    padding-left: 0;
  }
`;

interface SettingPage {
  id: string;
  label: string;
  title?: (provider: ServiceProvider) => React.ReactNode;
  component: React.ComponentType<StepProps>;
}

const sections: SettingPage[] = [
  {
    id: "notifications",
    label: "Notifications",
    component: Notifications
  },

  {
    id: "payments",
    label: "Payments",
    component: Payments
  }
];

type ButtonsProps = any

const renderStep = (
  Component: any,
  el: any
) => {
  return (
    <>
      <Component
        buttons={({ onNext, loading, disableNext }: ButtonsProps) =>
          el &&
          ReactDOM.createPortal(
            <>
              <Divider />
              <CardActions>
                <Button
                  disabled={loading || disableNext}
                  onClick={onNext}
                  color="primary"
                >
                  Save
                </Button>
              </CardActions>
              {loading ? <LinearProgress /> : null}
            </>,
            el
          )
        }
        onNext={() => null}
        onBack={() => null}
      />
    </>
  );
};

const Profile = ({ match }: RouteComponentProps) => {
  const [el, setEl] = useState(null as null | HTMLDivElement);

  return <Switch>
    <Route
      path={`${match.url}`}
      exact={true}
      render={() => {
        return (
          <ServicePortal>
            <CardTitle>
              <StepHeader>Settings</StepHeader>
            </CardTitle>
            <List component="nav">
              <Divider />
              {sections.map(value => {
                return (
                  <React.Fragment key={value.id}>
                    <ListItem
                      button
                      component={SiteLink}
                      href={join(match.url, value.id)}
                    >
                      <ListItemText primary={value.label}></ListItemText>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </List>
          </ServicePortal>
        );
      }}
    />
    {sections.map(value => {
      return (
        <Route
          key={value.id}
          path={join(match.url, value.id)}
          render={() => {
            return (
              <ServicePortal
                hideMobileNavigation={true}
                header={
                  <CardTitle>
                    <BackButton
                      size="small"
                      component={SiteLink}
                      href={match.url}
                    >
                      <NavigateBefore /> Back
                        </BackButton>
                    {value.title && value.title(provider)}
                  </CardTitle>
                }
                actions={<div ref={setEl} />}
              >
                {renderStep(
                  value.component,
                  value.title,
                  match.url,
                  el
                )}
              </ServicePortal>
            );
          }}
        />
      );
    })}
  </Switch>
};

export default Profile;
