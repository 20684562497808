import {
  Button,
  CardActions,
  CardContent,
  Typography,
  LinearProgress
} from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { firebaseApp } from "@/config/firebase";
import { useAsyncCallback } from "@/hooks/useAsyncCallback";
import { useRequireUser } from "@/hooks/useCurrentUser";
import { makeRule, rules, useForm } from "@/hooks/useForm";
import { withProps } from "../utils/withProps";
import { TextFormField } from "./TextFormField";
import { SuccessMessage } from "./SuccessMessage";
import { SiteDialog } from "@/components/SiteDialog";
import * as firebase from "firebase/app";

const resetPassword = (currentPassword, newPassword) => {
  const user = firebaseApp.auth().currentUser;
  const cred = firebase.default.auth.EmailAuthProvider.credential(
    user.email,
    currentPassword
  );
  return user
    .reauthenticateWithCredential(cred)
    .then(() => {
      const user = firebaseApp.auth().currentUser;
      return user.updatePassword(newPassword);
    })
    .catch(() => {
      return "password-incorrect";
    });
};

const Container = styled(CardContent)`
  width: 400px;
  max-width: 100vw;
  display: flex;
  flex-grow: 1;
  margin-bottom: auto;
  flex-direction: column;
  align-items: stretch;
`;

const Header = styled(Typography)`
  && {
    margin-bottom: 16px;
  }
`;

const variant = "outlined";
const margin = "normal";

const resetPasswordFields = {
  currentPassword: {
    field: withProps(TextFormField, {
      label: "Current Password",
      type: "password",
      variant,
      margin
    }),
    getErrors: rules(
      makeRule(
        "Please enter your current password",
        password => password !== ""
      )
    )
  },

  password: {
    field: withProps(TextFormField, {
      label: "New Password",
      type: "password",
      variant,
      margin
    }),
    getErrors: rules(
      makeRule(
        "Password length should be at least 8 characters.",
        password => password.length >= 8
      )
    )
  },

  confirmPassword: {
    field: withProps(TextFormField, {
      label: "Confirm Password",
      type: "password",
      variant,
      margin
    }),
    getErrors: rules(
      makeRule(
        "Make sure your passwords match.",
        (confirm, form) => confirm === form.password
      )
    )
  }
};

const defaultValue = { password: "", confirmPassword: "", currentPassword: "" };

export const ResetPassword = ({ onClose }) => {
  const user = useRequireUser("");
  const [status, setStatus] = useState(null);
  const [fields, value, errors, { setShowErrors }] = useForm(
    resetPasswordFields,
    defaultValue
  );

  const onSubmit = useAsyncCallback(async () => {
    if (errors.length > 0) {
      setShowErrors(true);
    } else {
      setStatus("loading");
      const result = await resetPassword(value.currentPassword, value.password);

      if (result === "password-incorrect") {
        setStatus("password-incorrect");
      } else {
        setStatus("success");
      }
    }
  }, [setShowErrors, errors]);

  return (
    <SiteDialog open={true}>
      {status == "success" ? (
        <CardContent>
          <SuccessMessage
            title="Done. Your password has been reset."
            message={
              <Button color="primary" onClick={onClose}>
                Close
              </Button>
            }
          />
        </CardContent>
      ) : (
        <>
          <Container>
            <Header variant="h6">Reset your password</Header>
            {status === "password-incorrect" && (
              <Typography variant="caption" gutterBottom color="error">
                Your current password is incorrect.
              </Typography>
            )}
            {fields.currentPassword}
            {fields.password}
            {fields.confirmPassword}
          </Container>
          <CardActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button
              style={{ marginLeft: "auto" }}
              onClick={onSubmit}
              disabled={status === "loading"}
              color="primary"
            >
              Reset
            </Button>
          </CardActions>
          {status === "loading" && <LinearProgress />}
        </>
      )}
    </SiteDialog>
  );
};
