import { GrowLoading } from "@/components/GrowLoading";
import {
  ServicePrice,
  useNewService,
  useProviderServices,
  useDeleteService
} from "@/config/collections/service";
import { awaitData } from "@/hooks/awaitData";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton
} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { EditServiceDialog } from "../EditServiceDialog";
import { EditExistingService } from "../EditExistingService";

const ServicesTable = styled(Table)`
  width: calc(100% + 200px);
`;

const IconValue = styled.div`
  display: flex;
  align-items: center;
`;

const IconCell = styled(TableCell)`
  && {
    padding: 0;
  }
`;

const ServiceRow = styled(TableRow)`
  cursor: pointer;
`;

const ExpandCell = styled(TableCell)`
  width: 99%;
`;

const EmptyState = styled.div`
  text-align: center;
  padding-top: 48px;
  padding-bottom: 24px;
`;

interface Props {
  providerId: string;
}

const formatCurrency = (value: number) => {
  return `$${value.toFixed(2)}`;
};

const renderPrice = (price: ServicePrice) => {
  if (price.type === "fixed") {
    return formatCurrency(price.value);
  }

  return `${formatCurrency(price.value)}/hr`;
};

const CreateServiceDialog = ({
  providerId,
  onClose
}: {
  providerId: string;
  onClose: () => void;
}) => {
  const service = useNewService(providerId);

  return awaitData(service, {
    loading() {
      return null;
    },

    data(service) {
      return <EditServiceDialog service={service} onClose={onClose} />;
    }
  });
};

export const EditServices = (props: Props) => {
  const [createService, setCreateService] = useState(false);
  const [editService, setEditService] = useState(null as null | string);
  const services = useProviderServices(props.providerId);
  const deleteService = useDeleteService();

  return (
    <>
      {createService ? (
        <CreateServiceDialog
          providerId={props.providerId}
          onClose={() => setCreateService(false)}
        />
      ) : null}

      {editService ? (
        <EditExistingService
          serviceKey={editService}
          onClose={() => setEditService(null)}
        />
      ) : null}

      <ServicesTable>
        <TableHead>
          <TableRow>
            <ExpandCell>Name</ExpandCell>
            <ExpandCell>Price</ExpandCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {awaitData(services, {
            loading() {
              return null;
            },

            data(data) {
              return data.map(service => {
                return (
                  <ServiceRow
                    hover
                    onClick={() => setEditService(service.key)}
                    title="edit"
                    key={service.key}
                  >
                    <TableCell title="edit">{service.name}</TableCell>
                    <TableCell>
                      {service.price && renderPrice(service.price)}
                    </TableCell>
                    <IconCell>
                      <IconValue>
                        <IconButton
                          size="small"
                          onClick={e => {
                            e.stopPropagation();
                            deleteService(service.key);
                          }}
                        >
                          <Close />
                        </IconButton>
                      </IconValue>
                    </IconCell>
                  </ServiceRow>
                );
              });
            }
          })}
        </TableBody>
      </ServicesTable>

      {awaitData(services, {
        loading() {
          return <GrowLoading />;
        },
        data(data) {
          if (data.length !== 0) {
            return null;
          }

          return (
            <EmptyState>
              <Typography variant="subtitle1">
                To get started, create your first service
              </Typography>

              <Button color="primary" onClick={() => setCreateService(true)}>
                Create Service
              </Button>
            </EmptyState>
          );
        }
      })}
    </>
  );
};
