import styled from "styled-components";
import { InputBase } from "@material-ui/core";
import { withProps } from "../utils/withProps";

const blurTarget = e => {
  return e.currentTarget.blur();
};

const ReadonlyBase = withProps(InputBase, { onFocus: blurTarget });

export const ReadonlyInput = styled(ReadonlyBase)`
  && {
    border-bottom: none;
    padding-top: 16px;
    width: 100%;

    &,
    &:after,
    &:before,
    &:hover {
      border-bottom: none;
    }
  }
` as typeof ReadonlyBase;
