import React, { useCallback, useState, ComponentType } from "react";
import { useMemo } from "react";
import { lensProp, view, set, Lens } from "ramda";

export const makeRule = <T, F>(
  message: string,
  validate: (value: T, fieds: F) => Boolean
) => {
  return (value: T, fullValue: F) =>
    validate(value, fullValue) ? [] : [message];
};

type ErrorFunction<T, F> = (value: T, fullValue: F) => string[];

export const rules = <T, F>(...allRulus: ErrorFunction<T, F>[]) => {
  return (value: T, fullValue: F) =>
    allRulus.flatMap(rule => rule(value, fullValue));
};

const mapObject = <T, R>(
  object: T,
  mapper: (value: T[keyof T], key: keyof T) => R
) => {
  const mapped: { [key in keyof T]: R } = {} as any;

  for (const key of (Object.keys(object) as any) as (keyof T)[]) {
    mapped[key] = mapper(object[key], key);
  }

  return mapped;
};

export interface InputComponentProps<V> {
  value: V;
  onChange: (value: V) => void;
  showErrors: boolean;
  errors: string[];
}

export type Fields<V> = {
  [key in keyof V]: {
    field: ComponentType<InputComponentProps<V[key]>>;
    getErrors?: ErrorFunction<V[key], V>;
    lens?: Lens;
  };
};

function makeFieldComponents<V>(
  fields: Fields<V>
): { [key in keyof V]: ComponentType<InputComponentProps<V>> } {
  return mapObject(fields, (field, key) => {
    const { field: Component } = field;
    const lens = field.lens || lensProp(key as string);

    return ({
      value,
      errors,
      showErrors,
      onChange
    }: InputComponentProps<V>) => {
      const handleChange = useCallback(
        newValue => {
          return onChange(set(lens, newValue, value));
        },
        [value, onChange]
      );

      return (
        <Component
          value={view(lens, value)}
          onChange={handleChange}
          showErrors={showErrors}
          errors={errors}
        />
      );
    };
  });
}

export function getFieldErrors<V>(fields: Fields<V>, formValue: V) {
  return mapObject(fields, (field, key) => {
    const value =
      field.lens != null
        ? view<V, V[typeof key]>(field.lens, formValue)
        : formValue[key];

    return field.getErrors == null ? [] : field.getErrors(value, formValue);
  });
}

const empty = {};

export function useFormControlled<V>(
  fields: Fields<V>,
  { value, onChange }: { value: V; onChange: (value: V) => void }
) {
  const [showErrors, setShowErrors] = useState(false);

  const fieldComponents = useMemo(() => makeFieldComponents(fields), [fields]);

  const fieldErrors = useMemo(() => {
    return getFieldErrors(fields, value);
  }, [value, fields]);

  const allErrors = useMemo(() => {
    return Array.from(Object.values(fieldErrors)).flat();
  }, [fieldErrors]);

  const outputFields = mapObject(
    fieldComponents,
    (Component: ComponentType<InputComponentProps<V>>, key) => (
      <Component
        value={value}
        showErrors={showErrors}
        errors={fieldErrors[key]}
        onChange={onChange}
      />
    )
  );

  const extraProps = { setValue: onChange, setShowErrors };

  return [outputFields, value, allErrors, extraProps] as [
    typeof outputFields,
    typeof value,
    typeof allErrors,
    typeof extraProps
  ];
}

export const useForm = function<V>(fields: Fields<V>, defaultValue: V) {
  const [value, onChange] = useState(defaultValue);
  return useFormControlled(fields, { value, onChange });
};
