import { Request } from '@/config/collections/requests';
import { CardContent, Divider, FormControl, InputLabel, ListItemText, Typography } from "@material-ui/core";
import Send from "@material-ui/icons/Send";
import { format } from "date-fns";
import * as React from 'react';
import { useState } from "react";
import styled from "styled-components";
import { ActionButton } from "./ActionButton";
import { CardChoice } from "./CardChoice";
import { ReadonlyInput } from "./ReadonlyInput";

interface Props {
    request: Request,
    onDeny: () => void,
    onConfirm: () => void,
    loading: boolean
}

const SendIcon = styled(Send)`
    && {
      margin-left: 8px;
      font-size: 18px;
    }
  ` as typeof Send;

const Detail = styled(FormControl)`
    && {
      width: 100%;
      margin-top: 16px;
    }
  `;

const SendButton = styled(ActionButton).attrs({
    variant: "contained",
    color: "primary"
})`
    && {
      justify-self: center;
    }
  ` as typeof ActionButton;

const SendActions = styled(CardContent)`
    && {
      padding-top: 24px;
      padding-bottom: 24px;
      display: flex;
      justify-content: center;
    }
  ` as typeof CardContent;

const cardChoices = [
    {
        id: "yes",
        value: true,
        label: (
            <ListItemText
                primary="Yes, I can meet with this client."
            />
        )
    },

    {
        id: "no",
        value: false,
        label: (
            <ListItemText
                primary="No, I can't make this appointment."
            />
        )
    }
];

export const RequestConfirmForm = ({
    request,
    onDeny,
    onConfirm,
    loading
}: Props) => {
    const [confirmed, onSetConfirmed] = useState(null);
    const appointmentDate = new Date(request.create.seconds * 1000)

    return (
        <>
            <CardContent style={{ paddingTop: '0' }}>
                <Typography variant="h5" component="h4">
                    Confirm Availability
                </Typography>
            </CardContent>
            <CardContent>
                <Detail>
                    <InputLabel shrink={true}>Requested time</InputLabel>
                    <ReadonlyInput
                        onFocus={e => e.currentTarget.blur()}
                        value={format(appointmentDate, "PPP, p")}
                    />
                </Detail>

                <Detail>
                    <InputLabel shrink={true}>Address</InputLabel>
                    <ReadonlyInput
                        onFocus={e => e.currentTarget.blur()}
                    //value={formatAddress(request.location)}
                    />
                </Detail>
            </CardContent>

            <Divider />
            <CardChoice
                value={confirmed}
                onChange={onSetConfirmed}
                options={cardChoices}
            />
            <Divider />

            {confirmed != null && (
                <SendActions>
                    {confirmed === true && (
                        <SendButton
                            disabled={loading}
                            onClick={() => onConfirm()}
                        >
                            Send confirmation <SendIcon />
                        </SendButton>
                    )}
                    {confirmed === false && (
                        <SendButton
                            disabled={loading}
                            onClick={() => onDeny()}
                        >
                            Confirm not available <SendIcon />
                        </SendButton>
                    )}
                </SendActions>
            )}
        </>
    );
};
