import * as React from "react";
import { CustomerPortal } from "@/layouts/CustomerPortal";
import styled from "styled-components";
import {
  Typography,
  CardContent,
  CardActions,
  LinearProgress,
  Button
} from "@material-ui/core";
import { useForm } from "@/hooks/useForm";
import { withProps } from "@/utils/withProps";
import { TextFormField } from "@/components/TextFormField";
import { useRequireUser } from "@/hooks/useCurrentUser";
import { awaitData } from "@/hooks/awaitData";
import { GrowLoading } from "@/components/GrowLoading";
import { useAsyncCallback } from "@/hooks/useAsyncCallback";
import { firebaseApp, firestore } from "@/config/firebase";
import { useUserProfile } from "@/hooks/useUserProfile";
import { useState, useRef } from "react";
import CloudUpload from "@material-ui/icons/CloudUpload";
import AccountCircle from "@material-ui/icons/AccountCircle";
import theme from "@/config/theme";
import { resizeThumbnail, getCoverCrop } from "@/utils/resizeImage";

const UploadButton = styled.label`
  height: 100%;
  width: 100%;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 30px;

  input {
    display: none;
  }

  > svg {
    font-size: inherit;
    color: #fff;
    margin: auto;
  }
`;

const PreviewImage = styled.img`
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50px;
`;

const Avatar = styled.div`
  background-color: #fff;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;

  > svg {
    width: 100%;
    height: 100%;
    color: ${theme.palette.primary.main};
  }

  &:hover {
    ${UploadButton} {
      display: flex;
    }
  }
`;

const Container = styled(CardContent)`
  display: flex;
  flex-direction: column;
`;

const FormField = styled(TextFormField)`
  && {
    margin-left: auto;
    margin-right: auto;
    max-width: 250px;
    width: 100%;
  }
`;

const nameFields = {
  firstName: {
    field: withProps(FormField, {
      label: "First Name",
      variant: "standard"
    })
  },

  lastName: {
    field: withProps(FormField, {
      label: "Last Name",
      variant: "standard"
    })
  }
};

const Profile = ({ user }) => {
  const [status, setStatus] = useState(null);
  const [fields, value] = useForm(nameFields, {
    firstName: user.firstName || "",
    lastName: user.lastName || ""
  });
  const imageRef = useRef();
  const [newFile, setFile] = useState(null);

  const fileUrl = newFile || user.profileImage;

  const onSave = useAsyncCallback(async () => {
    const db = firestore();
    const storage = firebaseApp.storage();

    setStatus("loading");

    if (newFile != null) {
      const ref = storage.ref(`profiles/${user.key}.jpg`);
      const cropped = resizeThumbnail(
        imageRef.current,
        getCoverCrop(imageRef.current, { width: 100, height: 100 })
      );
      await ref.putString(cropped, "data_url");
      setFile(null);
    }

    await db.doc(`/users/${user.key}`).update({
      firstName: value.firstName,
      lastName: value.lastName
    });

    setStatus(null);
  }, [value.firstName, value.lastName, imageRef.current, newFile]);

  return (
    <CustomerPortal
      header={
        <CardContent>
          <Typography variant="h6">My Profile</Typography>
        </CardContent>
      }
      actions={
        <>
          <CardActions>
            <Button
              disabled={
                status === "loading" ||
                (value.firstName === user.firstName &&
                  value.lastName === user.lastName &&
                  newFile == null)
              }
              style={{ marginLeft: "auto" }}
              onClick={onSave}
            >
              Save
            </Button>
          </CardActions>
          {status === "loading" && <LinearProgress />}
        </>
      }
    >
      <Container>
        <Avatar>
          {fileUrl == null ? (
            <AccountCircle viewBox="2 2 20 20" />
          ) : (
            <PreviewImage ref={imageRef} src={fileUrl} />
          )}
          <UploadButton>
            <CloudUpload />
            <input
              type="file"
              accept="image/*"
              onChange={event => {
                const [file] = event.target.files;
                const reader = new FileReader();

                reader.addEventListener(
                  "load",
                  () => setFile(reader.result),
                  false
                );

                if (file) {
                  reader.readAsDataURL(file);
                }
              }}
            />
          </UploadButton>
        </Avatar>
        {fields.firstName}
        {fields.lastName}
      </Container>
    </CustomerPortal>
  );
};

export default () => {
  useRequireUser();
  const userProfile = useUserProfile();

  return awaitData(userProfile, {
    loading() {
      return (
        <CustomerPortal>
          <GrowLoading />
        </CustomerPortal>
      );
    },

    data(userProfile) {
      return <Profile user={userProfile} />;
    }
  });
};
