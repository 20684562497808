import { firebaseApp } from "../config/firebase";
import { LOADING } from "./awaitData";
import usePromise from "react-use-promise";
import { Booking, BookingLocation } from "./booking";
import { Map } from "immutable";

const getRef = async ref => {
  return { ...(await ref.get()).data(), id: ref.id };
};

const db = firebaseApp.firestore();

export const useClientBookings = userId => {
  const [result, error, state] = usePromise(async () => {
    const docs = await db
      .collection(`bookings`)
      .where("client", "==", db.doc(`users/${userId}`))
      .get();

    const results = [];
    docs.forEach(doc => results.push(doc));

    return Promise.all(
      results.map(async ref => {
        const doc = ref;
        const data = doc.data();

        return new Booking({
          ...data,
          id: doc.id,
          client: await getRef(data.client),
          provider: await getRef(data.provider),
          services: new Map(data.services),
          location: new BookingLocation({
            ...data.location,
            options: data.location.options
          })
        });
      })
    );
  }, [userId]);

  if (state === "pending") {
    return LOADING;
  }

  if (state === "rejected") {
    throw error;
  }

  return result;
};
