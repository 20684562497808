import { FilterTab, FilterTabs } from "@/components/FilterTab";
import { GrowLoading } from "@/components/GrowLoading";
import { SiteLink } from "@/components/SiteLink";
import { awaitData } from "@/hooks/awaitData";
import { useClientBookings } from "@/hooks/useBookings";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { CustomerPortal } from "@/layouts/CustomerPortal";
import {
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import { format } from "date-fns";
import * as React from "react";
import { useState } from "react";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import styled from "styled-components";
import Appointment from "./Appointment";
import { join } from "@/utils/routes";

const ScrollList = styled(List)`
  overflow-y: auto;
`;

const HeaderContainer = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const filterers = {
  upcoming(appointment) {
    return (
      appointment.status !== "awaiting-confirmation" ||
      appointment.status === "payment-confirmed"
    );
  },

  completed(appointment) {
    return appointment.status === "appointment-completed-confirmed";
  },

  all() {
    return true;
  }
};

const Appointments = ({ match }) => {
  const currentUser = useCurrentUser();
  const bookings = useClientBookings(
    currentUser ? currentUser.uid : "PTW2fnNljIcuzpXdFmH6nkvKqOx1"
  );
  const [filterType, setFilterType] = useState("upcoming");

  return (
    <Switch>
      <Route path={join(match.url, ":bid")} component={Appointment}></Route>

      <CustomerPortal
        actions={null}
        header={
          <HeaderContainer>
            <CardContent>
              <Typography variant="h6">My Appointments</Typography>
            </CardContent>
            <FilterTabs
              variant="fullWidth"
              size="small"
              value={filterType}
              onChange={(e, tab) => setFilterType(tab)}
            >
              <FilterTab value="upcoming" label="Upcoming" />
              <FilterTab value="completed" label="Completed" />
              <FilterTab value="all" label="All" />
            </FilterTabs>
          </HeaderContainer>
        }
      >
        {awaitData(bookings, {
          loading() {
            return <GrowLoading />;
          },

          data(bookings) {
            return (
              <ScrollList>
                {bookings.filter(filterers[filterType]).map(booking => {
                  return (
                    <ListItem
                      button
                      href={join(match.url, booking.id)}
                      component={SiteLink}
                      key={booking.id}
                    >
                      <ListItemText
                        primary={`${booking.provider.name} for ${
                          booking.appointmentStart === "ASAP"
                            ? "ASAP"
                            : ormat(
                                booking.appointmentStart,
                                "dddd, MMMM do yyyy"
                              )
                        }`}
                      />
                    </ListItem>
                  );
                })}
              </ScrollList>
            );
          }
        })}
      </CustomerPortal>
    </Switch>
  );
};

export default Appointments;
