import * as React from "react";
import { useState, SyntheticEvent, ComponentType } from "react";
import { InputComponentProps } from "@/hooks/useForm";

export interface FormFieldProps<V> {
  error: boolean;
  onSetPristine: (isPristine: boolean) => void;
  onChange: (e: { target: { value: V } }) => void;
  isError: boolean;
  errors: string[];
}

export function makeFormField<V, P>(
  Component: ComponentType<FormFieldProps<V> & P>
): ComponentType<
  Omit<P, keyof InputComponentProps<any>> & InputComponentProps<V>
> {
  return ({
    errors,
    showErrors,
    onChange,
    value,
    ...props
  }: InputComponentProps<V>) => {
    const [pristine, setPristine] = useState(true);
    const isErrorState = errors.length > 0 && (showErrors || !pristine);

    return (
      <Component
        value={value}
        error={isErrorState}
        onSetPristine={setPristine}
        onChange={(e: { target: { value: V } }) => onChange(e.target.value)}
        isError={isErrorState}
        errors={errors}
        {...(props as P)}
      />
    );
  };
}
