import * as React from "react";
import AuthenticationForm from "./AuthenticationForm";
import { Typography, CardContent } from "@material-ui/core";
import styled from "styled-components";

const BookingAuthForm = styled(AuthenticationForm)`
  margin: 32px auto;
`;

const messages = {
  createAccount:
    "Let's get your account setup to start booking on Halló. By creating an account, it'll be even easier to book services next time.",
  signIn: "Welcome back! Login to your account to continue booking."
};

export const BookingAccount = ({ buttons, onNext }) => {
  const [loginType, setLoginType] = React.useState("createAccount");

  return (
    <>
      <CardContent>
        <Typography component="p" variant="body1">
          {messages[loginType]}
        </Typography>

        <BookingAuthForm
          className=""
          loginType={loginType}
          onSetLoginType={setLoginType}
          onSuccess={() => onNext()}
        />
      </CardContent>

      {buttons({ disableNext: true })}
    </>
  );
};
