import * as React from "react";
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import SignIn from "./sign-in";
import Join from "./join";
import { join } from "@/utils/routes";

export default ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route path={join(match.url, "sign-in")} component={SignIn}></Route>
      <Route path={join(match.url, "join")} component={Join}></Route>
    </Switch>
  );
};
