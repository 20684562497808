import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { Typography, Link, CardContent } from "@material-ui/core";
import { StepProps } from "./steps";
import { StepButtons } from "./StepButtons";
import { EditServices } from "./EditServices";
import { StepHeader } from "./styles";
import { useProviderServices, Service } from "@/config/collections/service";
import { awaitData } from "@/hooks/awaitData";
import { GrowLoading } from "@/components/GrowLoading";
import { CreateService } from "../CreateService";

const P = styled(Typography).attrs({ variant: "body1" })`
  && {
    margin-bottom: 8px;
    margin-top: 8px;
    display: block;
  }
`;

const Title = styled(StepHeader)`
  && {
    margin-right: auto;
    margin-bottom: 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Header = styled(P)`
  && {
    font-weight: 700;
  }
`;

const ServicesContainer = styled.div`
  min-height: 200px;
`;

const Intro = ({ onNext, onBack, buttons }: StepProps) => {
  return (
    <>
      <CardContent>
        <Header>We’ve simplified what you need into simple categories.</Header>
        <P>
          We aim to help customers find you need in as little time as possible.
          Our unique process will automatically add you in any of the categories
          your services may qualify for.
        </P>
        <Header>
          It is possible that you could be listed under multiple categories.
        </Header>
        <P>
          This means more visibility for you! So the more information and
          documents you provide here, the better we can list you under the right
          categories.
        </P>
        <P>
          To learn more about categories, visit{" "}
          <Link target="_blank" href="https://hallopr.com/halloservices">
            https://hallopr.com/halloservices
          </Link>
        </P>

        <Header>Next, you can add your services.</Header>
      </CardContent>

      <StepButtons onBack={onBack} onNext={onNext} />
    </>
  );
};

export const Services = ({
  onNext,
  services,
  onBack,
  provider
}: StepProps & { services: Service[] }) => {
  return (
    <>
      <ServicesContainer>
        <CardContent style={{ paddingBottom: 0 }}>
          <TitleContainer>
            <Title>Your Skills</Title>
            <CreateService providerId={provider.key} />
          </TitleContainer>
        </CardContent>
        <EditServices providerId={provider.key} />
      </ServicesContainer>
      <StepButtons
        onNext={services.length === 0 ? undefined : onNext}
        onBack={onBack}
      />
    </>
  );
};

export const ProviderServices = ({ onNext, onBack, ...props }: StepProps) => {
  const [status, setStatus] = useState("intro");
  const serviceProviders = useProviderServices(props.provider.key);

  return awaitData(serviceProviders, {
    loading() {
      return <GrowLoading />;
    },

    data: services => {
      if (services.length !== 0) {
        return (
          <Services
            onBack={() => onBack()}
            onNext={() => onNext()}
            services={services}
            {...props}
          />
        );
      }

      return (
        <>
          {status === "intro" ? (
            <Intro
              onBack={() => onBack()}
              onNext={() => setStatus("services")}
              {...props}
            />
          ) : status === "services" ? (
            <Services
              onBack={() => onBack()}
              onNext={() => onNext()}
              services={services}
              {...props}
            />
          ) : null}
        </>
      );
    }
  });
};
