import { AddressForm } from "@/components/AddressForm";
import { CardChoice } from "@/components/CardChoice";
import { GrowLoading } from "@/components/GrowLoading";
import { ReadonlyInput } from "@/components/ReadonlyInput";
import { ScheduleAppointmentDialog } from "@/components/ScheduleAppointmentDialog";
import { ServicesTable } from "@/components/ServicesTable";
import { SiteLink } from "@/components/SiteLink";
import { SuccessMessage } from "@/components/SuccessMessage";
import { confirmAppointmentUpdate } from "@/config/firebase";
import { awaitData } from "@/hooks/awaitData";
import {
  ClientLocation,
  ProvideBookingContext,
  useBookingContext
} from "@/hooks/booking";
import { useBooking } from "@/hooks/useBooking";
import { useBookingLineItems } from "@/hooks/useBookingLineItems";
import { CustomerPortal } from "@/layouts/CustomerPortal";
import { formatAddress } from "@/utils/formatAddress";
import {
  Button,
  CardActions,
  CardContent,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  ListItemText,
  Typography
} from "@material-ui/core";
import Edit from "@material-ui/icons/Edit";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import { format } from "date-fns";
import { is } from "immutable";
import { useRouter } from "@/utils/routes";
import * as React from "react";
import { useState, useCallback } from "react";
import styled from "styled-components";
import { AddToCalendarButton } from "@/components/AddToCalendarButton";
import { ConfirmButton } from "@/components/ConfirmButton";
import { RouteComponentProps } from "react-router";

const getBookingLocation = booking => {
  return formatAddress(
    booking.location.type === "client"
      ? booking.location.options
      : booking.provider.businessAddress
  );
};

const getEvent = booking => {
  return {
    title: `Hallò: ${booking.provider.name}`,
    duration: "0100",
    location: getBookingLocation(booking),
    startDatetime: format(booking.appointmentStart, "yyyyMMDDTHHmmss[Z]"),
    endDatetime: format(
      booking.appointmentStart + 60 * 60 * 1000,
      "yyyyMMDDTHHmmss[Z]"
    ),
    description: `View appointment in Hallò: ${location.origin}/customer/appointments/${booking.id}`
  };
};

const ScheduleControl = styled(FormControl)`
  && {
    margin: 16px 0;
    width: 100%;
  }
`;

const BookingServices = ({ booking }) => {
  const [lineItems] = useBookingLineItems(
    booking.provider.id,
    booking.services
  );

  return awaitData(lineItems, {
    loading: () => {
      return <GrowLoading />;
    },

    data: lineItems => {
      return <ServicesTable lineItems={lineItems} />;
    }
  });
};

const BackButton = styled(Button)`
  && {
    padding-left: 0;
  }
`;

const AppointmentDetails = styled(CardContent)`
  && {
    padding-top: 0;
    padding-bottom: 8px;
  }
`;

const getOptions = provider => [
  {
    id: "business",
    value: "business",
    label: (
      <ListItemText
        primary={`I'd like to visit ${provider.name}`}
        secondary={
          <span>
            {provider.name} is located at{" "}
            {formatAddress(provider.businessAddress)}
          </span>
        }
      />
    )
  },

  {
    id: "client",
    value: "client",
    label: (
      <ListItemText
        primary={`I'd like ${provider.name} to come to me.`}
        secondary={`${provider.name} can help you anywhere in the San Juan Metro area`}
      />
    )
  }
];

const AddressEdit = () => {
  const { booking, setLocation } = useBookingContext();
  const [addressValid, onSetValid] = useState();

  return (
    <>
      <Divider />
      <CardChoice
        value={booking.location.type}
        options={getOptions(booking.provider)}
        onChange={type => {
          setLocation(booking.location.set("type", type));
        }}
      />
      <Divider />
      {booking.location.type === "client" && (
        <CardContent>
          <AddressForm
            value={booking.location.options || undefined}
            onChange={newAddress =>
              setLocation(booking.location.set("options", newAddress))
            }
            onSetValid={onSetValid}
          />
        </CardContent>
      )}
    </>
  );
};

const bookingIsUpdated = (booking, newBooking) => {
  if (booking.appointmentStart !== newBooking.appointmentStart) {
    return true;
  }

  if (booking.location.type !== newBooking.location.type) {
    return true;
  }

  if (newBooking.location.type === "client") {
    return !is(
      ClientLocation(booking.location.options),
      ClientLocation(newBooking.location.options)
    );
  }

  return false;
};

const BookingSave = ({ booking, saving, onSaving, onSaved }) => {
  const { booking: newBooking, saveBooking } = useBookingContext();
  return (
    <Button
      disabled={!bookingIsUpdated(booking, newBooking) || saving}
      onClick={() => {
        onSaving(true);

        saveBooking()
          .then(() => confirmAppointmentUpdate({ bookingId: newBooking.id }))
          .finally(() => onSaving(false))
          .then(onSaved);
      }}
      variant="contained"
      color="primary"
    >
      Save
    </Button>
  );
};

const BookingAppointmentTime = () => {
  const [edit, setEdit] = useState();
  const { booking } = useBookingContext();

  return (
    <>
      <ScheduleControl>
        <InputLabel>Date Scheduled</InputLabel>
        <ReadonlyInput
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setEdit(true)}
              >
                <Edit />
              </IconButton>
            </InputAdornment>
          }
          value={format(booking.appointmentStart, "MMMM Do, yyyy [at] h:mm a")}
        />
      </ScheduleControl>
      {edit && (
        <ScheduleAppointmentDialog
          startDate={booking.appointmentStart}
          onClose={() => setEdit(false)}
        />
      )}
    </>
  );
};

const Appointment = ({ match: { params } }) => {
  const { bid } = params;
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const booking = useBooking(bid);

  const cancelEvent = useCallback(() => {}, []);

  return awaitData(booking, {
    loading() {
      return (
        <CustomerPortal hideMobileNavigation={true}>
          <GrowLoading />
        </CustomerPortal>
      );
    },

    data(booking) {
      if (saved === true) {
        const { provider } = booking;
        return (
          <CustomerPortal hideMobileNavigation={true}>
            <SuccessMessage
              title={`Done. ${provider.name} will confirm they can make the appointment.`}
              message={
                <>
                  We've sent the details of your appointment to {provider.name},
                  they will get back with you via email to confirm your booking.
                  <Button
                    style={{
                      marginTop: "32px",
                      marginLeft: "auto",
                      marginRight: "auto"
                    }}
                    color="primary"
                    onClick={() => setSaved(false)}
                  >
                    Go Back
                  </Button>
                </>
              }
            />
          </CustomerPortal>
        );
      }

      return (
        <ProvideBookingContext defaultState={{ booking: booking }}>
          <CustomerPortal
            hideMobileNavigation={true}
            actions={
              <CardActions>
                <ConfirmButton
                  confirmTitle="Are you sure you want to cancel this appointment?"
                  confirmBody={`This action isn't reversable, but you can create a new appointment with ${booking.provider.name} later.`}
                  confirmAction="Cancel appointment"
                  denyAction="Nevermind"
                  onConfirmed={cancelEvent}
                  style={{ marginRight: "auto" }}
                >
                  Cancel
                </ConfirmButton>
                <AddToCalendarButton event={getEvent(booking)}>
                  Add to calendar
                </AddToCalendarButton>
                <BookingSave
                  saving={saving}
                  booking={booking}
                  onSaving={setSaving}
                  onSaved={() => {
                    setSaved(true);
                  }}
                />
              </CardActions>
            }
            header={
              <>
                <CardActions>
                  <BackButton
                    component={SiteLink}
                    href="/customer/appointments"
                    size="small"
                  >
                    <NavigateBefore />
                    Back to all appointments
                  </BackButton>
                </CardActions>

                <AppointmentDetails>
                  <Typography variant="h6">
                    Appointment with {booking.provider.name}
                  </Typography>
                </AppointmentDetails>
              </>
            }
          >
            <BookingServices booking={booking} />

            <CardContent>
              <BookingAppointmentTime />
            </CardContent>

            <AppointmentDetails style={{ marginBottom: "8px" }}>
              <Typography variant="caption" color="textSecondary">
                Appointment Location
              </Typography>
            </AppointmentDetails>

            <AddressEdit />

            {saving && <LinearProgress />}
          </CustomerPortal>
        </ProvideBookingContext>
      );
    }
  });
};

export default Appointment;
