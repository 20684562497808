import { useEffect, useState } from "react";
import {
  createDraftCollection,
  useMutation,
  useSelector,
  useRemoveDraft,
  selectDocument,
  selectCollection,
  useSavePatch,
  useMakeDraft,
  useDeleteDocument
} from "../draftCollection";
import { LOADING } from "@/hooks/awaitData";
import { v4 as uuid } from "uuid";
import { omit } from "ramda";
import { useAsyncCallback } from "@/hooks/useAsyncCallback";

export interface FixedPrice {
  type: "fixed";
  value: number;
  durationMinutes: number;
}

export interface HourlyPrice {
  type: "hourly";
  value: number;
  minMinutes: number;
}

export type ServicePrice = FixedPrice | HourlyPrice;

export interface Service {
  key: string;
  price?: ServicePrice;
  name: string;
  description: string;
}

export const serviceCollection = createDraftCollection({
  path: "serviceProviders/*/services",

  mutations: {
    createService(provider: Service, key: string) {
      return {
        key,
        name: "",
        description: ""
      } as Service;
    },

    patchService(provider: Service, value: Partial<Service>) {
      return {
        ...provider,
        ...value
      } as Service;
    }
  }
});

export const useNewService = (serviceProvider: string) => {
  const createProvider = useMutation(serviceCollection, "createService");
  const [id, setId] = useState(LOADING as typeof LOADING | string);

  useEffect(() => {
    const id = `${serviceProvider}/services/${uuid()}`;
    createProvider(id, id);
    setId(id);
  }, [serviceProvider]);

  return useSelector(selectDocument, serviceCollection, id);
};

export const useServiceDraft = (serviceKey: string) => {
  const makeDraft = useMakeDraft(serviceCollection);
  const removeDraft = useRemoveDraft(serviceCollection);

  useEffect(() => {
    makeDraft(serviceKey);

    return () => {
      removeDraft(serviceKey);
    };
  }, [serviceKey]);

  return useSelector(selectDocument, serviceCollection, serviceKey);
};

export const useProviderServices = (serviceProvider: string) => {
  return useSelector(selectCollection, serviceCollection, serviceProvider);
};

export const useSaveService = () => {
  const patchService = useMutation(serviceCollection, "patchService");
  const saveService = useSavePatch(serviceCollection, omit(["key"]));
  const removeDraft = useRemoveDraft(serviceCollection);

  return useAsyncCallback(
    async (key: string, service: Partial<Service>) => {
      patchService(key, service);
      await saveService(key);
      removeDraft(key);
    },
    [patchService, saveService]
  );
};

export const useDeleteService = () => {
  return useDeleteDocument();
};
