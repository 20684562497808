import styled from "styled-components";
import { ActionButton } from "./ActionButton";

export const LoginButton = styled(ActionButton).attrs({
  variant: "contained",
  color: "primary",
  size: "large"
})`
  && {
    align-self: stretch;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;
