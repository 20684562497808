import { EmptyState } from "@/components/EmptyState";
import { GrowLoading } from "@/components/GrowLoading";
import { StepHeader } from "@/components/providerSetup/styles";
import { ServiceProvider, useProvider } from "@/config/collections/provider";
import {
  getStripeExpressBalances,
  getStripeExpressLink
} from "@/config/firebase";
import theme from "@/config/theme";
import { isLoading, LOADING } from "@/hooks/awaitData";
import { UserProfile, useUserProfile } from "@/hooks/useUserProfile";
import { ServicePortal } from "@/layouts/ServicePortal";
import NavigateNext from "@material-ui/icons/NavigateNext";
import {
  Button,
  CardContent,
  Divider,
  List,
  CardActions,
  ListItem,
  ListItemText,
  Typography,
  LinearProgress
} from "@material-ui/core";
import { SiteLink } from "@/components/SiteLink";
import * as numeral from "numeral";
import * as proc from "process";
import * as React from "react";
import styled from "styled-components";
import "./stripe-connect.css";
import { useAsyncCallback } from "@/hooks/useAsyncCallback";

const currencyFormat = "$0,0.00";

const AvailableBalance = styled(Typography)`
  && {
    color: ${theme.palette.text.primary};
    margin-top: 16px;
    font-weight: 300;
  }
`;

const PendingBalance = styled(Typography)`
  && {
    color: ${theme.palette.text.primary};
    margin-top: 16px;
    font-weight: 300;
  }
`;

const getStripeLink = (
  userProfile: UserProfile,
  serviceProvider: ServiceProvider
) => {
  const redirectUrl = `${window.location.protocol}://${window.location.host}/service/stripe-connect-callback`;
  return `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${redirectUrl}&client_id=${proc.env.STRIPE_CLIENT_ID}&state=${userProfile.key}&stripe_user[email]=${userProfile.email}&stripe_user[country]=us&stripe_user[business_type]=individual&stripe_user[phone_number]=${serviceProvider.contactNumber}`;
};

interface Balance {
  amount: number;
}

interface Balances {
  available: Balance[];
  pending: Balance[];
}

const Payments = () => {
  const serviceProvider = useProvider();
  const userProfile = useUserProfile();
  const [loadingLink, setLoadingLink] = React.useState(false);
  const [stripeLink, setStripeLink] = React.useState(null as null | string);
  const [errorType, setError] = React.useState();
  const [stripeBalances, setStripeBalances] = React.useState(LOADING as
    | typeof LOADING
    | Balances);

  const getPaid = useAsyncCallback(async () => {
    setLoadingLink(true);
    await getStripeExpressLink().then(({ data }: { data: string }) => {
      setStripeLink(data);
    });
    setLoadingLink(false);
  }, []);

  React.useEffect(() => {
    if (userProfile != null) {
      getStripeExpressBalances()
        .then(({ data }: { data: Balances }) => {
          setStripeBalances(data);
        })
        .catch((e: any) => {
          if (
            e &&
            e.details &&
            e.details.errorType === "noStripeExpressAccount"
          ) {
            setError(e.details.errorType);
          } else {
            setError(e);
          }
        });
    }
  }, [userProfile]);

  if (isLoading(serviceProvider) || isLoading(userProfile)) {
    return (
      <GrowLoading />
    );
  }

  if (errorType === "noStripeExpressAccount") {
    return (
      <EmptyState>
        <Typography style={{ marginBottom: "12px" }} variant="h5">
          Start receiving payments.
          </Typography>
        <Typography
          style={{ marginBottom: "24px", maxWidth: "400px" }}
          variant="body1"
        >
          You can start receiving payments by visiting Stripe, our secure
          payments partner.
          </Typography>
        <a
          style={{ padding: 0 }}
          className="stripe-connect light-blue"
          target="_blank"
          href={getStripeLink(userProfile, serviceProvider)}
        >
          <span>Connect with Stripe</span>
        </a>
        <br />
      </EmptyState>
    );
  }

  if (errorType != null) {
    throw errorType;
  }

  if (isLoading(stripeBalances)) {
    return (
      <GrowLoading />
    );
  }

  const availible = stripeBalances.available
    .map(value => value.amount)
    .reduce((total, value) => total + value, 0);

  const pending = stripeBalances.pending
    .map(value => value.amount)
    .reduce((total, value) => total + value, 0);

  return (
    <>
      <CardContent>
        <StepHeader>Payment Settings</StepHeader>
      </CardContent>
      <List style={{ marginBottom: "16px" }}>
        <Divider component="li" />
        <ListItem>
          <ListItemText
            primary="Available Balance"
            secondary={
              <>
                This amount is ready to be transferred to your account.
                <AvailableBalance variant="h6">
                  {numeral(availible).format(currencyFormat)}
                </AvailableBalance>
              </>
            }
          ></ListItemText>
        </ListItem>
        <Divider component="li" />
        <ListItem>
          <ListItemText
            primary="Pending Balance"
            secondary={
              <>
                Typically will be available for transfer within 2-3 days, but
                may take longer for your first earnings.
                <PendingBalance variant="h6">
                  {numeral(pending).format(currencyFormat)}
                </PendingBalance>
              </>
            }
          ></ListItemText>
        </ListItem>
        <Divider component="li" />
      </List>

      <CardActions>
        {stripeLink ? (
          <Button
            component={SiteLink}
            color="primary"
            onClick={() => setStripeLink(null)}
            target="_blank"
            href={stripeLink}
          >
            Continue to stripe to manage <NavigateNext />
          </Button>
        ) : (
            <Button color="primary" onClick={getPaid} disabled={loadingLink}>
              Get Paid & Manage Payments
          </Button>
          )}
      </CardActions>
      {loadingLink && <LinearProgress />}
    </>
  );
};

export default Payments;
