import * as React from "react";
import { useEffect } from "react";
import {
  useFormControlled,
  Fields,
  InputComponentProps,
  getFieldErrors
} from "@/hooks/useForm";

export function createSubform<V>(form: Fields<V>) {
  return {
    getErrors: (value: V, form: any) =>
      Object.values(getFieldErrors(form, value)).flat(),
    field: ({ showErrors, onChange, value }: InputComponentProps<V>) => {
      const [fields, , , { setShowErrors }] = useFormControlled(form, {
        value,
        onChange
      });

      useEffect(() => {
        setShowErrors(showErrors);
      }, [showErrors]);

      return (
        <>
          {(Object.keys(fields) as (keyof V)[]).map(key => (
            <React.Fragment key={key}>{fields[key]}</React.Fragment>
          ))}
        </>
      );
    }
  };
}
