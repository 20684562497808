import { firebaseApp } from "../config/firebase";
import { LOADING } from "./awaitData";
import usePromise from "react-use-promise";

export const useServiceProvider = id => {
  const [result, error, state] = usePromise(async () => {
    if (id == null) {
      return new Promise(() => {});
    }

    const doc = await firebaseApp
      .firestore()
      .doc(`serviceProviders/${id}`)
      .get();

    const data = doc.data();

    return {
      ...data,
      id: doc.id
    };
  }, [id]);

  if (state === "pending") {
    return LOADING;
  }

  if (state === "rejected") {
    throw error;
  }

  return result;
};
