import * as React from "react";
import { ServicePortal } from "@/layouts/ServicePortal";
import { useUserProfile } from "@/hooks/useUserProfile";
import {
  useProviderBookings,
  BookingStatus
} from "@/config/collections/booking";
import { useProvider } from "@/config/collections/provider";
import { awaitData, LOADING } from "@/hooks/awaitData";
import { GrowLoading } from "@/components/GrowLoading";
import { EmptyState } from "@/components/EmptyState";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  Typography,
  TableCell,
  CardHeader,
  CardContent
} from "@material-ui/core";
import styled from "styled-components";
import { formatName } from "@/utils/formatName";
import { getRelativeDayLabel } from "@/utils/time";
import { join } from "@/utils/routes";
import { RouteComponentProps, Switch, Route } from "react-router-dom";
import Appointment from "./appointment";

const AppointmentRow = styled(TableRow)`
  cursor: pointer;
`;

const formatStatus = (status: BookingStatus) => {
  if (status === BookingStatus.PaymentConfirmed) {
    return "Awaiting confirm";
  }

  if (status === BookingStatus.AppointmentConfirmed) {
    return "Confirmed";
  }

  if (status === BookingStatus.AppointmentCompletedConfirmed) {
    return "Completed";
  }

  if (status === BookingStatus.PaymentCaptured) {
    return "Customer Paid";
  }

  if (status === BookingStatus.AppointmentCanceled) {
    return "Cancelled";
  }

  return status;
};

const Appoinments = ({ match, history }: RouteComponentProps) => {
  const profile = useUserProfile();
  const provider = useProvider();
  const bookings = useProviderBookings(
    awaitData(profile, { data: profile => profile.serviceProvider })
  );

  return (
    <Switch>
      <Route path={join(match.url, ":bid")} component={Appointment}></Route>

      <ServicePortal
        header={<CardHeader title="Your Appointments"></CardHeader>}
      >
        {awaitData(provider, {
          loading: () => <GrowLoading />,
          data: provider => {
            if (!provider.public) {
              return (
                <CardContent>
                  <EmptyState>
                    <Typography variant="h6">
                      Your account isn't active yet.
                    </Typography>
                    <Typography variant="body1">
                      We'll send you an email when it's time for next steps.
                    </Typography>
                  </EmptyState>
                </CardContent>
              );
            }

            return awaitData(bookings, {
              loading() {
                return <GrowLoading />;
              },

              data(bookings) {
                return (
                  <>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Customer</TableCell>
                          <TableCell>Appointment Date</TableCell>
                          <TableCell>Status</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bookings.map(booking => (
                          <AppointmentRow
                            hover
                            key={booking.key}
                            onClick={() =>
                              history.push(join(match.url, booking.id))
                            }
                          >
                            <TableCell>
                              {booking.client && formatName(booking.client)}
                            </TableCell>
                            <TableCell>
                              {getRelativeDayLabel(
                                booking.appointmentStart === "ASAP"
                                  ? "ASAP"
                                  : new Date(
                                      booking.appointmentStart.seconds * 1000
                                    ),
                                Date.now()
                              )}
                            </TableCell>
                            <TableCell>
                              {formatStatus(booking.status)}
                            </TableCell>
                          </AppointmentRow>
                        ))}
                      </TableBody>
                    </Table>
                  </>
                );
              }
            });
          }
        })}
      </ServicePortal>
    </Switch>
  );
};

export default Appoinments;
