import React, { ComponentType } from "react";

type PartialComponent<Props, Passed extends Partial<Props>> = {
  [key in keyof Props]: key extends keyof Passed
    ? Props[key] | undefined | never
    : Props[key];
} & { ref?: any };

export const withProps = <Props, Passed>(
  Component: ComponentType<Props>,
  props: Passed
): ComponentType<PartialComponent<Props, Passed>> => {
  return React.forwardRef((restProps, ref) => {
    return <Component ref={ref} {...props} {...(restProps as Props)} />;
  }) as any;
};
