import { setIn, update, set } from "immutable";
import {
  createDraftCollection,
  useMutation,
  selectQuery,
  selectCollection,
  useSelector,
  selectDocument
} from "@/config/draftCollection";
import { useEffect, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { LOADING, Load } from "@/hooks/awaitData";
import { switchMap } from "rxjs/operators";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { firebaseApp } from "../firebase";

export enum BookingStatus {
  AwaitingConfirmation = "awaiting-confirmation",
  PaymentConfirmed = "payment-confirmed",
  AppointmentConfirmed = "appointment-confirmed",
  AppointmentCompletedConfirmed = "appointment-completed-confirmed",
  AppointmentCanceled = "appointment-canceled",
  PaymentCaptured = "payment-captured"
}

export interface Message {}

export interface LineItem {
  service: firebase.firestore.DocumentReference;
  quantity: number;
}

export interface Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
}

export enum LocationType {
  Client = "client",
  Business = "business"
}

export interface BookingLocation {
  type: LocationType;
  options: Address;
}

export interface Booking {
  id: string;
  key: string;
  client: firebase.firestore.DocumentReference;
  provider: firebase.firestore.DocumentReference;
  location: BookingLocation;
  lineItems: LineItem[];
  appointmentStart: { seconds: number } | "ASAP";
  status: BookingStatus;
  paymentToken?: string;
}

type WithClient = Omit<Booking, "client"> & {
  client: {
    firstName: string;
    lastName: string;
    email: string;
  };
};

export const bookingCollection = createDraftCollection({
  path: "bookings",

  mutations: {
    createBooking(
      booking: Booking,
      {
        client,
        provider
      }: {
        client: firebase.firestore.DocumentReference;
        provider: firebase.firestore.DocumentReference;
      }
    ) {
      return ({
        client,
        provider,
        location: {
          type: LocationType.Client,
          options: {} as any
        },
        lineItems: [],
        status: BookingStatus.AwaitingConfirmation
      } as Omit<Booking, "key" | "id">) as any;
    },

    setBookingService(
      booking: Booking,
      value: { quantity: number; service: string }
    ) {
      const index = booking.lineItems.findIndex(
        lineItem => lineItem.service.id === value.service
      );

      return setIn(
        booking,
        ["lineItems", index === -1 ? booking.lineItems.length : index],
        value
      );
    },

    removeBookingService(booking: Booking, service: string) {
      return update(booking, "lineItems", value =>
        value.filter(lineItem => lineItem.service.id !== service)
      );
    },

    setAppointmentStart(booking: Booking, start: Date) {
      return set(booking, "appointmentStart", start.toUTCString());
    },

    setLocation(booking: Booking, location: BookingLocation) {
      return set(booking, "location", location);
    },

    setPaymentToken(booking: Booking, token: string) {
      return set(booking, "paymentToken", token);
    }
  }
});

export const useNewBooking = (
  provider: firebase.firestore.DocumentReference
) => {
  const createBooking = useMutation(bookingCollection, "createBooking");
  const client = useCurrentUser();

  useEffect(() => {
    const id = uuid();
    createBooking(id, {
      provider,
      client: firebaseApp.firestore().doc(`users/${client.uid}`)
    });
  }, [provider.id]);

  return;
};

const hide = ["awaiting-confirmation"];

const selectBookingsWithClient = (
  ctx: any,
  provider: firebase.firestore.DocumentReference
) => {
  return selectQuery<Booking>(ctx, store => {
    return store
      .collection(bookingCollection.path)
      .where("provider", "==", provider)
      .orderBy("createDate", "desc");
  }).pipe(
    switchMap(value => {
      return Promise.all(
        value.map(async value => {
          const client = await (value.client && value.client.get());
          return {
            ...value,
            client: client.data() as any
          };
        })
      ).then(v =>
        v.filter((value: WithClient) => !hide.includes(value.status))
      );
    })
  );
};

export const selectBookingMessages = (ctx: any, bookingId: string) => {
  return selectQuery(ctx, store => {
    return store
      .collection(`bookings/${bookingId}/messages`)
      .orderBy("sentDate", "desc");
  });
};

export const useBookingMessages = (
  bookingId: string
): typeof LOADING | Message[] => {
  return useSelector(selectBookingMessages, bookingId);
};

export const useBooking = (bookingId: string): typeof LOADING | Booking => {
  return useSelector(
    selectDocument,
    bookingCollection,
    `bookings/${bookingId}`
  );
};

export const useProviderBookings = (
  provider: firebase.firestore.DocumentReference | typeof LOADING
): typeof LOADING | WithClient[] => {
  return useSelector(selectBookingsWithClient, provider);
};
