import * as React from "react";
import { Link } from "react-router-dom";

export const SiteLink = React.forwardRef<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  any
>(({ href, ...rest }, ref) => {
  if (href && (href.startsWith("https://") || href.startsWith("https://"))) {
    return <a href={href} ref={ref} {...rest} />;
  }

  return <Link to={href} innerRef={ref} {...rest}></Link>;
});
