import styled from "styled-components";
import { Card } from "@material-ui/core";
import theme from "@/config/theme";

export const BookingCard = styled(Card)`
  && {
    max-width: 500px;
    width: 100%;
    min-height: 400px;
    align-self: center;
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 64px);
    margin-right: 24px;

    ${theme.breakpoints.down("xs")} {
      flex-grow: 1;
      max-height: none;
      min-height: 0;
      height: auto;
      border-radius: 0;
      margin-right: 0;
      max-width: none;
    }
  }
`;
