import { useState } from "react";
import createUseContext from "constate";
import { Map, Record } from "immutable";
import { firebaseApp } from "../config/firebase";
import { useCurrentUser } from "./useCurrentUser";
import { useRouter } from "@/utils/routes";

export const BookingLocation = new Record({
  type: null,
  options: null
});

export const ClientLocation = new Record({
  address1: "",
  address2: "",
  city: "",
  state: "",
  zipCode: ""
});

export const Booking = new Record({
  id: null,
  client: null,
  provider: null,
  status: "awaiting-confirmation",
  services: Map(),
  location: new BookingLocation({ type: "client" }),
  appointmentStart: null
});

const useBookingState = ({ defaultState = { booking: null, open: false } }) => {
  const router = useRouter();
  const currentUser = useCurrentUser();
  const [booking, setBooking] = useState(defaultState.booking);

  return {
    booking,

    async saveBooking() {
      const store = firebaseApp.firestore();
      const updatedBooking = booking
        .update("provider", provider =>
          store.doc(`serviceProviders/${provider.id}`)
        )
        .set("client", store.doc(`users/${currentUser.uid}`))
        .update("services", services =>
          services.map((service, id) => ({
            ...service,
            service: store.doc(
              `serviceProviders/${booking.provider.id}/services/${id}`
            )
          }))
        )
        .toJS();

      if (booking.id) {
        await store.doc(`bookings/${booking.id}`).update(updatedBooking);
        return { id: booking.id };
      } else {
        const newBooking = await store
          .collection("bookings")
          .add(updatedBooking);

        setBooking(booking.set("id", newBooking.id));

        return newBooking;
      }
    },

    setBookingService(id, value) {
      setBooking(booking.setIn(["services", id], value));
    },

    removeBookingService(id, value) {
      setBooking(booking.removeIn(["services", id]));
    },

    setAppointmentStart(appointmentStart) {
      setBooking(booking.set("appointmentStart", appointmentStart));
    },

    setLocation(location) {
      setBooking(booking.set("location", location));
    },

    editBooking(booking) {
      setBooking(booking);
    },

    closeBooking() {
      router.history.push("/");
    },

    bookWithProvider(provider) {
      setBooking(new Booking({ provider }));
    },

    setPaymentToken() {
      setBooking(booking.set("paymentToken"));
    }
  };
};

export const useBookingContext = createUseContext(useBookingState);
export const ProvideBookingContext = useBookingContext.Provider;
