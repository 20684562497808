import "./setup-proxy";
import * as ReactDOM from "react-dom";
import * as React from "react";
import { ProvideFirebaseUser } from "./hooks/useCurrentUser";
import { ProvideBookingContext } from "./hooks/booking";
import { StoreContext, createCollectionsState } from "./config/draftCollection";
import { useUserProfile } from "./hooks/useUserProfile";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { firebaseApp } from "./config/firebase";
import Index from "@pages/index";
import BookAppointment from "@pages/book-appointment";
import Customer from "@pages/customer";
import Service from "@pages/service";
import Auth from "@pages/auth";
import About from "@pages/about";
import { ErrorBoundary } from "@/components/ErrorBoundary";
import { AppError } from "@/components/AppError";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import theme from "@/config/theme";
import { CssBaseline } from "@material-ui/core";

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/"
          exact={true}
          render={() => {
            window.location.href = `https://hallopr.com`;
            return null;
          }}
        />
        <Route
          path="/r/:referralCode"
          exact={true}
          render={({ match }) => (
            <Redirect
              to={`/service/setup?referrer=${match.params.referralCode}`}
            />
          )}
        />
        <Route path="/book-appointment/:pid" component={BookAppointment} />
        <Route path="/customer" component={Customer} />
        <Route path="/service" component={Service} />
        <Route path="/auth" component={Auth} />
        <Route path="/about" component={About} />
        <Route
          path="/confirm-appointment/:pid"
          render={({ match }) => (
            <Redirect to={`/service/appointments/${match.params.pid}`} />
          )}
        />
      </Switch>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <ErrorBoundary fallback={() => <AppError />}>
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider>
        <CssBaseline />
        <ProvideFirebaseUser>
          <ProvideBookingContext>
            <StoreContext.Provider
              value={createCollectionsState(firebaseApp.firestore())}
            >
              <useUserProfile.Provider>
                <AppRouter />
              </useUserProfile.Provider>
            </StoreContext.Provider>
          </ProvideBookingContext>
        </ProvideFirebaseUser>
      </SnackbarProvider>
    </MuiThemeProvider>
  </ErrorBoundary>,
  document.getElementById("app")
);
