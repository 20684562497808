import * as React from "react";
import { Button, CircularProgress } from "@material-ui/core";
import { PropsOf } from '@/utils/types'

type Props = {
  loading?: boolean,
} & PropsOf<typeof Button>

export const ActionButton = React.forwardRef(
  ({ loading, children, disabled, ...props }: Props, ref: any) => {
    return (
      <Button disabled={loading || disabled} {...props} ref={ref}>
        {loading ? <CircularProgress /> : children}
      </Button>
    );
  }
) 
