import * as React from "react";
import { Route, Switch } from "react-router";
import Request from "./request";
import RequestsList from "./requestsList";

const Requests = () => {
  return (
    <Switch>
      <Route exact path="/service/requests" component={RequestsList}></Route>
      <Route exact path="/service/requests/:rid" component={Request}></Route>
    </Switch>
  );
};

export default Requests;
