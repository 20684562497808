import * as React from "react";
import styled from "styled-components";
import LoginForm from "./LoginForm";
import { LoginTabs } from "./LoginTabs";
import SignupForm from "./SignupForm";
import { Link } from "@material-ui/core";

const LoginContainer = styled.div`
  && {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
`;

const NavTabs = styled(LoginTabs)`
  && {
    margin-bottom: 32px;
  }
`;

const ButtonLink = styled(Link).attrs({ component: "button" })`
  && {
    margin-bottom: 16px;

    &:hover {
      cursor: pointer;
    }
  }
`;

const AuthenticationForm = ({
  loginType,
  className,
  onSetLoginType,
  onSuccess
}) => {
  return (
    <LoginContainer className={className}>
      {loginType === "createAccount" ? (
        <ButtonLink component="button" onClick={() => onSetLoginType("signIn")}>
          Already have an account?
        </ButtonLink>
      ) : (
        <ButtonLink
          component="button"
          onClick={() => onSetLoginType("createAccount")}
        >
          Don't have an account yet? Create one.
        </ButtonLink>
      )}

      {loginType === "signIn" ? (
        <LoginForm onSuccess={onSuccess} />
      ) : (
        <SignupForm onSuccess={onSuccess} />
      )}
    </LoginContainer>
  );
};

export default AuthenticationForm;
