import { useMemo } from "react";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { firebaseApp } from "../config/firebase";
import { LOADING } from "./awaitData";

export const useCollection = (path, updateKey) => {
  const query = useMemo(() => {
    const query = firebaseApp.firestore().collection(path);
    return query;
  }, [path, updateKey]);

  const [result, loading, error] = useCollectionOnce(query);

  const docs = useMemo(() => {
    if (result) {
      return result.docs.map(res => {
        const data = res.data();

        return {
          id: res.id,
          ...data
        };
      });
    }
    return null;
  }, [result]);

  if (loading) {
    return LOADING;
  }

  if (error) {
    throw error;
  }

  return docs;
};
