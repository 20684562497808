import React, { useState, useMemo } from "react";
import { Card, InputBase, Tabs, Tab, Paper, colors } from "@material-ui/core";
import styled from "styled-components";
import { Search as SearchIcon } from "@material-ui/icons";
import { useServiceProviders } from "@/hooks/useServiceProviders";
import { awaitData, LOADING } from "@/hooks/awaitData";
import { ProviderSearchResult } from "./ProviderSearchResult";
import theme from "@/config/theme";
import { FilterTab, FilterTabs } from "./FilterTab";

const categories = [
  {
    id: "all",
    label: "All"
  },

  {
    id: "beauty",
    label: "Beauty"
  },

  {
    id: "moving",
    label: "Moving & Delivery"
  },

  {
    id: "professional_services",
    label: "Professional Skills"
  }
];

const CategoryTabs = styled(FilterTabs)`
  ${FilterTab}:first-child {
    margin-left: 16px;
  }

  ${FilterTab}:last-child {
    margin-right: 16px;
  }
`;

const SearchPageContainer = styled(Paper).attrs({ elevation: 3 })`
  && {
    background-color: ${colors.grey[100]};
    border-radius: 0;
    flex-grow: 1;
    min-height: 100vh;
    z-index: 1;
  }
`;

const SearchHeader = styled(Card).attrs({ elevation: 1 })`
  && {
    margin: 32px;
    display: flex;
    align-items: stretch;

    ${theme.breakpoints.down("xs")} {
      flex-direction: column;
      margin: 0;
      border-radius: 0;
      margin-bottom: 32px;
    }
  }
`;

const SearchContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

const SearchInput = styled(InputBase)`
  flex-grow: 1;

  && input {
    height: auto;
    line-height: 56px;
    font-size: 16px;
    padding: 0;
    width: 100%;
  }
`;

const SearchLabel = styled.label`
  padding: 0 16px;
  line-height: 0;
`;

const ResultContainer = styled.div`
  display: grid;
  grid-gap: 32px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  padding: 32px;
  padding-top: 0;

  ${theme.breakpoints.down("xs")} {
    padding: 16px;
    padding-top: 0;
    grid-gap: 16px;
  }
`;

export const SearchPage = () => {
  const providers = useServiceProviders();
  const [category, setCategory] = useState("all");

  const filtered = useMemo(() => {
    if (category === "all") {
      return providers;
    }

    if (providers === LOADING) {
      return providers;
    }

    return providers.filter(provider =>
      provider.serviceCategories.includes(category)
    );
  }, [providers, category]);

  return (
    <SearchPageContainer>
      <SearchHeader>
        <SearchContainer>
          <SearchLabel htmlFor="product-search">
            <SearchIcon />
          </SearchLabel>
          <SearchInput
            id="product-search"
            placeholder="What can we do for you today?"
          />
        </SearchContainer>

        <CategoryTabs
          onChange={(ev, tab) => setCategory(tab)}
          indicatorColor="primary"
          textColor="primary"
          value={category}
          variant="scrollable"
        >
          {categories.map(({ id, label }) => {
            return <FilterTab key={id} value={id} label={label} />;
          })}
        </CategoryTabs>
      </SearchHeader>

      <ResultContainer>
        {awaitData(filtered, {
          loading() {
            return <div>Loading...</div>;
          },

          data(providers) {
            return providers.map(provider => (
              <ProviderSearchResult key={provider.id} provider={provider} />
            ));
          }
        })}
      </ResultContainer>
    </SearchPageContainer>
  );
};
