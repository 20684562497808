import * as React from "react";
import { useState } from "react";
import { SitePage } from "@/layouts/SitePage";
import { GrowLoading } from "@/components/GrowLoading";
import { useUserProfile, UserProfile } from "@/hooks/useUserProfile";
import { RouteComponentProps } from "react-router";
import { providerConnectStripe } from "@/config/firebase";
import { AppError } from "@/components/AppError";
import { useRequireUser } from "@/hooks/useCurrentUser";
import { awaitData } from "@/hooks/awaitData";
import { EmptyState } from "@/components/EmptyState";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { Typography, Button } from "@material-ui/core";
import styled from "styled-components";
import { SiteLink } from "@/components/SiteLink";

const SuccessIcon = styled(CheckCircle)`
  && {
    font-size: 64px;
    margin-bottom: 16px;
  }
`;

const Body = styled(Typography)`
  && {
    margin: 16px;
    margin-bottom: 32px;
    max-width: 600px;
  }
`;

const ConnectCallback = ({
  code
}: RouteComponentProps & { profile: UserProfile; code: string }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  React.useEffect(() => {
    providerConnectStripe({ code })
      .then(() => {
        setLoading(false);
      })
      .catch((e: any) => {
        console.error(e);
      });
  }, []);

  if (error) {
    return <AppError />;
  }

  if (!loading) {
    return (
      <SitePage gray>
        <EmptyState>
          <SuccessIcon />
          <Typography variant="h5">
            You're ready to recieve payments!
          </Typography>
          <Body>
            Your account is setup to receive payments. You can access your
            payment settings, view your balances and get paid by visiting your
            helper account any time.
          </Body>
          <Button
            variant="contained"
            color="primary"
            component={SiteLink}
            href={"/service/payments"}
          >
            Back to payment settings
          </Button>
        </EmptyState>
      </SitePage>
    );
  }

  return (
    <SitePage gray>
      <GrowLoading />
    </SitePage>
  );
};

export default (props: RouteComponentProps) => {
  useRequireUser();
  const profile = useUserProfile();
  const params = new URLSearchParams(props.location.search.slice(1));
  const code = params.get("code");
  const state = params.get("state");

  return awaitData(profile, {
    loading() {
      return <GrowLoading />;
    },

    data(profile) {
      if (code === null) {
        return <AppError />;
      }

      if (state !== profile.key) {
        return (
          <AppError
            title="Oops.. looks like this is the wrong account."
            body="Please login with the account you originally used to start setting up with payments."
          />
        );
      }

      return <ConnectCallback {...props} profile={profile} code={code} />;
    }
  });
};
