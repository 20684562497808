import React, { useState, useMemo, useCallback } from "react";
import styled from "styled-components";
import { colors } from "@material-ui/core";
import { format } from "date-fns";
import Slider from "@material-ui/core/Slider";
import theme from "../config/theme";

const TimeStyledSlider = styled(Slider).attrs({
  classes: { rail: "slider-rail", track: "slider-track", thumb: "slider-thumb" }
})`
  && {
    position: relative;
    margin-top: 36px;
    height: 25px;
    padding: 0;

    .slider-track {
      display: none;
    }

    .slider-thumb {
      opacity: 0;
    }

    .slider-rail {
      height: 25px;
      opacity: 1;
      background-color: ${colors.grey[200]};
      border-radius: 8px;
    }
  }
`;

const SliderWrapper = styled.div`
  position: relative;
  margin-bottom: 12px;
`;

const AppointmentSlot = styled.div`
  && {
    position: absolute;
    background-color: #007bbc;
    height: 100%;
    border-radius: 0;
    top: 0;
    left: 300px;
    transform: translateX(-50%);
  }
`;

const TimeLabel = styled.div`
  position: absolute;
  bottom: calc(100% + 16px);
  white-space: nowrap;
  transform: translateX(-50%);
  color: ${theme.palette.text.primary};
  line-height: 28px;
  border-radius: 14px;
  background-color: ${theme.palette.grey[200]};
  padding: 0 12px;
`;

const makeTimeValueLabel = slotWidth => ({
  children,
  valueLabelFormat,
  value
}) => {
  return (
    <div>
      <TimeLabel style={{ left: value + "%" }}>
        {valueLabelFormat(value)}
      </TimeLabel>
      <AppointmentSlot style={{ left: value + "%", width: slotWidth }} />
      {children}
    </div>
  );
};

const TimeMark = styled.div`
  position: absolute;
  font-size: 10px;
  color: ${colors.grey[600]};
  transform: translate(-50%);
  white-space: nowrap;
  margin-top: 2px;

  &:after {
    position: absolute;
    bottom: calc(100% + 8px);
    content: "";
    display: "inline-block";
    height: 25px;
    width: 1px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const Labels = styled.div`
  display: flex;

  & > ${TimeMark}:last-child {
    transform: translateX(-100%);

    &:after {
      display: none;
    }
  }

  & > ${TimeMark}:first-child {
    transform: translateX(0);

    &:after {
      display: none;
    }
  }
`;

const pct = value => {
  return `${value * 100}%`;
};

const msHour = 60 * 60 * 1000;
const msStep = 10 * 60 * 1000;

const stepValue = (stepEvery, value) => {
  return Math.round(value / stepEvery) * stepEvery;
};

const toSlider = (relLength, value) => {
  return relLength / 2 + (1 - relLength) * value;
};

const fromSlider = (relLength, value) => {
  const relMargin = relLength / 2;
  const bound = Math.max(relMargin, Math.min(1 - relMargin, value)) - relMargin;
  return bound / (1 - relLength);
};

export const TimeSlider = ({
  className,
  startRange,
  endRange,
  appointmentStart: sourceStart = startRange,
  appointmentLength,
  onChangeStart
}) => {
  const [dragStart, setDragStart] = useState(null);
  const length = endRange - startRange;
  const rangeAvailable = endRange - startRange - appointmentLength;
  const appointmentStart = dragStart == null ? sourceStart : dragStart;

  const relAppointmentStart = (appointmentStart - startRange) / rangeAvailable;
  const relAppointmentLength = appointmentLength / length;

  const hoursLength = length / msHour;
  const multiplier = hoursLength >= 20 ? 4 : 2;
  const markCount = Math.floor(hoursLength / multiplier) + 1;

  const TimeValue = useMemo(
    () => makeTimeValueLabel(relAppointmentLength * 100 + "%"),
    [relAppointmentLength]
  );
  const formatTime = useCallback(
    relativeTime => {
      return format(
        startRange +
          rangeAvailable * fromSlider(relAppointmentLength, relativeTime / 100),
        "h:mm a"
      );
    },
    [startRange, rangeAvailable, relAppointmentLength]
  );

  return (
    <>
      <SliderWrapper className={className}>
        <TimeStyledSlider
          valueLabelDisplay="on"
          value={100 * toSlider(relAppointmentLength, relAppointmentStart)}
          valueLabelFormat={formatTime}
          ValueLabelComponent={TimeValue}
          onChange={(e, value) => {
            const pct = fromSlider(relAppointmentLength, value / 100);
            const date = stepValue(msStep, startRange + rangeAvailable * pct);
            setDragStart(date);
          }}
          onChangeCommitted={(e, value) => {
            const pct = fromSlider(relAppointmentLength, value / 100);
            const date = stepValue(msStep, startRange + rangeAvailable * pct);
            onChangeStart(date);
            setDragStart(null);
          }}
        />
        <Labels>
          {Array.from(new Array(markCount)).map((_, index) => {
            const startTime = Math.ceil(
              startRange + index * msHour * multiplier
            );
            const relStartPosition = (startTime - startRange) / length;

            return (
              <TimeMark key={index} style={{ left: pct(relStartPosition) }}>
                {format(startTime, "ha")}
              </TimeMark>
            );
          })}
        </Labels>
      </SliderWrapper>
    </>
  );
};
