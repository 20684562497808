import { makeRule, useForm } from "@/hooks/useForm";
import { withProps } from "@/utils/withProps";
import { Typography, CardContent } from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { TextFormField } from "../TextFormField";
import { StepProps } from "./steps";
import { StepHeader } from "./styles";
import { useSaveProfileDescription } from "@/config/collections/provider";

const BioContainer = styled.div`
  display: flex;
  align-items: strech;
  flex-direction: column;
  margin-top: 16px;
`;

const BioField = styled(TextFormField)`
  textarea {
    min-height: 4em;
    max-height: 8em;
  }
`;

const serviceForm = {
  serviceDescription: {
    getErrors: makeRule(
      "Please provide a bio for your profile.",
      (value: string) => value.length > 0
    ),
    field: withProps(BioField, {
      label: "Your Profile Bio",
      variant: "outlined",
      multiline: true
    })
  }
};

const defaultForm = {
  serviceDescription: ""
};

export const ServiceIntro = ({ buttons, onNext, provider }: StepProps) => {
  const [fields, value, errors, { setShowErrors }] = useForm(serviceForm, {
    serviceDescription: provider.serviceDescription || ""
  });
  const [loading, setLoading] = useState();
  const saveDescription = useSaveProfileDescription();

  const handleNext = React.useCallback(() => {
    if (errors.length > 0) {
      setShowErrors(true);
    } else {
      setLoading(true);
      saveDescription(provider.key, value).then(() => {
        setLoading(false);
        onNext();
      });
    }
  }, [value, provider, saveDescription, setShowErrors]);

  return (
    <>
      <CardContent>
        <StepHeader>Your Profile Bio</StepHeader>
        <Typography variant="body1">
          Please provide a bio with a little bit about yourself and your
          services for your profile.
        </Typography>

        <BioContainer>{fields.serviceDescription}</BioContainer>
      </CardContent>

      {buttons({ loading, onNext: handleNext })}
    </>
  );
};
