export const getCoverCrop = (image, { height, width }) => {
  if (image.naturalHeight > image.naturalWidth) {
    const cropWidth = image.naturalWidth;
    const cropHeight = (height / width) * image.naturalWidth;
    const y = (image.naturalHeight - cropHeight) / 2;

    return {
      width: cropWidth,
      height: cropHeight,
      y,
      x: 0,
      scale: width / image.naturalWidth
    };
  }

  const cropWidth = (width / height) * image.naturalHeight;
  const cropHeight = image.naturalHeight;
  const x = (image.naturalWidth - cropWidth) / 2;

  return {
    width: cropWidth,
    height: cropHeight,
    y: 0,
    x,
    scale: height / image.naturalHeight
  };
};

export const resizeThumbnail = (image, crop) => {
  const canvas = document.createElement("canvas");
  canvas.width = crop.width * crop.scale;
  canvas.height = crop.height * crop.scale;
  const ctx = canvas.getContext("2d");

  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    crop.width * crop.scale,
    crop.height * crop.scale
  );

  return canvas.toDataURL("image/jpeg");
};
