import { CreateService } from "@/components/CreateService";
import { GrowLoading } from "@/components/GrowLoading";
import { ContactInformation } from "@/components/providerSetup/ContactInformation";
import { EditServices } from "@/components/providerSetup/EditServices";
import { ProviderLocation } from "@/components/providerSetup/ProviderLocations";
import { ProviderProfile } from "@/components/providerSetup/ProviderProfile";
import { ServiceIntro } from "@/components/providerSetup/ServiceIntro";
import { StepProps } from "@/components/providerSetup/steps";
import { CardTitle, StepHeader } from "@/components/providerSetup/styles";
import { SiteLink } from "@/components/SiteLink";
import { useExistingOrNewProvider } from "@/config/collections/provider";
import { awaitData } from "@/hooks/awaitData";
import { ServicePortal } from "@/layouts/ServicePortal";
import { join } from "@/utils/routes";
import {
  Button,
  CardActions,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemText
} from "@material-ui/core";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import * as React from "react";
import { useState } from "react";
import * as ReactDOM from "react-dom";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import styled from "styled-components";

const BackButton = styled(Button)`
  && {
    padding-left: 0;
  }
`;

interface ProviderStep {
  id: string;
  label: string;
  title?: (provider: ServiceProvider) => React.ReactNode;
  component: React.ComponentType<StepProps>;
}

const Title = styled(StepHeader)`
  && {
    margin-right: auto;
    margin-bottom: 0;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const sections: ProviderStep[] = [
  {
    id: "profile",
    label: "Name & Profile Photo",
    component: ProviderProfile
  },

  {
    id: "bio",
    label: "Profile Bio",
    component: ServiceIntro
  },

  {
    id: "contact",
    label: "Contact Information",
    title: () => <StepHeader>Contact Information</StepHeader>,
    component: ContactInformation
  },

  {
    id: "locations",
    label: "Delivery Locations",
    title: () => <StepHeader>Your Delivery Locations</StepHeader>,
    component: ProviderLocation
  },

  {
    id: "services",
    label: "Your Skills",
    title: provider => (
      <TitleContainer>
        <Title>Your Skills</Title>
        <CreateService providerId={provider.key} />
      </TitleContainer>
    ),
    component: ({ provider }) => <EditServices providerId={provider.key} />
  }
];

const renderStep = (
  Component: any,
  title: any,
  back: string,
  provider: any,
  el: any
) => {
  return (
    <>
      <Component
        buttons={({ onNext, loading, disableNext }) =>
          el &&
          ReactDOM.createPortal(
            <>
              <Divider />
              <CardActions>
                <Button
                  disabled={loading || disableNext}
                  onClick={onNext}
                  color="primary"
                >
                  Save
                </Button>
              </CardActions>
              {loading ? <LinearProgress /> : null}
            </>,
            el
          )
        }
        onNext={() => null}
        onBack={() => null}
        provider={provider}
      />
    </>
  );
};

const Profile = ({ match }: RouteComponentProps) => {
  const provider = useExistingOrNewProvider();
  const [el, setEl] = useState(null as null | HTMLDivElement);

  return awaitData(provider, {
    loading: () => (
      <ServicePortal>
        <GrowLoading />
      </ServicePortal>
    ),
    data: provider => (
      <Switch>
        <Route
          path={`${match.url}`}
          exact={true}
          render={() => {
            return (
              <ServicePortal>
                <CardTitle>
                  <StepHeader>Profile</StepHeader>
                </CardTitle>
                <List component="nav">
                  <Divider />
                  {sections.map(value => {
                    return (
                      <React.Fragment key={value.id}>
                        <ListItem
                          button
                          component={SiteLink}
                          href={join(match.url, value.id)}
                        >
                          <ListItemText primary={value.label}></ListItemText>
                        </ListItem>
                        <Divider />
                      </React.Fragment>
                    );
                  })}
                </List>
              </ServicePortal>
            );
          }}
        />
        {sections.map(value => {
          return (
            <Route
              key={value.id}
              path={join(match.url, value.id)}
              render={() => {
                return (
                  <ServicePortal
                    hideMobileNavigation={true}
                    header={
                      <CardTitle>
                        <BackButton
                          size="small"
                          component={SiteLink}
                          href={match.url}
                        >
                          <NavigateBefore /> Back
                        </BackButton>
                        {value.title && value.title(provider)}
                      </CardTitle>
                    }
                    actions={<div ref={setEl} />}
                  >
                    {renderStep(
                      value.component,
                      value.title,
                      match.url,
                      provider,
                      el
                    )}
                  </ServicePortal>
                );
              }}
            />
          );
        })}
      </Switch>
    )
  });
};

export default Profile;
