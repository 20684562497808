import * as React from "react";
import { Dialog, CardActions, Button } from "@material-ui/core";
import { CreateAppointment } from "./CreateAppointment";
import styled from "styled-components";
import { SiteDialog } from "@/components/SiteDialog";

const Container = styled.div`
  width: 600px;
  max-width: 100vw;
  min-height: 400px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const ScheduleAppointmentDialog = ({ onClose, startDate }) => {
  return (
    <SiteDialog open={true}>
      <Container>
        <CreateAppointment
          startDate={startDate}
          onNext={onClose}
          buttons={({ onNext }) => (
            <>
              <CardActions style={{ marginTop: "auto" }}>
                <Button onClick={onClose} style={{ marginRight: "auto" }}>
                  Cancel
                </Button>
                <Button onClick={onNext} color="primary">
                  Update Appointment
                </Button>
              </CardActions>
            </>
          )}
        />
      </Container>
    </SiteDialog>
  );
};
