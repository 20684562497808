import * as React from "react";
import { EditServiceDialog } from "./EditServiceDialog";
import { useServiceDraft } from "@/config/collections/service";
import { awaitData } from "@/hooks/awaitData";

interface Props {
  onClose: () => void;
  serviceKey: string;
}

export const EditExistingService = ({ serviceKey, onClose }: Props) => {
  const service = useServiceDraft(serviceKey);

  return awaitData(service, {
    loading: () => null,
    data: service => <EditServiceDialog service={service} onClose={onClose} />
  });
};
