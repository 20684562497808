import theme from "@/config/theme";
import { awaitData } from "@/hooks/awaitData";
import { useUserProfile } from "@/hooks/useUserProfile";
import { useRouter } from "@/utils/routes";
import {
  BottomNavigation,
  BottomNavigationAction,
  Button,
  Divider,
  Hidden,
  AppBar,
  IconButton,
  Toolbar,
  Typography
} from "@material-ui/core";
import { ArrowBack, Close } from "@material-ui/icons";
import * as React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import { BookingCard } from "../components/BookingCard";
import { FilterTab, FilterTabs } from "../components/FilterTab";
import { GrowLoading } from "../components/GrowLoading";
import { ScrollList } from "../components/ScrollList";
import { SiteLink } from "../components/SiteLink";
import { firebaseApp } from "../config/firebase";
import { SitePage } from "./SitePage";
import { MyAccount } from "@/components/MyAccount";
import { Navigation } from "@/components/Navigation";

interface TabInfo {
  pathname: string;
  label: string;
  icon: string | React.ReactElement;
}

interface Props {
  children: React.ReactNode;
  actions?: React.ReactNode;
  accountHref: string;
  title?: React.ReactNode;
  header?: React.ReactNode;
  hideMobileNavigation?: boolean;
  tabs: TabInfo[];
}

const TabDivider = styled(Divider)`
  && {
    color: rgba(0, 0, 0, 0.05);
  }
`;

const ContentContainer = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: center;

  ${theme.breakpoints.down("xs")} {
    flex-direction: column;
    margin-top: 0;
    height: 100vh;
  }
`;

const NavigationContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-right: 24px;

  * {
    white-space: nowrap;
  }
`;

const BackButton = styled(Button)`
  && {
    align-self: flex-start;
    margin-left: 8px;
  }
`;

const PageTabs = styled(FilterTabs)`
  width: auto;
`;

const PageTab = styled(FilterTab).attrs({
  classes: { wrapper: "page-tab-wrapper" }
})`
  && {
    min-height: 40px;
    height: 40px !important;
    padding: 0 8px;

    .page-tab-wrapper {
      align-items: center;
      display: flex;
      flex-direction: row;
      text-align: left;
      justify-content: start;
    }
  }
`;

export const CardPage = ({
  children,
  title,
  accountHref,
  tabs,
  hideMobileNavigation,
  header,
  actions
}: Props) => {
  const {
    location: { pathname }
  } = useRouter();
  const profile = useUserProfile();

  const selectedTab = tabs.find(tab => pathname.startsWith(tab.pathname));

  const handleLogout = useCallback(async () => {
    await firebaseApp.auth().signOut();
    window.location.href = "/";
  }, []);

  return (
    <SitePage gray>
      {awaitData(profile, {
        loading() {
          return <GrowLoading />;
        },

        data(profile) {
          return (
            <ContentContainer>
              <Hidden smUp>
                {!hideMobileNavigation && (
                  <AppBar position="static">
                    <Toolbar>
                      <IconButton
                        size="small"
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                      >
                        <Navigation collapse={true} side="left" />
                      </IconButton>
                      <Typography variant="h6">{title}</Typography>
                    </Toolbar>
                  </AppBar>
                )}
              </Hidden>
              <Hidden xsDown>
                <NavigationContainer>
                  <BackButton component={SiteLink} href="/" size="small">
                    <>
                      <ArrowBack />
                      Back to services
                    </>
                  </BackButton>

                  <MyAccount profile={profile} href={accountHref} />

                  <PageTabs
                    indicatorColor="primary"
                    orientation="vertical"
                    value={selectedTab && selectedTab.pathname}
                  >
                    {tabs.map(tab => (
                      <PageTab
                        component={SiteLink}
                        href={tab.pathname}
                        key={tab.pathname}
                        value={tab.pathname}
                        label={tab.label}
                      />
                    ))}
                    <PageTab
                      value="log-out"
                      label="Log out"
                      onClick={handleLogout}
                    />
                  </PageTabs>
                </NavigationContainer>
              </Hidden>
              <BookingCard>
                {header}
                <ScrollList>{children}</ScrollList>
                {actions}
              </BookingCard>
              <TabDivider />
              <Hidden smUp>
                {!hideMobileNavigation && (
                  <BottomNavigation
                    color={"secondary"}
                    value={selectedTab && selectedTab.pathname}
                  >
                    {tabs.map(tab => {
                      return (
                        <BottomNavigationAction
                          component={SiteLink}
                          href={tab.pathname}
                          key={tab.pathname}
                          value={tab.pathname}
                          label={tab.label}
                          icon={tab.icon}
                        />
                      );
                    })}
                  </BottomNavigation>
                )}
              </Hidden>
            </ContentContainer>
          );
        }
      })}
    </SitePage>
  );
};
