import { Service, useSaveService } from "@/config/collections/service";
import { makeRule, useForm, Fields } from "@/hooks/useForm";
import { withProps } from "@/utils/withProps";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress
} from "@material-ui/core";
import * as React from "react";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { SiteDialog } from "./SiteDialog";
import { TextFormField } from "./TextFormField";
import { NumberFormatField } from "./NumberFormatField";
import { SelectFormField } from "./SelectFormField";
import { createSubform } from "@/utils/createSubform";

interface Props {
  onClose: () => void;
  service: Service;
}

const Content = styled.div`
  max-width: 100vw;
  width: 500px;
  flex-grow: 1;
`;

const ServiceFormContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const CancelButton = styled(Button)`
  && {
    margin-right: auto;
  }
`;

const Title = styled(DialogTitle)`
  && {
    padding-bottom: 0;
  }
`;

const Row = styled.div`
  display: flex;

  > * {
    flex: 1;

    &:not(:last-child) {
      margin-right: 16px;
    }
  }
`;

const numPattern = /^\$[0-9]*(\.[0-9]+)?$/;
const durationPattern = /^[0-9]+( minutes)?$/;

const hourlyPriceForm = createSubform<ServiceForm["hourlyPrice"]>({
  value: {
    getErrors: makeRule("Enter a valid price", (value: string) =>
      numPattern.test(value)
    ),

    field: withProps(NumberFormatField, {
      prefix: "$",
      label: "Price"
    })
  },

  minMinutes: {
    getErrors: makeRule(
      "Enter a valid minimum appointment length",
      (value: string) => durationPattern.test(value)
    ),

    field: withProps(NumberFormatField, {
      label: "Minimum appointment",
      placeholder: "e.g 60 minutes",
      suffix: " minutes"
    })
  }
});

const fixedPriceForm = createSubform<ServiceForm["fixedPrice"]>({
  value: {
    getErrors: makeRule("Enter a valid price", (value: string) =>
      numPattern.test(value)
    ),

    field: withProps(NumberFormatField, {
      prefix: "$",
      label: "Price"
    })
  },

  durationMinutes: {
    getErrors: makeRule("Enter a valid appointment duration", (value: string) =>
      durationPattern.test(value)
    ),

    field: withProps(NumberFormatField, {
      label: "Duration",
      placeholder: "e.g 45 minutes",
      suffix: " minutes"
    })
  }
});

interface ServiceForm {
  name: string;
  description: string;
  priceType: "fixed" | "hourly";
  fixedPrice: {
    durationMinutes: string;
    value: string;
  };
  hourlyPrice: {
    minMinutes: string;
    value: string;
  };
}

const serviceForm: Fields<ServiceForm> = {
  name: {
    getErrors: makeRule(
      "Enter a service name",
      (name: string) => name.length > 0
    ),

    field: withProps(TextFormField, {
      label: "Name"
    })
  },

  fixedPrice: {
    ...fixedPriceForm,

    getErrors: (value, form) => {
      if (form.priceType === "fixed") {
        return fixedPriceForm.getErrors(value, form);
      }

      return [];
    }
  },

  hourlyPrice: {
    ...hourlyPriceForm,

    getErrors: (value, form) => {
      if (form.priceType === "hourly") {
        return hourlyPriceForm.getErrors(value, form);
      }

      return [];
    }
  },

  priceType: {
    field: withProps(SelectFormField, {
      label: "Pricing",
      options: [
        { value: "fixed", label: "Fixed" },
        { value: "hourly", label: "Hourly" }
      ]
    })
  },

  description: {
    getErrors: makeRule(
      "Enter a service description",
      (description: string) => description.length > 0
    ),

    field: withProps(TextFormField, {
      label: "Description",
      multiline: true,
      rows: 2
    })
  }
};

const getServiceForm = (service: Service): ServiceForm => {
  return {
    name: service.name,
    description: service.description,
    priceType: service.price ? service.price.type : "fixed",
    fixedPrice:
      service.price && service.price.type === "fixed"
        ? {
            value: String(service.price.value),
            durationMinutes: String(service.price.durationMinutes)
          }
        : emptyService.fixedPrice,
    hourlyPrice:
      service.price && service.price.type === "hourly"
        ? {
            value: String(service.price.value),
            minMinutes: String(service.price.minMinutes)
          }
        : emptyService.hourlyPrice
  };
};
const emptyService = {
  name: "",
  description: "",

  priceType: "fixed",

  fixedPrice: {
    value: "",
    durationMinutes: ""
  },

  hourlyPrice: {
    value: "",
    minMinutes: ""
  }
};

const cleanAndParseNumber = (value: string) => {
  return parseFloat(value.replace(/[^\d\.]/g, ""));
};

const getServiceFromForm = (service: ServiceForm): Service => {
  return {
    name: service.name,
    description: service.description,
    price:
      service.priceType === "hourly"
        ? {
            type: "hourly",
            value: cleanAndParseNumber(service.hourlyPrice.value),
            minMinutes: cleanAndParseNumber(service.hourlyPrice.minMinutes)
          }
        : {
            type: "fixed",
            value: cleanAndParseNumber(service.fixedPrice.value),
            durationMinutes: cleanAndParseNumber(
              service.fixedPrice.durationMinutes
            )
          }
  };
};

export const EditServiceDialog = ({ service, onClose }: Props) => {
  const saveService = useSaveService();
  const [fields, value, errors, { setShowErrors }] = useForm(
    serviceForm,
    getServiceForm(service)
  );

  const [loading, setLoading] = useState(false);
  const onSave = useCallback(() => {
    if (errors.length > 0) {
      setShowErrors(true);
    } else {
      setLoading(true);
      const newService = getServiceFromForm(value);

      saveService(service.key, newService).then(() => {
        setLoading(false);
        onClose();
      });
    }
  }, [errors, setShowErrors, service.key, value]);

  return (
    <SiteDialog open={true} onClose={onClose}>
      <Content>
        <Title>Edit Service</Title>
        <DialogContent>
          <ServiceFormContainer>
            <Row>
              {fields.name}
              {fields.priceType}
            </Row>
            <Row>
              {value.priceType === "fixed"
                ? fields.fixedPrice
                : fields.hourlyPrice}
            </Row>
            {fields.description}
          </ServiceFormContainer>
        </DialogContent>
        <DialogActions style={{ marginTop: "auto" }}>
          <Button disabled={loading} color="primary" onClick={onSave}>
            Save
          </Button>
          <CancelButton disabled={loading} onClick={onClose}>
            Cancel
          </CancelButton>
        </DialogActions>
        {loading ? <LinearProgress /> : null}
      </Content>
    </SiteDialog>
  );
};
