import React from "react";
import { List, Divider, ListItem, ListItemIcon } from "@material-ui/core";
import styled from "styled-components";
import { Check } from "@material-ui/icons";
import theme from "../config/theme";

const CheckIconContainer = styled(ListItemIcon)`
  && {
    min-width: 0;
  }
`;

const ListSelectItem = ({ children, selected, onSelect }) => (
  <ListItem button onClick={onSelect} selected={selected}>
    {children}
    {selected && (
      <CheckIconContainer>
        <Check color="primary" />
      </CheckIconContainer>
    )}
  </ListItem>
);

const QuestionList = styled(List)`
  && {
    padding: 0;
  }
`;

export const CardChoice = ({ value, options, onChange }) => {
  return (
    <QuestionList>
      {options.map((option, index) => (
        <React.Fragment key={option.id}>
          {index !== 0 && <Divider component="li" />}
          <ListSelectItem
            selected={value === option.value}
            onSelect={() => onChange(option.value)}
          >
            {option.label}
          </ListSelectItem>
        </React.Fragment>
      ))}
    </QuestionList>
  );
};
