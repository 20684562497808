export const LOADING = Symbol("loading");

export const isLoading = (data: any): data is typeof LOADING => {
  return data === LOADING;
};

export type Load<T> = T | typeof LOADING;

interface AwaitData {
  <T, R>(
    result: typeof LOADING | T,
    options: {
      loading: () => R;
      data: (value: T) => R;
    }
  ): R;

  <T, R>(
    result: typeof LOADING | T,
    options: {
      data: (value: T) => R;
    }
  ): R | typeof LOADING;

  <T, R>(result: typeof LOADING | T, options: (value: T) => R):
    | R
    | typeof LOADING;
}

export const awaitData: AwaitData = <T, R>(
  result: typeof LOADING | T,
  options:
    | {
        loading?: () => R;
        data: (value: T) => R;
      }
    | Function
) => {
  if (options instanceof Function) {
    if (result === LOADING) {
      return result;
    }

    return options(result);
  }

  if (result === LOADING) {
    return options.loading ? options.loading() : LOADING;
  }

  return options.data(result);
};
