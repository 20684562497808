import { Button } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import { PropsOf } from "@/utils/types";

type Props = {
  children: React.ReactNode;
} & PropsOf<typeof Button>;

const Unpadded = styled(Button)`
  && {
    padding-left: 0;
  }
`;

export const BackButton = ({ children, ...rest }: Props) => {
  return (
    <Unpadded {...rest}>
      <NavigateBefore /> {children}
    </Unpadded>
  );
};
