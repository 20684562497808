import { steps } from "@/components/providerSetup/steps";
import { useExistingOrNewProvider } from "@/config/collections/provider";
import { awaitData, LOADING } from "@/hooks/awaitData";
import { SitePage } from "@/layouts/SitePage";
import {
  Card,
  Step,
  StepContent,
  StepLabel,
  useMediaQuery
} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import NavigateBefore from "@material-ui/icons/NavigateBefore";
import * as React from "react";
import { useCallback, useState } from "react";
import { SiteLink } from "../SiteLink";
import { StepButtons } from "./StepButtons";
import {
  CardTitle,
  MobileStepContainer,
  SetupCard,
  SetupContainer,
  SetupStepper
} from "./styles";
import { verifyReferralCode } from "@/config/firebase";
import theme from "@/config/theme";
import { ScrollList } from "../ScrollList";
import * as ReactDOM from "react-dom";

export type GetProviderButtons = (options: {
  onNext?: () => void;
  loading?: boolean;
  nextLabel?: React.ReactChild;
  disableNext?: boolean;
}) => React.ReactNode;

const MobileSetup = ({
  onContinue,
  verified,
  code,
  onBack,
  provider,
  stepIndex
}: any) => {
  const step = steps[stepIndex];
  const { component: Component } = step;
  const [buttons, setButtons] = React.useState<HTMLDivElement | null>(null);

  return (
    <>
      {step.title ? <CardTitle>{step.title}</CardTitle> : null}
      <ScrollList>
        <Component
          onNext={onContinue}
          verified={verified}
          referralCode={code || undefined}
          onBack={onBack}
          provider={provider}
          buttons={({
            onNext = onContinue,
            loading = false,
            nextLabel = <>Continue</>,
            disableNext = false
          }) =>
            buttons &&
            ReactDOM.createPortal(
              <StepButtons
                nextLabel={nextLabel}
                onNext={disableNext ? undefined : onNext}
                onBack={stepIndex === 0 ? undefined : onBack}
                loading={loading}
              />,
              buttons
            )
          }
        />
      </ScrollList>
      <div style={{ marginTop: "auto" }} ref={setButtons} />
    </>
  );
};

export const ServiceProviderSetup = () => {
  const code = new URLSearchParams(window.location.search.slice(1)).get(
    "referrer"
  );

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [stepIndex, setStep] = useState(0);

  const [verified, setVerified] = useState(null as
    | null
    | boolean
    | typeof LOADING);

  React.useEffect(() => {
    if (code != null && code.length > 0) {
      setVerified(LOADING);
      verifyReferralCode({ code })
        .then(({ data }: { data: boolean }) => {
          setVerified(data);
        })
        .catch((e: any) => {
          console.error(e);
          setVerified(null);
        });
    }
  }, [code]);

  const provider = useExistingOrNewProvider(
    verified === true && code !== null ? code : undefined
  );

  const onContinue = useCallback(() => {
    setStep(Math.min(stepIndex + 1, steps.length - 1));
  }, [stepIndex]);

  const onBack = useCallback(() => {
    setStep(Math.max(stepIndex - 1, 0));
  }, [stepIndex]);

  return awaitData(provider, {
    loading() {
      return null;
    },

    data(provider) {
      return (
        <SitePage gray={!isMobileScreen}>
          <SetupContainer>
            {isMobileScreen ? (
              <MobileSetup
                onContinue={onContinue}
                verified={verified}
                code={code}
                onBack={onBack}
                provider={provider}
                stepIndex={stepIndex}
              />
            ) : (
              <SetupStepper orientation="vertical" activeStep={stepIndex}>
                {steps.map((step, index) => {
                  const { component: Component } = steps[index];
                  return (
                    <Step key={step.id} id={step.id}>
                      <StepLabel>{step.label}</StepLabel>
                      <StepContent>
                        <SetupCard>
                          {step.title ? (
                            <CardTitle>{step.title}</CardTitle>
                          ) : null}
                          <Component
                            onNext={onContinue}
                            verified={verified}
                            referralCode={code || undefined}
                            onBack={onBack}
                            provider={provider}
                            buttons={({
                              onNext = onContinue,
                              loading = false,
                              nextLabel = <>Continue</>,
                              disableNext
                            }) => (
                              <StepButtons
                                nextLabel={nextLabel}
                                onNext={disableNext ? undefined : onNext}
                                onBack={stepIndex === 0 ? undefined : onBack}
                                loading={loading}
                              />
                            )}
                          />
                        </SetupCard>
                      </StepContent>
                    </Step>
                  );
                })}
              </SetupStepper>
            )}
          </SetupContainer>
        </SitePage>
      );
    }
  });
};
