import * as React from "react";
import styled from "styled-components";
import theme from "@/config/theme";

const SiteContainer = styled.div`
  display: relative;
  min-height: 100vh;

  ${theme.breakpoints.down("xs")} {
    min-height: -webkit-fill-available;
    min-height: fill-available;
  }

  display: flex;
  flex-direction: column;
`;

export const SitePage = ({ children, gray }) => {
  return (
    <SiteContainer
      style={{
        backgroundColor: gray ? "#F9F6EE" : "transparent"
      }}
    >
      {children}
    </SiteContainer>
  );
};
