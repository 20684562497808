import { PropsOf } from "@/utils/types";
import { EventAvailable, Settings, Store } from "@material-ui/icons";
import * as React from "react";
import { CardPage } from "./CardPage";

type Props = Omit<PropsOf<typeof CardPage>, "tabs" | "accountHref">;

const tabs = [
  {
    label: "Appointments",
    pathname: "/service/appointments",
    icon: <EventAvailable />
  },

  {
    label: "Profile",
    pathname: "/service/profile-settings",
    icon: <Store />
  },

  {
    label: "Settings",
    pathname: "/service/settings",
    icon: <Settings />
  }
];

export const ServicePortal = (props: Props) => {
  return (
    <CardPage
      accountHref={"/customer/profile"}
      title="Your Helper Account"
      tabs={tabs}
      {...props}
    />
  );
};
