import { Button, Typography } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";
import { LoginTabs } from "@/components/LoginTabs";
import { TextFormField } from "@/components/TextFormField";
import firebaseApp from "@/config/firebase";
import { makeRule, rules, useForm } from "@/hooks/useForm";
import { SitePage } from "@/layouts/SitePage";
import { withProps } from "@/utils/withProps";
import { useRouter } from "@/utils/routes";

const variant = "outlined";
const margin = "normal";
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const authMessages = {
  "auth/email-already-in-use": (
    <>An account with this email is already registered. </>
  ),
  "auth/invalid-email": "Your email doesn't appear to be valid."
};

const loginForm = {
  email: {
    field: withProps(TextFormField, { label: "Email", variant, margin }),
    getErrors: makeRule("Please enter a valid email", email =>
      emailRegex.test(email)
    )
  },

  password: {
    field: withProps(TextFormField, {
      label: "Password",
      type: "password",
      variant,
      margin
    }),
    getErrors: rules(
      makeRule(
        "Password length should be at least 8 characters.",
        password => password.length >= 8
      )
    )
  },

  confirmPassword: {
    field: withProps(TextFormField, {
      label: "Confirm Password",
      type: "password",
      variant,
      margin
    }),
    getErrors: rules(
      makeRule(
        "Make sure your passwords match.",
        (confirm, form) => confirm === form.password
      )
    )
  }
};

const LoginContainer = styled.div`
  && {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    position: absolute;
    top: 10vh;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const NavTabs = styled(LoginTabs)`
  && {
    margin-bottom: 32px;
  }
`;

const JoinButton = styled(Button)`
  && {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

const initialState = {
  email: "",
  password: "",
  confirmPassword: ""
};

const Join = ({ on }) => {
  const [errorCode, setErrorCode] = React.useState();
  const [fields, details, errors, { setShowErrors }] = useForm(
    loginForm,
    initialState
  );
  const router = useRouter();
  const query = new URLSearchParams(router.location.search.slice(1));

  const createUser = React.useCallback(async () => {
    if (errors.length === 0) {
      const user = await firebaseApp
        .auth()
        .createUserWithEmailAndPassword(details.email, details.password)
        .then(user => {
          return firebaseApp
            .firestore()
            .collection("users")
            .doc(user.user.uid)
            .set({
              roles: [],
              serviceProvider: null,
              email: details.email,
              firstName: "",
              lastName: ""
            })
            .then(() => user);
        })
        .catch(e => {
          console.error(e);
          setErrorCode(e.code);
        });

      if (user != null) {
        window.location = query.get("r") || "/";
      }
    } else {
      setShowErrors(true);
    }
  }, [details, errors, setShowErrors]);

  return (
    <SitePage gray>
      <LoginContainer>
        <NavTabs active="createAccount" />
        {errorCode && (
          <Typography variant="subtitle2" color="error">
            {authMessages[errorCode] ||
              "We're sorry, an unknown error occured."}
          </Typography>
        )}

        {fields.email}
        {fields.password}
        {fields.confirmPassword}

        <JoinButton
          onClick={createUser}
          color="primary"
          variant="contained"
          size="large"
        >
          Join
        </JoinButton>
      </LoginContainer>
    </SitePage>
  );
};

export default Join;
