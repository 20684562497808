import * as React from "react";
import { SitePage } from "@/layouts/SitePage";
import styled from "styled-components";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import { Typography, Button } from "@material-ui/core";

const ContentContainer = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 500px;
  padding-left: 16px;
  padding-right: 16px;
`;

const ErrorIcon = styled(ErrorOutline)`
  && {
    font-size: 64px;
    margin-bottom: 16px;
  }
`;

const Body = styled(Typography)`
  && {
    margin-top: 16px;
    margin-bottom: 32px;
  }
`;

const defaultTitle = "Oops... something's not right.";
const defaultBody =
  "Something went wrong, but we're not quite sure what happened. Please try again, or if the issue persists you cantact us at admin@hallopr.com";

export const AppError = ({ title = defaultTitle, body = defaultBody }) => {
  return (
    <SitePage gray>
      <ContentContainer>
        <ErrorIcon />
        <Typography variant="h5">{title}</Typography>
        <Body variant="body1">{body}</Body>

        <Button
          color="primary"
          variant="contained"
          size="large"
          onClick={() => (window.location.href = window.location.href)}
        >
          Go Back
        </Button>
      </ContentContainer>
    </SitePage>
  );
};
