import { GrowLoading } from "@/components/GrowLoading";
import { awaitData } from "@/hooks/awaitData";
import { useRequireUser } from "@/hooks/useCurrentUser";
import { useUserProfile } from "@/hooks/useUserProfile";
import { SitePage } from "@/layouts/SitePage";
import { join } from "@/utils/routes";
import * as React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";
import Appoinments from "./appointments";
import Profile from "./profile";
import ServiceProvider from "./provider";
import Requests from "./requests";
import Setup from "./setup";
import ConnectCallback from "./stripe-connect-callback";
import Settings from './settings'

const Service = ({ match }: RouteComponentProps) => {
  useRequireUser();
  const userProfile = useUserProfile();

  return (
    <Switch>
      <Route path={join(match.url, "setup")} component={Setup} />

      {awaitData(userProfile, {
        loading: () => (
          <SitePage gray>
            <GrowLoading />
          </SitePage>
        ),

        data: profile => {
          if (profile.serviceProvider === null) {
            return <Redirect to="/" />;
          }

          return (
            <Switch>
              <Switch>
                <Route
                  path={join(match.url, "requests")}
                  component={Requests}
                />
                <Route
                  path={join(match.url, "stripe-connect-callback")}
                  component={ConnectCallback}
                />
                <Route
                  path={join(match.url, "settings")}
                  component={Settings}
                />
                <Route
                  path={join(match.url, "profile-settings")}
                  component={Profile}
                />
                <Route
                  path={join(match.url, "appointments")}
                  component={Appoinments}
                />
                <Route
                  path={join(match.url, ":pid")}
                  component={ServiceProvider}
                />
                <Route
                  path={join(match.url, "notifications")}
                  exact={true}
                  render={() => (
                    <Redirect to={join(match.url, "settings/notifications")} />
                  )}
                />
                <Route
                  path={join(match.url, "payments")}
                  exact={true}
                  render={() => (
                    <Redirect to={join(match.url, "settings/payments")} />
                  )}
                />
                <Route
                  path={`${match.url}`}
                  exact={true}
                  render={() => (
                    <Redirect to={join(match.url, "appointments")} />
                  )}
                />
              </Switch>
            </Switch>
          );
        }
      })}
    </Switch>
  );
};

export default Service;
