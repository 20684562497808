import * as React from "react";
import styled from "styled-components";
import { AccountCircle } from "@material-ui/icons";
import theme from "../config/theme";

const Avatar = styled.div`
  height: 36px;
  width: 36px;
  border-radius: 18px;
  background-color: blue;
  margin-right: 8px;
  background-color: #fff;

  > svg {
    width: 100%;
    height: 100%;
    color: ${theme.palette.primary.main};
  }

  input {
    display: none;
  }
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 18px;
`;

export const AvatarImage = ({ src }) => {
  return (
    <Avatar>
      {src ? (
        <Image alt="Profile image" src={src} />
      ) : (
        <AccountCircle viewBox="2 2 20 20" />
      )}
    </Avatar>
  );
};
