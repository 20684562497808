import * as React from "react";
import styled from "styled-components";

interface Props {
  grow?: boolean;
  children?: React.ReactNode;
}

const Container = styled.div`
  min-height: 250px;
  padding: 16px;
  display: flex;
  flex: 1;
`;

const Content = styled.div`
  text-align: center;
  margin: auto;
`;

export const EmptyState = ({ grow, children }: Props) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  );
};
