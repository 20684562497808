import { BackButton } from "@/components/BackButton";
import { ChatBox } from "@/components/ChatBox";
import { ConfirmAppointmentForm } from "@/components/ConfirmAppointmentForm";
import Details from "@/components/BookingDetails";
import { FilterTab, FilterTabs } from "@/components/FilterTab";
import { GrowLoading } from "@/components/GrowLoading";
import { SiteLink } from "@/components/SiteLink";
import { SuccessMessage } from "@/components/SuccessMessage";
import { useBooking, useBookingMessages } from "@/config/collections/booking";
import {
  providerConfirmAppointment,
  providerConfirmAppointmentCompleted,
  providerDenyAppointment
} from "@/config/firebase";

import { awaitData } from "@/hooks/awaitData";
import { useAsyncCallback } from "@/hooks/useAsyncCallback";
import { useRequireUser } from "@/hooks/useCurrentUser";
import { ServicePortal } from "@/layouts/ServicePortal";
import { formatName } from "@/utils/formatName";
import {
  Button,
  CardContent,
  CircularProgress,
  LinearProgress
} from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";

const CenteredProgress = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Appointment = ({ match: { params } }) => {
  useRequireUser();

  const { bid } = params;
  const booking = useBooking(bid);
  const messages = useBookingMessages(bid);
  const [page, setPage] = useState("messages");

  const bookingWithMessages = awaitData(booking, () =>
    awaitData(messages, () => ({ booking, messages }))
  );

  return awaitData(bookingWithMessages, {
    loading: () => (
      <CenteredProgress>
        <CircularProgress />
      </CenteredProgress>
    ),
    data: ({ booking, messages }) => {
      if (booking.status === "payment-confirmed") {
        return (
          <ConfirmAppointment
            bid={bid}
            booking={booking}
            header={
              <CardContent style={{ paddingBottom: "0" }}>
                <BackButton component={SiteLink} href={"/service/appointments"}>
                  Back to appointments
                </BackButton>
              </CardContent>
            }
          ></ConfirmAppointment>
        );
      }

      const header = (
        <>
          <CardContent style={{ paddingBottom: "0" }}>
            <BackButton component={SiteLink} href={"/service/appointments"}>
              Back to appointments
            </BackButton>
          </CardContent>
          <FilterTabs
            variant="fullWidth"
            size="small"
            value={page}
            onChange={(e, tab) => setPage(tab)}
          >
            <FilterTab value="messages" label="Messages">
              Messages
            </FilterTab>
            <FilterTab value="details" label="Details">
              Details
            </FilterTab>
          </FilterTabs>
        </>
      );

      if (page === "messages") {
        return (
          <ChatBox
            header={header}
            booking={booking}
            bookingId={bid}
            messages={messages}
          />
        );
      }

      return (
        <ServicePortal header={header}>
          <CardContent>
            <Details booking={booking} provider={booking.provider} />
          </CardContent>
        </ServicePortal>
      );
    }
  });
};

const ConfirmAppointment = ({ bid, header, booking }) => {
  useRequireUser();

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(null);

  const onConfirmCompleted = useAsyncCallback(() => {
    setLoading(true);

    providerConfirmAppointmentCompleted({
      bookingId: bid
    }).finally(() => setLoading(false));
  });

  const onDeny = useAsyncCallback(
    ({ message }) => {
      setLoading(true);

      return providerDenyAppointment({
        message,
        bookingId: bid
      })
        .finally(() => setLoading(false))
        .then(() => setSuccess("denied"));
    },
    [bid]
  );

  const onAccept = useAsyncCallback(
    ({ message }) => {
      setLoading(true);

      return providerConfirmAppointment({
        message,
        bookingId: bid
      })
        .finally(() => setLoading(false))
        .then(() => setSuccess("accepted"));
    },
    [bid]
  );

  return (
    <ServicePortal header={header}>
      {awaitData(booking, {
        loading: () => {
          return (
            <CenteredProgress>
              <CircularProgress />
            </CenteredProgress>
          );
        },

        data: booking => {
          if (booking.status === "appointment-confirmed") {
            if (loading) {
              return <GrowLoading />;
            }

            return (
              <Button onClick={onConfirmCompleted}>
                Confirm Appointment Complete
              </Button>
            );
          }

          if (success === "accepted") {
            return (
              <CardContent>
                <SuccessMessage
                  title={`Done. We've let ${formatName(
                    booking.client
                  )} know their appointment is confirmed.`}
                />
              </CardContent>
            );
          }

          if (success === "denied") {
            return (
              <CardContent>
                <SuccessMessage
                  title={`Done. We've sent your reply.`}
                  message={`${formatName(
                    booking.client
                  )} can contact you directly at your customer reply email (${
                    booking.provider.customerReply
                  }) to respond.`}
                />
              </CardContent>
            );
          }

          if (booking.status === "payment-confirmed") {
            return (
              <ConfirmAppointmentForm
                loading={loading}
                booking={booking}
                onDeny={onDeny}
                onConfirm={onAccept}
              />
            );
          }

          return null;
        }
      })}
      {loading && <LinearProgress />}
    </ServicePortal>
  );
};

export default Appointment;
