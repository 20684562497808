import * as React from "react";
import {
  Menu,
  MenuItem,
  useMediaQuery,
  ListSubheader,
  List
} from "@material-ui/core";
import { firebaseApp } from "../config/firebase";
import { NavLink } from "./NavLink";
import { SiteLink } from "./SiteLink";
import theme from "@/config/theme";
import { useUserProfile } from "@/hooks/useUserProfile";
import { awaitData } from "@/hooks/awaitData";

export const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState();
  const [bizAnchorEl, setBizAnchorEl] = React.useState();
  const collapseNavigation = useMediaQuery(theme.breakpoints.down("xs"));
  const profile = useUserProfile();

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = React.useCallback(async () => {
    await firebaseApp.auth().signOut();
    window.location = "/";
  });

  if (collapseNavigation) {
    return (
      <>
        {awaitData(profile, {
          loading: () => null,
          data: profile => {
            if (profile.serviceProvider == null) {
              return null;
            }

            return (
              <>
                <List
                  subheader={
                    <ListSubheader component="div">
                      Helper Account
                    </ListSubheader>
                  }
                >
                  <MenuItem component={SiteLink} href="/service/appointments">
                    Appointments
                  </MenuItem>
                  <MenuItem
                    component={SiteLink}
                    href="/service/profile-settings"
                  >
                    Profile Settings
                  </MenuItem>
                </List>
              </>
            );
          }
        })}
        <List
          subheader={<ListSubheader component="div">My Account</ListSubheader>}
        >
          <MenuItem component={SiteLink} href="/customer/settings">
            Account Settings
          </MenuItem>
          <MenuItem component={SiteLink} href="/customer/appointments">
            My Bookings
          </MenuItem>
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </List>
      </>
    );
  }

  return (
    <>
      {awaitData(profile, {
        loading: () => null,
        data: profile => {
          if (profile.serviceProvider == null) {
            return null;
          }

          return (
            <>
              <NavLink onClick={e => setBizAnchorEl(e.currentTarget)}>
                Helper Account
              </NavLink>
              <Menu
                id="simple-menu"
                anchorEl={bizAnchorEl}
                keepMounted
                open={Boolean(bizAnchorEl)}
                onClose={() => setBizAnchorEl(null)}
              >
                <MenuItem component={SiteLink} href="/service/appointments">
                  Your Appointments
                </MenuItem>
                <MenuItem component={SiteLink} href="/service/profile-settings">
                  Profile Settings
                </MenuItem>
              </Menu>
            </>
          );
        }
      })}
      <NavLink onClick={e => setAnchorEl(e.currentTarget)}>My Account</NavLink>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component={SiteLink} href="/customer/settings">
          Account Settings
        </MenuItem>
        <MenuItem component={SiteLink} href="/customer/appointments">
          My Bookings
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};
