import React, { useMemo, useState, useCallback } from "react";
import * as ReactDOM from "react-dom";
import {
  Stepper,
  Step,
  StepLabel,
  Dialog,
  Divider,
  Button,
  DialogActions,
  LinearProgress,
  Hidden
} from "@material-ui/core";
import styled from "styled-components";
import SelectBookingServices from "./SelectBookingServices";
import { CreateAppointment } from "./CreateAppointment";
import { SelectBookingLocation } from "./SelectBookingLocation";
import { NavigateBefore, NavigateNext } from "@material-ui/icons";
import { useBookingContext } from "@/hooks/booking";
import { motion, AnimatePresence } from "framer-motion";
import { AppointmentPayment } from "./AppointmentPayment";
import { BookingAccount } from "./BookingAccount";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { BookedSuccess } from "./BookedSuccess";
import { SiteDialog } from "@/components/SiteDialog";

const ButtonDivider = styled(Divider)`
  && {
    margin-top: auto;
  }
`;

const CancelButton = styled(Button)`
  && {
    margin-right: auto;
  }
`;

const allSteps = [
  {
    id: "chooseServices",
    label: "Options",
    component: SelectBookingServices
  },

  {
    id: "authenticate",
    label: "Account",
    component: BookingAccount
  },

  {
    id: "chooseLocation",
    label: "Location",
    component: SelectBookingLocation
  },

  {
    id: "createAppointment",
    label: "Appointment",
    component: CreateAppointment
  },

  {
    id: "confirmPayment",
    label: "Payment",
    component: AppointmentPayment
  }
];

const BookingDialog = styled(({ className, ...props }) => (
  <SiteDialog {...props} classes={{ paper: className }} />
))`
  && {
    display: flex;
    flex-direction: column;
    max-width: 100vw;
    align-items: stretch;
    width: 600px;
    overflow-x: hidden;
    min-height: 500px;
  }
`;

const Panel = styled(motion.div)`
  width: 600px;
  max-width: 100vw;
`;

const PanelsContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;

  > * {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
`;

const setRef = cb => {
  let current;
  return {
    set current(value) {
      if (current !== value) {
        cb(value);
        current = current;
      }
    },

    get current() {
      return current;
    }
  };
};

const transitionPanel = {
  enter: ({ direction }) => ({
    x: direction > 0 ? "100%" : "-100%"
  }),
  rest: { x: 0 },
  exit: ({ direction }) => ({ x: direction > 0 ? "-100%" : "100%" })
};

const BookAppointment = () => {
  const currentUser = useCurrentUser();
  const steps = useMemo(() => {
    return currentUser != null
      ? allSteps.filter(step => step.id !== "authenticate")
      : allSteps;
  }, []);
  const { closeBooking, booking } = useBookingContext();
  const [step, setStep] = useState(0);
  const [direction, setDirection] = useState(1);
  const { component: Component } = steps[step];
  const { provider } = booking;
  const [bookedSuccess, onSetDone] = useState(false);

  const onContinue = useCallback(() => {
    setDirection(1);
    setStep(Math.min(step + 1, steps.length - 1));
  }, [step]);

  const onSuccess = useCallback(() => {
    onSetDone(true);
  }, [step]);

  const onBack = useCallback(() => {
    setDirection(-1);
    setStep(Math.max(step - 1, 0));
  }, [step]);

  const [actionContainer, setActionContainer] = React.useState();
  const actionsRef = useMemo(() => setRef(setActionContainer), [step]);

  if (bookedSuccess) {
    return (
      <BookingDialog open={true}>
        <BookedSuccess />
        <ButtonDivider />
        <DialogActions>
          <Button onClick={closeBooking}>Close</Button>
        </DialogActions>
      </BookingDialog>
    );
  }

  return (
    <BookingDialog open={true}>
      <Hidden xsDown>
        <Stepper activeStep={step}>
          {steps.map(step => {
            return (
              <Step key={step.id} id={step.id}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Hidden>

      <Divider />

      <PanelsContainer>
        <AnimatePresence initial={false} custom={{ direction }}>
          <Panel
            custom={{ direction }}
            key={step}
            initial={"enter"}
            animate={"rest"}
            exit={"exit"}
            variants={transitionPanel}
          >
            <Component
              onNext={onContinue}
              onSuccess={onSuccess}
              buttons={({
                onNext = onContinue,
                loading,
                nextLabel = (
                  <>
                    Continue <NavigateNext />
                  </>
                ),
                disableNext
              }) =>
                actionContainer &&
                ReactDOM.createPortal(
                  <>
                    <DialogActions>
                      <CancelButton onClick={closeBooking}>Cancel</CancelButton>
                      {step !== 0 && (
                        <Button onClick={onBack}>
                          <NavigateBefore /> Back
                        </Button>
                      )}
                      <Button
                        variant="text"
                        color="primary"
                        onClick={onNext}
                        disabled={disableNext || loading}
                      >
                        {nextLabel}
                      </Button>
                    </DialogActions>
                    {loading && <LinearProgress />}
                  </>,
                  actionContainer
                )
              }
              provider={provider}
            />
          </Panel>
        </AnimatePresence>
      </PanelsContainer>

      <ButtonDivider />

      <div key={step} ref={actionsRef} />
    </BookingDialog>
  );
};

export default BookAppointment;
