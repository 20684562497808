import * as React from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

const GrowContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  justify-self: center;

  > * {
    margin: auto;
  }
`;

export const GrowLoading = () => {
  return (
    <GrowContainer>
      <CircularProgress color="secondary" />
    </GrowContainer>
  );
};
