import * as React from "react";
import Home from "@pages/index";
import { ViewServiceProvider } from "@/components/ViewServiceProvider";
import { useRouter } from "@/utils/routes";
import { useServiceProvider } from "@/hooks/useServiceProvider";
import { awaitData } from "@/hooks/awaitData";
import { RouteComponentProps } from "react-router-dom";

interface RouteProps {
  pid: string;
}

const Provider = ({ match }: RouteComponentProps<RouteProps>) => {
  const { pid } = match.params;

  const serviceProvider = useServiceProvider(pid);

  return (
    <Home
      dialog={awaitData(serviceProvider, {
        loading() {
          return null;
        },

        data(provider) {
          return <ViewServiceProvider provider={provider} />;
        }
      })}
    />
  );
};

export default Provider;
