import { useReducer } from "react";
import { awaitData, LOADING } from "./awaitData";
import { useProviderServices } from "./useProviderServices";
import immutable from "immutable";

export const useBookingLineItems = (providerId, servicesMap) => {
  const [updateKey, onUpdate] = useReducer(value => (value + 1) % 2, 0);
  const services = useProviderServices(providerId, updateKey);

  const data = awaitData(services, {
    loading() {
      return LOADING;
    },

    data(data) {
      return immutable.isMap(servicesMap)
        ? servicesMap.entrySeq().map(([key, value]) => {
            const service = data.find(service => service.id === key);

            return {
              id: key,
              name: service.name,
              quantity: value.quantity,
              cost: value.quantity * service.price.value
            };
          })
        : Array.from(Object.entries(servicesMap)).map(([key, value]) => {
            const service = data.find(service => service.id === key);

            return {
              id: key,
              name: service.name,
              quantity: value.quantity,
              cost: value.quantity * service.price.value
            };
          });
    }
  });

  return [data, onUpdate];
};
