import Details from "@/components/BookingDetails";
import { awaitData } from "@/hooks/awaitData";
import { useBookingLineItems } from "@/hooks/useBookingLineItems";
import { makeRule, useForm } from "@/hooks/useForm";
import {
  CardContent,
  Divider,
  LinearProgress,
  ListItemText,
  Typography
} from "@material-ui/core";
import Send from "@material-ui/icons/Send";
import React, { useState } from "react";
import styled from "styled-components";
import { formatName } from "../utils/formatName";
import { withProps } from "../utils/withProps";
import { ActionButton } from "./ActionButton";
import { CardChoice } from "./CardChoice";
import { ServicesTable } from "./ServicesTable";
import { TextFormField } from "./TextFormField";

const SendIcon = styled(Send)`
  && {
    margin-left: 8px;
    font-size: 18px;
  }
`;

const SendButton = styled(ActionButton).attrs({
  variant: "contained",
  color: "primary"
})`
  && {
    justify-self: center;
  }
`;

const SendActions = styled(CardContent)`
  && {
    padding-top: 24px;
    padding-bottom: 24px;
    display: flex;
    justify-content: center;
  }
`;

const cardChoices = [
  {
    id: "yes",
    value: true,
    label: (
      <ListItemText
        primary="Yes, I can meet with this client."
        secondary="Terrific, we'll let the client know their appointment is confirmed."
      />
    )
  },

  {
    id: "no",
    value: false,
    label: (
      <ListItemText
        primary="No, I can't make this appointment."
        secondary="We'll help you and the client find a better time and place."
      />
    )
  }
];

const MessageField = styled(TextFormField)`
  width: 100%;

  textarea {
    min-height: 3em;
  }
`;

const FeedbackContainer = styled(CardContent)`
  && {
    padding-bottom: 0;
  }
`;

const appointmentAcceptFeedback = {
  message: {
    field: withProps(MessageField, {
      variant: "outlined",
      autoFocus: true,
      multiline: true,
      label: "Anything you'd like share with the client?",
      rowsMax: 4
    })
  }
};

const appointmentFeedback = {
  message: {
    field: withProps(MessageField, {
      variant: "outlined",
      autoFocus: true,
      multiline: true,
      label: "What would you like to tell the client?",
      rowsMax: 4
    }),
    getErrors: makeRule(
      "Please provide a message to help the client reschedule.",
      message => message.length > 0
    )
  }
};

export const ConfirmAppointmentForm = ({
  booking,
  onDeny,
  onConfirm,
  loading
}) => {
  const [confirmed, onSetConfirmed] = useState(null);
  const [acceptFields, acceptFeedback] = useForm(appointmentAcceptFeedback, {
    message: ""
  });
  const [feedbackFields, feedback, feedbackErrors] = useForm(
    appointmentFeedback,
    { message: "" }
  );
  const [lineItems] = useBookingLineItems(
    booking.provider.id,
    booking.services
  );

  return (
    <>
      <CardContent>
        <Typography variant="h5" component="h4">
          Confirm appointment: {formatName(booking.client)}
        </Typography>
      </CardContent>
      {awaitData(lineItems, {
        loading() {
          return <LinearProgress />;
        },

        data() {
          return <ServicesTable lineItems={lineItems} />;
        }
      })}
      <CardContent>
        <Details booking={booking} provider={booking.provider} />
      </CardContent>

      <Divider />
      <CardChoice
        value={confirmed}
        onChange={onSetConfirmed}
        options={cardChoices}
      />
      <Divider />

      {confirmed === false && (
        <FeedbackContainer>{feedbackFields.message}</FeedbackContainer>
      )}

      {confirmed === true && (
        <FeedbackContainer>{acceptFields.message}</FeedbackContainer>
      )}

      {confirmed != null && (
        <SendActions>
          {confirmed === true && (
            <SendButton
              disabled={loading}
              onClick={() => onConfirm(acceptFeedback)}
            >
              Send confirmation <SendIcon />
            </SendButton>
          )}
          {confirmed === false && (
            <SendButton
              disabled={feedbackErrors.length > 0 || loading}
              onClick={() => onDeny(feedback)}
            >
              Send to client
              <SendIcon />
            </SendButton>
          )}
        </SendActions>
      )}
    </>
  );
};
