import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback
} from "react";
import { colors, Typography, Fab } from "@material-ui/core";
import styled, { css } from "styled-components";
import { NavigateNext, NavigateBefore } from "@material-ui/icons";
import {
  format,
  addDays,
  differenceInDays,
  getTime,
  startOfDay,
  subDays
} from "date-fns";
import { motion, AnimatePresence } from "framer-motion";
import { withProps } from "../utils/withProps";
import { getRelativeDayOfWeekLabel, getDayOfYearLabel } from "../utils/time";

const tileWidth = 120;

const DatePickerContainer = styled.div`
  background-color: ${colors.grey[200]};
  width: 100%;
  position: relative;
  overflow: hidden;
`;

const DatesContainer = styled(motion.div)`
  padding-left: 16px;
  position: relative;
  height: 72px;
`;

const selectedTile = css`
  border-color: ${colors.grey[700]};
  color: ${colors.grey[900]};
`;

const DateTile = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  cursor: pointer;
  user-select: none;
  text-align: center;
  padding: 16px;
  color: ${colors.grey[600]};
  border-bottom: 3px solid transparent;
  width: ${tileWidth}px;
  box-sizing: border-box;

  ${props => (props.active ? selectedTile : null)}
`;

const NavButton = styled(Fab)`
  && {
    width: 30px;
    height: 30px;
    min-height: 0;
    pointer-events: all;
  }
`;

const ButtonsContainer = styled.div`
  position: absolute;
  pointer-events: none;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0 8px;
`;

const DayLabel = styled(Typography)`
  && {
    white-space: nowrap;
    font-size: 16px;
  }
`;

const DateLabel = styled(Typography)`
  font-size: 14px;
  white-space: nowrap;
`;

const dates = Array.from(new Array(30));

/**
 * next page: set start element, onComplete: adjust
 * select element: set picked element, set start element, onComplete: adjust
 */

const msDay = 24 * 60 * 60 * 1000;

export const DatePicker = ({ date: pickedDate, today, onChange }) => {
  const [startDate, setDate] = useState(pickedDate);
  const containerRef = useRef();

  useEffect(() => {
    setDate(pickedDate);
  }, [pickedDate]);

  const [scrollPositon, setScrollPostion] = useState(0);

  const setElement = React.useCallback(startElement => {
    if (startElement) {
      const offset = startElement.offsetLeft;
      const targetWidth = startElement.getBoundingClientRect().width;
      const container = startElement.parentElement.parentElement.getBoundingClientRect()
        .width;
      const position = offset - container / 2 + targetWidth / 2;

      setScrollPostion(Math.max(-16, position));
    }
  }, []);

  const onNext = useCallback(() => {
    if (containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect();
      const advanceBy = Math.floor(width / tileWidth) - 1;
      console.log(advanceBy);
      const newDate = addDays(startDate, advanceBy);
      setDate(newDate);
    }
  }, [containerRef.current, startDate]);

  const onBack = useCallback(() => {
    if (containerRef.current) {
      const { width } = containerRef.current.getBoundingClientRect();
      const reverseBy = Math.floor(width / tileWidth) - 1;
      const newDate = Math.max(today, subDays(startDate, reverseBy));
      setDate(newDate);
    }
  }, [containerRef.current, startDate]);

  return (
    <DatePickerContainer ref={containerRef}>
      <DatesContainer animate={{ x: -scrollPositon }}>
        {dates.map((_, distance) => {
          const date = addDays(startDate, distance - 10);

          if (getTime(date) < today) {
            return null;
          }

          const distanceFromStart = Math.floor((getTime(date) - today) / msDay);
          const selected = getTime(date) === getTime(pickedDate);
          const startElement = getTime(date) === getTime(startDate);

          return (
            <DateTile
              style={{ left: distanceFromStart * tileWidth }}
              ref={startElement ? setElement : undefined}
              key={getTime(date)}
              active={selected}
              onClick={() => onChange(date)}
            >
              <DayLabel variant="h6">
                {getRelativeDayOfWeekLabel(date, today)}
              </DayLabel>
              <DateLabel variant="caption">{getDayOfYearLabel(date)}</DateLabel>
            </DateTile>
          );
        })}
      </DatesContainer>
      <ButtonsContainer>
        <AnimatePresence>
          {scrollPositon !== -16 && (
            <motion.div
              key="button"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
            >
              <NavButton
                key="back"
                size="small"
                color="primary"
                onClick={onBack}
              >
                <NavigateBefore />
              </NavButton>
            </motion.div>
          )}
        </AnimatePresence>

        <NavButton
          style={{ marginLeft: "auto" }}
          size="small"
          color="primary"
          onClick={onNext}
        >
          <NavigateNext />
        </NavButton>
      </ButtonsContainer>
    </DatePickerContainer>
  );
};
