import { BackButton } from "@/components/BackButton";
import { RequestConfirmForm } from "@/components/RequestConfirmForm";
import { SiteLink } from "@/components/SiteLink";
import { SuccessMessage } from "@/components/SuccessMessage";
import { useRequest } from "@/config/collections/requests";
import { awaitData } from "@/hooks/awaitData";
import { useAsyncCallback } from "@/hooks/useAsyncCallback";
import { useRequireUser } from "@/hooks/useCurrentUser";
import { ServicePortal } from "@/layouts/ServicePortal";
import { formatName } from "@/utils/formatName";
import { CardContent, CircularProgress, LinearProgress } from "@material-ui/core";
import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

const CenteredProgress = styled.div`
  height: 400px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ConfirmRequest = ({
  match: { params }
}: RouteComponentProps<{ rid: string }>) => {
  useRequireUser();

  const { rid } = params;
  const request = useRequest(rid);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(null);

  const onDeny = useAsyncCallback(({ message }) => { }, []);
  const onAccept = useAsyncCallback(({ message }) => { }, []);

  return (
    <ServicePortal
      header={
        <>
          <CardContent>
            <BackButton component={SiteLink} href={"/service/requests"}>
              All Requests
            </BackButton>
          </CardContent>
        </>
      }
    >
      {awaitData(request, {
        loading: () => {
          return (
            <CenteredProgress>
              <CircularProgress />
            </CenteredProgress>
          );
        },

        data: request => {
          if (request.status !== "payment-confirmed") {
            //return <Redirect to={`/service-provider/request/${request.id}`} />;
          }

          if (success === "accepted") {
            return (
              <CardContent>
                <SuccessMessage
                  title={`Done. We've let ${formatName(
                    request.client
                  )} know their appointment is confirmed.`}
                />
              </CardContent>
            );
          }

          if (success === "denied") {
            return (
              <CardContent>
                <SuccessMessage
                  title={`Done. We've sent your reply.`}
                  message={`${formatName(
                    request.client
                  )} can contact you directly at your customer reply email (${
                    request.provider.customerReply
                    }) to respond.`}
                />
              </CardContent>
            );
          }

          return (
            <RequestConfirmForm
              loading={loading}
              request={request}
              onDeny={onDeny}
              onConfirm={onAccept}
            />
          );
        }
      })}
      {loading && <LinearProgress />}
    </ServicePortal>
  );
};

export default ConfirmRequest;
