import React from "react";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from "@material-ui/core";

export const ServicesTable = ({ lineItems }) => {
  const totalCost = lineItems.reduce((acc, item) => acc + item.cost, 0);

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Skills</TableCell>
          <TableCell align="right">Quantity</TableCell>
          <TableCell align="right">Cost</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {lineItems.map(({ id, name, quantity, cost }) => (
          <TableRow key={id}>
            <TableCell>{name}</TableCell>
            <TableCell align="right">{quantity}</TableCell>
            <TableCell align="right">${cost.toFixed(2)}</TableCell>
          </TableRow>
        ))}
        {lineItems.length > 1 ? (
          <TableRow>
            <TableCell>
              <strong>Total</strong>
            </TableCell>
            <TableCell align="right" />
            <TableCell align="right">
              <strong>${totalCost.toFixed(2)}</strong>
            </TableCell>
          </TableRow>
        ) : null}
      </TableBody>
    </Table>
  );
};
