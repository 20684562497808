import { useSelector } from "@/config/draftCollection";
import { Load } from "@/hooks/awaitData";
import Request from "./request";

interface Address { }

export interface Request {
  key: string;
  id: string;
  address: Address;
  skills: string[];
  helpers: number[];
  userId: string;
  create: { seconds: number };
}

export const useRequest = (requestId: Load<string>): Load<Request> => {
  return useSelector(
    (store, id: string) => store.getRemote("requests", `booking-requests/${id}`),
    requestId
  );
};

export const useRequests = (providerId: Load<string>) => {
  return useSelector(
    (store, id: string) =>
      store.getQuery<Request>(store => {
        return store
          .collection("booking-requests")
          .where("helpers", "array-contains", id)
          .limit(500);
      }),
    providerId
  );
};
