import * as React from "react";
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
  Button,
  Grid
} from "@material-ui/core";
import { SiteDialog } from "@/components/SiteDialog";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { useAsyncCallback } from "@/hooks/useAsyncCallback";

export const DeleteAccount = ({ onClose }) => {
  const user = useCurrentUser();

  const deleteAccount = useAsyncCallback(async () => {
    // TODO clear out more data
    await user.delete();

    window.location = "/";

    onClose();
  }, [user]);

  return (
    <SiteDialog onClose={onClose}>
      <DialogTitle>Are you sure you want delete your account?</DialogTitle>
      <DialogContent>
        <Grid container direction="column" alignItems="stretch">
          <DialogContentText>
            We're sorry to see you go. We'd love to know the reason you're
            interested in deleting your account on Hallò.
          </DialogContentText>

          <TextField
            multiline
            variant="outlined"
            rows={2}
            label="Why are you deleting your account?"
          />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Nevermind</Button>
        <Button color="primary" onClick={deleteAccount}>
          Delete account
        </Button>
      </DialogActions>
    </SiteDialog>
  );
};
