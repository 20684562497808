import * as React from "react";
import { useState, useEffect, createContext, useContext } from "react";
import { auth } from "../config/firebase";
import { LOADING, awaitData } from "./awaitData";
import { useRouter } from "@/utils/routes";

const UserContext = createContext();

export const ProvideFirebaseUser = ({ children }) => {
  const [user, setUser] = useState(LOADING);

  useEffect(() => {
    const sub = auth().onAuthStateChanged(value => setUser(auth().currentUser));

    return sub;
  });

  useEffect(() => {
    setUser(user);
  }, [auth().currentUser]);

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>;
};

export const useUserData = () => {
  return useContext(UserContext);
};

export const useRequireUser = (redirect, createAccount = false) => {
  const data = useUserData();
  const router = useRouter();

  useEffect(() => {
    if (data == null) {
      if (window.location.pathname.startsWith("/auth")) {
        return;
      }

      router.history.push(
        `/auth/${createAccount ? "join" : "sign-in"}?r=${redirect ||
          encodeURIComponent(
            window.location.pathname + window.location.search
          )}`
      );
    }
  }, [data]);

  return awaitData(data, {
    loading() {
      return LOADING;
    },

    data(data) {
      if (data == null) {
        return LOADING;
      }

      return data;
    }
  });
};

export const useCurrentUser = () => {
  const data = useUserData();

  return awaitData(data, {
    loading() {
      return null;
    },

    data(data) {
      return data;
    }
  });
};
