import { __RouterContext as RouterContext } from "react-router";
import * as React from "react";
import { join as pathJoin } from "path";

export function useRouter() {
  return React.useContext(RouterContext);
}

export const join = (base: string, ...segments: string[]) => {
  return pathJoin(base, ...segments);
};
