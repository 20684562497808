import { PropsOf } from "@/utils/types";
import { TextField } from "@material-ui/core";
import React from "react";
import styled, { css } from "styled-components";
import { makeFormField } from "../utils/makeFormField";

const normalMargin = css`
  && {
    margin-bottom: ${(props: PropsOf<typeof TextField>) =>
      props.helperText ? "0" : "20px"};
    margin-top: 8px;
  }
`;

const Field = styled(TextField)`
  ${props => props.margin === "normal" && normalMargin}
`;

export const TextFormField = makeFormField<string, PropsOf<typeof TextField>>(
  ({ onSetPristine, margin = "dense", errors, isError, ...props }) => {
    return (
      <Field
        error={isError}
        onBlur={() => onSetPristine(false)}
        helperText={isError && errors[0]}
        margin={margin}
        {...props}
      />
    );
  }
);
