import {
  Card,
  Divider,
  Typography,
  CardActions,
  Stepper,
  CardContent
} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import { motion } from "framer-motion";
import styled from "styled-components";
import theme from "@/config/theme";

export const CardTitle = styled(CardContent)`
  && {
    padding-bottom: 0;
  }
`;

export const MobileStepContainer = styled.div`
  && {
    background-color: transparent;
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 1;
    align-items: stretch;
  }
`;

export const SetupStepper = styled(Stepper)`
  && {
    background-color: transparent;
  }
`;

export const SetupCard = styled(Card)`
  align-self: stretch;
  margin-top: 8px;
  width: 600px;
`;

export const SetupContainer = styled.div`
  margin: 32px auto;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${theme.breakpoints.down("xs")} {
    margin: 0 auto;
    align-items: stretch;
    width: 100vw;
    height: 100vh;
    height: -webkit-fill-available;
    height: fill-available;
  }
`;

export const PageContainer = styled.div`
  display: flex;
`;

export const NextButton = styled(Button)`
  && {
    margin-left: 16px;
    margin-right: 16px;
  }
`;

export const ButtonsContainer = styled(CardActions)`
  display: flex;
  align-items: center;
  margin-top: auto;

  ${theme.breakpoints.down("xs")} {
    justify-content: flex-end;
  }
`;

export const ButtonDivider = styled(Divider)`
  && {
    margin-top: auto;
  }
`;

export const Panel = styled(motion.div)`
  width: 600px;
  max-width: 100vw;
`;

export const PanelsContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;

  > * {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
  }
`;

export const StepHeader = styled(Typography).attrs({
  variant: "h6",
  gutterBottom: true
})`
  && {
    font-weight: 400;
  }
`;
