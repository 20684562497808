import * as React from "react";
import { MenuItem, Menu, Button } from "@material-ui/core";
import styled from "styled-components";
import AddToCalendarHoc from "react-add-to-calendar-hoc";
import { useState } from "react";

const LinkItem = styled(MenuItem)`
  && {
    min-width: 150px;
    text-align: center;
  }
`;

const LinkMenu = ({ children, onRequestClose, ...props }) => {
  return (
    <Menu onClose={onRequestClose} {...props}>
      {children.map((child, index) =>
        React.createElement(LinkItem, {
          key: index,
          component: "a",
          ...child.props
        })
      )}
    </Menu>
  );
};

const AddToCalendar = AddToCalendarHoc(Button, LinkMenu);

export const AddToCalendarButton = ({ event }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  return (
    <AddToCalendar
      buttonProps={{
        onMouseDown: e => setAnchorEl(e.currentTarget)
      }}
      dropdownProps={{
        anchorEl,
        open: !!anchorEl
      }}
      event={event}
    >
      Add to calendar
    </AddToCalendar>
  );
};
