import * as React from "react";
import { CustomerPortal } from "@/layouts/CustomerPortal";
import { CardChoice } from "@/components/CardChoice";
import {
  ListItemText,
  Paper,
  Button,
  CardContent,
  CardActions,
  Link,
  Divider
} from "@material-ui/core";
import { useRequireUser } from "@/hooks/useCurrentUser";
import { useUserProfile } from "@/hooks/useUserProfile";
import { awaitData } from "@/hooks/awaitData";
import { GrowLoading } from "@/components/GrowLoading";
import { Typography } from "@material-ui/core";
import { useState } from "react";
import { ResetPassword } from "@/components/ResetPassword";
import { DeleteAccount } from "@/components/DeleteAccount";
import { JoinReferralProgram } from "@/components/JoinReferralProgram";
import { ChangeEmail } from "@/components/ChangeEmail";
import styled from "styled-components";
import copy from "copy-to-clipboard";
import { useSnackbar } from "notistack";

const Copy = () => {
  return (
    <svg
      style={{ marginRight: "4px", width: "20px", color: "inherit" }}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        style={{ fill: "currentColor" }}
        d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z"
      />
    </svg>
  );
};

const LinkContent = styled.span`
  padding: 12px;
`;

const ReferralField = styled(Paper)`
  && {
    background-color: rgba(0, 0, 0, 0.05);
    font-size: 16px;
    display: flex;
    align-items: stretch;
  }
`;

const settingsOptions = user => [
  {
    id: "email",
    value: "email",
    label: <ListItemText primary="Email address" secondary={user.email} />
  },

  {
    id: "password",
    value: "password",
    label: <ListItemText primary="Reset Password" />
  },

  {
    id: "delete",
    value: "delete",
    label: <ListItemText primary="Delete your account" />
  }
];

const Settings = () => {
  useRequireUser();
  const snackBar = useSnackbar();
  const userProfile = useUserProfile();
  const [Dialog, setDialog] = useState();

  return (
    <CustomerPortal
      header={
        <CardContent>
          <Typography variant="h6">Account settings</Typography>
        </CardContent>
      }
    >
      {Dialog && <Dialog onClose={() => setDialog(null)} />}
      {awaitData(userProfile, {
        loading() {
          return <GrowLoading />;
        },

        data(profile) {
          return (
            <>
              <Divider />
              <CardChoice
                onChange={value => {
                  if (value === "password") {
                    setDialog(() => ResetPassword);
                  }

                  if (value === "delete") {
                    return setDialog(() => DeleteAccount);
                  }

                  if (value === "email") {
                    return setDialog(() => ChangeEmail);
                  }
                }}
                options={settingsOptions(profile)}
              />
              <Divider />
              <CardContent style={{ paddingBottom: "0", marginTop: "8px" }}>
                <Typography
                  variant="subtitle1"
                  style={{ marginBottom: "12px" }}
                >
                  Helper Referral Program
                </Typography>
                <Typography variant="body2">
                  Know some awesome helpers? You & your helper could earn up to
                  $500 dollars when they become a Hallo Helper with your
                  referral link.
                </Typography>
              </CardContent>
              {userProfile.referralCode ? (
                <CardContent>
                  <ReferralField>
                    <LinkContent>
                      https://gethallo.com/r/{userProfile.referralCode}
                    </LinkContent>
                    <Button
                      style={{
                        marginLeft: "auto",
                        padding: "0 12px",
                        borderTopLeftRadius: "0",
                        borderBottomLeftRadius: "0"
                      }}
                      size="small"
                      onClick={() => {
                        copy(
                          `https://gethallo.com/r/${userProfile.referralCode}`
                        );
                        snackBar.enqueueSnackbar("Copied to your clipboard!");
                      }}
                    >
                      <Copy /> Copy Link
                    </Button>
                  </ReferralField>

                  <Typography
                    style={{ marginBottom: "8px", marginTop: "16px" }}
                    variant="body2"
                  >
                    View the referral program{" "}
                    <Link
                      href="https://hallò.com/referraltermsandconditions"
                      target="_blank"
                    >
                      terms & conditions
                    </Link>
                  </Typography>
                </CardContent>
              ) : (
                <CardActions>
                  <Button
                    color="primary"
                    size="mall"
                    onClick={() => setDialog(() => JoinReferralProgram)}
                  >
                    Get your link
                  </Button>
                </CardActions>
              )}
            </>
          );
        }
      })}
    </CustomerPortal>
  );
};

export default Settings;
