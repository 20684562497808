import * as React from "react";
import { Switch, Route } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import Profile from "@pages/customer/profile";
import Settings from "@pages/customer/settings";
import Appointments from "@pages/customer/appointments";
import { join } from "@/utils/routes";

const Customer = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route path={join(match.url, "profile")} component={Profile} />
      <Route path={join(match.url, "settings")} component={Settings} />
      <Route path={join(match.url, "appointments")} component={Appointments} />
    </Switch>
  );
};

export default Customer;
