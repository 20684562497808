import theme from "@/config/theme";
import { getCoverCrop, resizeThumbnail } from "@/utils/resizeImage";
import { AccountCircle, CloudUpload } from "@material-ui/icons";
import * as React from "react";
import { useRef, useState, useEffect } from "react";
import styled from "styled-components";
import { Tooltip } from "@material-ui/core";

interface Props {
  value: string | null;
  onChange: (value: string) => void;
  errors?: string[];
  showErrors?: boolean;
}

const UploadButton = styled.label`
  height: 100%;
  width: 100%;
  border-radius: 50px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 30px;

  input {
    display: none;
  }

  > svg {
    font-size: inherit;
    color: #fff;
    margin: auto;
  }
`;

const PreviewImage = styled.img`
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50px;
  left: 0;
`;

const Avatar = styled.div`
  background-color: #fff;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  margin: 0 auto;
  position: relative;
  cursor: pointer;

  > svg {
    width: 100%;
    height: 100%;
    color: ${theme.palette.primary.main};
  }

  &:hover {
    ${UploadButton} {
      display: flex;
    }
  }
`;

export const UploadProfileImage = ({
  value,
  onChange,
  errors,
  showErrors
}: Props) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const [newFile, setFile] = useState<string | null>();
  const fileUrl = newFile || value;

  useEffect(() => {
    if (newFile != null) {
      const cropped = resizeThumbnail(
        imageRef.current,
        getCoverCrop(imageRef.current, { width: 100, height: 100 })
      );

      onChange(cropped);
    }

    setFile(null);
  }, [newFile]);

  return (
    <Tooltip
      placement="right"
      title={errors && errors.length > 0 ? errors[0] : ""}
      open={showErrors}
    >
      <Avatar>
        {fileUrl == null ? (
          <AccountCircle viewBox="2 2 20 20" />
        ) : (
          <PreviewImage ref={imageRef} src={fileUrl} />
        )}
        <UploadButton>
          <CloudUpload />
          <input
            type="file"
            accept="image/*"
            onChange={event => {
              if (event.target.files && event.target.files.length) {
                const [file] = event.target.files;
                const reader = new FileReader();

                reader.addEventListener(
                  "load",
                  () => setFile(reader.result as string),
                  false
                );

                reader.readAsDataURL(file);
              }
            }}
          />
        </UploadButton>
      </Avatar>
    </Tooltip>
  );
};
