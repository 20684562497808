import { awaitData } from "@/hooks/awaitData";
import { UserProfile } from "@/hooks/useUserProfile";
import { LinearProgress, Typography } from "@material-ui/core";
import * as React from "react";
import { AvatarImage } from "../components/AvatarImage";
import { formatName } from "../utils/formatName";
import styled from "styled-components";
import { SiteLink } from "@/components/SiteLink";

interface AccountProps {
  profile: UserProfile;
  href: string;
}

const AccountContainer = styled(SiteLink)`
  display: flex;
  align-items: center;
  padding: 16px;
  color: inherit;
  text-decoration: none;
`;

export const MyAccount = ({ profile, href }: AccountProps) => {
  return awaitData(profile, {
    loading() {
      return <LinearProgress />;
    },

    data(profile) {
      return (
        <AccountContainer href={href}>
          <AvatarImage src={profile.profileImage} />
          <Typography variant="caption">{formatName(profile)}</Typography>
        </AccountContainer>
      );
    }
  });
};
