import styled from "styled-components";

export const NavLink = styled.a`
  text-transform: uppercase;
  color: inherit;
  text-decoration: none;
  font-weight: 700;
  &:hover {
    cursor: pointer;
  }
`;
