import { AddressForm } from "@/components/AddressForm";
import { makeRule, useForm } from "@/hooks/useForm";
import { withProps } from "@/utils/withProps";
import * as React from "react";
import { useCallback } from "react";
import styled from "styled-components";
import { NumberFormatField } from "../NumberFormatField";
import { StepProps } from "./steps";
import { StepHeader } from "./styles";
import { useMutation } from "@/config/draftCollection";
import {
  providerCollection,
  useSaveProvider,
  useSaveContactInformation
} from "@/config/collections/provider";
import { CardContent } from "@material-ui/core";

const validPhone = /^(\([0-9]{3}\) [0-9]{3}-[0-9]{4})|[0-9]{10}$/;
const FullWidth = styled(NumberFormatField)`
  width: 100%;
`;

const formFields = {
  phoneNumber: {
    getErrors: makeRule("Please enter a valid phone number", (phone: string) =>
      validPhone.test(phone)
    ),
    field: withProps(FullWidth, {
      label: "Phone Number",
      variant: "outlined",
      mask: "(###) ###-####"
    })
  }
};

export const ContactInformation = ({
  buttons,
  provider,
  onNext
}: StepProps) => {
  const saveContactInfo = useSaveContactInformation();
  const [fields, value, errors, { setShowErrors }] = useForm(formFields, {
    phoneNumber: String(provider.contactNumber)
  });
  const [address, setAddress] = React.useState(provider.businessAddress);
  const [addressValid, setAddressValid] = React.useState();
  const [showErrors, setShowAddressErrors] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const handleNext = useCallback(() => {
    if (errors.length > 0 || !addressValid) {
      setShowAddressErrors(true);
      setShowErrors(true);
    } else {
      setLoading(true);

      saveContactInfo(
        provider.key,
        parseInt(value.phoneNumber.replace(/[^\d]/g, "")),
        address!
      ).then(() => {
        setLoading(false);
        onNext();
      });
    }
  }, [errors, value, address, addressValid]);

  return (
    <>
      <CardContent>
        {fields.phoneNumber}

        <AddressForm
          value={address}
          showErrors={showErrors}
          onChange={setAddress}
          onSetValid={setAddressValid}
        />
      </CardContent>

      {buttons({ onNext: handleNext, loading })}
    </>
  );
};
