import { Button, Typography } from "@material-ui/core";
import * as React from "react";
import styled from "styled-components";
import { TextFormField } from "./TextFormField";
import { firebaseApp } from "../config/firebase";
import { makeRule, rules, useForm } from "@/hooks/useForm";
import { withProps } from "../utils/withProps";
import { ActionButton } from "./ActionButton";

const variant = "outlined";
const margin = "normal";
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const authMessages = {
  "auth/email-already-in-use": (
    <>An account with this email is already registered. </>
  ),
  "auth/invalid-email": "Your email doesn't appear to be valid."
};

const loginForm = {
  email: {
    field: withProps(TextFormField, { label: "Email", variant, margin }),
    getErrors: makeRule("Please enter a valid email", email =>
      emailRegex.test(email)
    )
  },

  password: {
    field: withProps(TextFormField, {
      label: "Password",
      type: "password",
      variant,
      margin
    }),
    getErrors: rules(
      makeRule(
        "Password length should be at least 8 characters.",
        password => password.length >= 8
      )
    )
  },

  confirmPassword: {
    field: withProps(TextFormField, {
      label: "Confirm Password",
      type: "password",
      variant,
      margin
    }),
    getErrors: rules(
      makeRule(
        "Make sure your passwords match.",
        (confirm, form) => confirm === form.password
      )
    )
  }
};

const JoinButton = styled(ActionButton)`
  && {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

const initialState = {
  email: "",
  password: "",
  confirmPassword: ""
};

const Join = ({ onSuccess }) => {
  const [loading, setLoading] = React.useState(false);
  const [errorCode, setErrorCode] = React.useState();
  const [fields, details, errors, { setShowErrors }] = useForm(
    loginForm,
    initialState
  );

  const createUser = React.useCallback(() => {
    if (errors.length === 0) {
      setLoading(true);
      firebaseApp
        .auth()
        .createUserWithEmailAndPassword(details.email, details.password)
        .finally(() => setLoading(false))
        .then(onSuccess)
        .catch(e => {
          setErrorCode(e.code);
        });
    } else {
      setShowErrors(true);
    }
  }, [details, errors, setShowErrors]);

  return (
    <>
      {errorCode && (
        <Typography variant="subtitle2" color="error">
          {authMessages[errorCode] || "We're sorry, an unknown error occured."}
        </Typography>
      )}

      {fields.email}
      {fields.password}
      {fields.confirmPassword}

      <JoinButton
        loading={loading}
        onClick={createUser}
        color="primary"
        variant="contained"
        size="large"
      >
        Join
      </JoinButton>
    </>
  );
};

export default Join;
