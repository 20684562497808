import { SiteDialog } from "@/components/SiteDialog";
import {
  Button,
  LinearProgress,
  DialogActions,
  DialogTitle,
  DialogContent,
  Link,
  Typography
} from "@material-ui/core";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { generateReferralCode } from "@/config/firebase";
import { useAsyncCallback } from "@/hooks/useAsyncCallback";

interface Props {
  onClose: () => void;
}

const TermList = styled.ul`
  padding-left: 16px;
`;
const TermItem = styled(Typography).attrs({
  component: "li",
  variant: "body1"
})`
  padding-bottom: 8px;
`;

export const JoinReferralProgram = ({ onClose }: Props) => {
  const [loading, setLoading] = useState(false);

  const joinProgram = useAsyncCallback(async () => {
    setLoading(true);
    await generateReferralCode();
    setLoading(false);
    onClose();
  }, []);

  return (
    <SiteDialog open={true}>
      <DialogTitle>Join the Hallò Helper Referral Program</DialogTitle>
      <DialogContent style={{ paddingBottom: "0" }}>
        <Typography variant="body1">
          You and any new helper you refer will receive a referral bonus of 4%
          of revenues generated by the new hallò helper up to $500. The earning
          period is the first 3 months.
        </Typography>

        <Typography
          style={{ fontWeight: "600", marginTop: "16px" }}
          variant="subtitle1"
        >
          Affiliate Program Terms
        </Typography>
        <TermList>
          <TermItem>
            The bonus will be capped at $500, and will be paid after the third
            month the earning period.
          </TermItem>
          <TermItem>
            The earning period will start when the helper goes live on hallò,
            and end 3 months after that date.
          </TermItem>
          <TermItem>
            The helper must have a rating of at least 4-stars at the end of the
            earning period.
          </TermItem>
          <TermItem>
            The new hallò helper must stay on the platform, providing help for
            at least 3 months.
          </TermItem>
          <TermItem>
            You may referer multiple helpers to earn more, the bonus is one time
            per hallò helper. If a helper quits and rejoins platform there will
            not be a second bonus period.
          </TermItem>
          <TermItem>
            Hallò reserves the right to terminate a referrer's affiliate link if
            abuse of the bonus structure is discovered, as well as the right to
            change or discontinue the affiliate program at any time
          </TermItem>
        </TermList>

        <Typography style={{ marginBottom: "8px" }} variant="body1">
          View the full hallò helper referral program{" "}
          <Link
            href="https://hallò.com/referraltermsandconditions"
            target="_blank"
          >
            terms & conditions here
          </Link>
        </Typography>
      </DialogContent>
      <DialogActions style={{ justifyContent: "start", marginLeft: "8px" }}>
        <Button
          color="primary"
          disabled={loading}
          onClick={() => joinProgram()}
        >
          Agree & Join
        </Button>
        <Button>Nevermind</Button>
      </DialogActions>
      {loading && <LinearProgress />}
    </SiteDialog>
  );
};
