import {
  getTime,
  differenceInCalendarDays,
  differenceInCalendarYears,
  format
} from "date-fns";

const msDay = 24 * 60 * 60 * 1000;

export const startOfUTCDay = date => {
  const time = getTime(date);
  return time - (time % msDay);
};

export const getRelativeDayOfWeekLabel = (date, today) => {
  const fromToday = differenceInCalendarDays(date, today);

  if (fromToday === 0) {
    return "Today";
  }

  if (fromToday === 1) {
    return "Tomorrow";
  }

  return format(date, "dddd");
};

export const getDayOfYearLabel = date => {
  return format(date, "MMMM do");
};

export const getRelativeDayLabel = (date, today) => {
  if (date === "ASAP") {
    return "ASAP";
  }

  const fromToday = differenceInCalendarDays(date, today);

  if (fromToday === 0) {
    return "Today";
  }

  if (fromToday === 1) {
    return "Tomorrow";
  }

  if (fromToday < 7 && fromToday > 0) {
    return format(date, "EEEE");
  }

  const years = differenceInCalendarYears(date, today);

  if (years === 0) {
    return getDayOfYearLabel(date);
  }

  return format(date, "MMMM do, yyyy");
};
