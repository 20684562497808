import {
  Button,
  CardContent,
  LinearProgress,
  Typography
} from "@material-ui/core";
import { CalendarToday } from "@material-ui/icons";
import { format, getTime, startOfDay } from "date-fns";
import { Set } from "immutable";
import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { useBookingContext } from "@/hooks/booking";
import { useProviderResources } from "@/hooks/useProviderResources";
import { getRelativeDayOfWeekLabel, startOfUTCDay } from "../utils/time";
import { DatePicker } from "./DatePicker";
import { TimeSlider } from "./TimeSlider";

const CalendarIcon = styled(CalendarToday)`
  margin-right: 8px;
`;

const CalendarButton = styled(Button)`
  && {
    margin-left: auto;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
`;

const Instructions = styled(Typography)`
  && {
    max-width: 350px;
    display: inline-block;
  }
`;

const TimeSliderContainer = styled.div`
  padding: 32px 0;
`;

const getAvailability = (allResources, services) => {
  const servicesList = Set(services.keySeq());

  const resources = allResources.filter(resource =>
    servicesList.isSubset(resource.servicesProvided)
  );

  if (resources.size === 0) {
    return null;
  }

  const timeOpen = resources.reduce(
    (acc, { timeOpen }) => Math.min(acc, timeOpen),
    Infinity
  );

  const timeClose = resources.reduce(
    (acc, { timeClose }) => Math.max(acc, timeClose),
    -Infinity
  );

  return { timeOpen, timeClose };
};

export const CreateAppointment = ({ buttons, onNext }) => {
  const {
    booking: { provider, appointmentStart, services },
    setAppointmentStart
  } = useBookingContext();
  const today = useMemo(() => startOfDay(Date.now()), []);
  const resources = useProviderResources(provider.id);
  const availability = useMemo(() => {
    return Array.isArray(resources)
      ? getAvailability(resources, services)
      : null;
  }, [resources, services]);

  const startDate = appointmentStart || null;
  const [appointmentDay, setAppointmentDay] = useState(
    startDate != null ? startOfDay(startDate) : startOfDay(Date.now())
  );
  const [appointmentTimeState, setAppointmentTime] = useState(
    startDate != null ? startDate - getTime(startOfDay(startDate)) : null
  );

  if (availability == null) {
    return <LinearProgress />;
  }

  const appointmentTime =
    appointmentTimeState == null
      ? availability.timeOpen + startOfUTCDay(appointmentDay)
      : getTime(appointmentDay) + appointmentTimeState;

  return (
    <>
      <DatePicker
        today={today}
        date={appointmentDay}
        onChange={setAppointmentDay}
      />

      <div>
        <CardContent>
          <HeaderContainer>
            <Typography variant="h6">
              Availability for{" "}
              {getRelativeDayOfWeekLabel(appointmentDay, today)},{" "}
              {format(appointmentDay, "MMM Do")}
            </Typography>
          </HeaderContainer>
          <Instructions variant="caption" gutterBottom>
            Drag the blue square to select your time of delivery.
          </Instructions>

          <TimeSliderContainer>
            <TimeSlider
              startRange={availability.timeOpen + startOfUTCDay(appointmentDay)}
              endRange={availability.timeClose + startOfUTCDay(appointmentDay)}
              appointmentStart={appointmentTime}
              appointmentLength={1 * (60 * 60 * 1000)}
              onChangeStart={time =>
                setAppointmentTime(time - getTime(appointmentDay))
              }
            />
          </TimeSliderContainer>
        </CardContent>
      </div>

      {buttons({
        onNext() {
          setAppointmentStart(appointmentTime);
          onNext();
        }
      })}
    </>
  );
};
