import * as React from "react";
import {
  Dialog,
  List,
  ListItem,
  ListItemText,
  LinearProgress,
  CardContent,
  CardActions,
  Button,
  Divider
} from "@material-ui/core";
import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { useProviderServices } from "@/hooks/useProviderServices";
import { awaitData } from "@/hooks/awaitData";
import { ScrollList } from "./ScrollList";
import { SiteLink } from "./SiteLink";
import { SiteDialog } from "@/components/SiteDialog";
import theme from "@/config/theme";

const Container = styled.div`
  max-width: 100vw;
  max-height: 700px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  @media (max-height: 796px) {
    max-height: calc(100vh - 96px);
  }

  @media (max-width: ${theme.breakpoints.width("sm")}px) {
    max-height: 100vh;
    height: 100%;
  }
`;

const FeatureImage = styled.img`
  width: 100%;
  height: auto;
  flex-grow: 0;
  flex-shrink: 0;
`;

const HeaderContainer = styled(CardContent)`
  && {
    padding-bottom: 0;
    padding-top: 8px;
  }
`;

const ServicesList = styled(List)`
  && {
    padding: 0;
  }
`;

const ServiceNameLine = styled.div`
  display: flex;
`;

export const ViewServiceProvider = ({ provider }) => {
  const services = useProviderServices(provider.id);

  return (
    <SiteDialog open={true} scroll="body">
      <Container>
        <ScrollList>
          <div>
            <FeatureImage src={provider.featureImages[0]} />
          </div>
          <CardContent>
            <Typography variant="h5">{provider.name}</Typography>
            <Typography variant="p">{provider.serviceDescription}</Typography>
          </CardContent>
          <Divider />
          <HeaderContainer>
            <Typography component="h6" variant="h6">
              Skills
            </Typography>
          </HeaderContainer>
          {awaitData(services, {
            loading() {
              return <LinearProgress />;
            },

            data(data) {
              return (
                <ServicesList>
                  {services.map(service => {
                    return (
                      <ListItem key={service.id}>
                        <ListItemText
                          primary={
                            <ServiceNameLine>
                              <div style={{ marginRight: "auto" }}>
                                {service.name}
                              </div>
                              <Typography color="inherit">
                                ${parseFloat(service.price.value).toFixed(2)}
                              </Typography>
                            </ServiceNameLine>
                          }
                          secondary={
                            <Typography
                              align="justify"
                              variant="body2"
                              color="textSecondary"
                            >
                              {service.description}
                            </Typography>
                          }
                        />
                      </ListItem>
                    );
                  })}
                </ServicesList>
              );
            }
          })}
        </ScrollList>
        <Divider />
        <CardActions>
          <Button component={SiteLink} href="/">
            Close
          </Button>
          <Button
            style={{ marginLeft: "auto" }}
            color="primary"
            component={SiteLink}
            href={`/book-appointment/${provider.id}`}
          >
            Book Appointment
          </Button>
        </CardActions>
      </Container>
    </SiteDialog>
  );
};
