import { AccountCircle, EventAvailable, Settings } from "@material-ui/icons";
import * as React from "react";
import { CardPage } from "./CardPage";
import { PropsOf } from "@/utils/types";

type Props = Omit<PropsOf<typeof CardPage>, "tabs" | "accountHref">;

const tabs = [
  {
    label: "Appointments",
    pathname: "/customer/appointments",
    icon: <EventAvailable />
  },
  {
    label: "Profile",
    pathname: "/customer/profile",
    icon: <AccountCircle />
  },

  {
    label: "Settings",
    pathname: "/customer/settings",
    icon: <Settings />
  }
];

export const CustomerPortal = (props: Props) => {
  return (
    <CardPage
      accountHref={"/customer/settings"}
      title="Your Account"
      tabs={tabs}
      {...props}
    />
  );
};
