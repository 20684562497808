import * as React from "react";
import { useCallback } from "react";
import { StepProps } from "./steps";
import { CardContent, Typography, Checkbox } from "@material-ui/core";
import { StepHeader } from "./styles";
import styled from "styled-components";
import { props, t } from "@/utils/types";
import theme from "@/config/theme";
import { Set } from "immutable";
import { useSaveServiceAreas } from "@/config/collections/provider";

const AreaContainer = styled(props("label", { selected: t<boolean>() }))`
  border: 1px solid
    ${props =>
      props.selected ? theme.palette.primary.main : theme.palette.grey[300]};
  color: ${props =>
    props.selected ? theme.palette.primary.main : theme.palette.text.primary};
  padding-left: 12px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const AreasContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 8px;
`;

const locations = [
  "Bayamon",
  "Carolina",
  "Condado",
  "Dorado",
  "Fort Buchanon",
  "Guaynabo",
  "Hato Rey",
  "Isla Verde",
  "Miramar",
  "Old San Juan",
  "Ocean Park",
  "Santurce",
  "Sagrado Corazon"
];

export const ProviderLocation = ({ buttons, provider, onNext }: StepProps) => {
  const [areas, setAreas] = React.useState(
    Set<string>(provider.serviceAreas || [])
  );
  const [loading, setLoading] = React.useState(false);
  const saveAreas = useSaveServiceAreas();

  const handleNext = useCallback(() => {
    setLoading(true);
    saveAreas(provider.key, { serviceAreas: areas.toArray() }).then(() => {
      setLoading(false);
      onNext();
    });
  }, [areas]);

  return (
    <>
      <CardContent>
        <AreasContainer>
          {locations.map(location => {
            const checked = areas.has(location);

            return (
              <AreaContainer key={location} selected={checked}>
                {location}
                <Checkbox
                  color="primary"
                  size="small"
                  checked={checked}
                  onChange={({ target }) =>
                    setAreas(
                      target.checked
                        ? areas.add(location)
                        : areas.remove(location)
                    )
                  }
                />
              </AreaContainer>
            );
          })}
        </AreasContainer>
      </CardContent>

      {buttons({
        disableNext: areas.size === 0,
        loading,
        onNext: handleNext
      })}
    </>
  );
};
