import * as React from "react";
import { SiteLink } from "@/components/SiteLink";
import styled from "styled-components";
import { Navigation } from "@/components/Navigation";
import theme from "@/config/theme";
import { Button } from "@material-ui/core";
import * as background from "./5.png";
import * as logo from "./beta-logo.png";

const Spacer = styled.div`
  height: 350px;

  ${theme.breakpoints.down("xs")} {
    height: 400px;
  }
`;

const Container = styled.section`
  position: fixed;
  width: 100%;
  background-image: url(${background});
  background-size: cover;
  padding: 32px;
  color: #fff;
  height: 350px;
  z-index: 0;

  ${theme.breakpoints.down("xs")} {
    height: 400px;
  }
`;

const BetaHeader = styled.h1`
  position: relative;
  margin: 0;
  line-height: 1;
  margin-right: auto;
  font-size: 64px;
  text-transform: uppercase;

  ${theme.breakpoints.down("xs")} {
    font-size: 48px;
  }

 }
`;

const NavigationContainer = styled.header`
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

const HeroParagraph = styled.p`
  max-width: 500px;
  font-size: 18px;
  margin-bottom: 24px;
  font-weight: 400;
`;

export const HeroHeader = () => {
  return (
    <React.Fragment>
      <Spacer />
      <Container>
        <NavigationContainer>
          <img width={"300px"} src={logo} alt="Hallò"></img>
          <BetaHeader></BetaHeader>
          <Navigation />
        </NavigationContainer>

        <HeroParagraph>
          Your on demand marketplace for reliable skilled helpers. Get the who
          for what you need. Currently available in the San Juan metro area.
        </HeroParagraph>

        <HeroParagraph>Your island life just got easier.</HeroParagraph>

        <Button
          variant="outlined"
          color="inherit"
          component={SiteLink}
          href={"https://hallopr.com/features"}
        >
          Learn More
        </Button>
      </Container>
    </React.Fragment>
  );
};
