import { Map } from "immutable";
import usePromise from "react-use-promise";
import { firebaseApp } from "../config/firebase";
import { LOADING } from "./awaitData";
import { Booking, BookingLocation } from "./booking";

const getRef = async ref => {
  return { ...(await ref.get()).data(), id: ref.id };
};

export const useBooking = id => {
  const [result, error, state] = usePromise(async () => {
    if (id == null) {
      return new Promise(() => {});
    }

    const doc = await firebaseApp
      .firestore()
      .doc(`bookings/${id}`)
      .get();

    const data = doc.data();

    return new Booking({
      ...data,
      id: doc.id,
      client: await getRef(data.client),
      provider: await getRef(data.provider),
      services: new Map(data.services),
      location: new BookingLocation({
        ...data.location,
        options: data.location.options
      })
    });
  }, [id]);

  if (state === "pending") {
    return LOADING;
  }

  if (state === "rejected") {
    throw error;
  }

  return result;
};
