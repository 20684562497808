import { LoginForm } from "@/components/LoginForm";
import { LoginTabs } from "@/components/LoginTabs";
import { SitePage } from "@/layouts/SitePage";
import { useRouter } from "@/utils/routes";
import * as React from "react";
import styled from "styled-components";

const LoginContainer = styled.div`
  && {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    position: absolute;
    top: 10vh;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const NavTabs = styled(LoginTabs)`
  && {
    margin-bottom: 32px;
  }
`;

const SignIn = () => {
  const router = useRouter();
  const query = new URLSearchParams(router.location.search.slice(1));

  return (
    <SitePage gray>
      <LoginContainer>
        <NavTabs active="signIn" />
        <LoginForm
          onSuccess={() => (window.location.href = query.get("r") || "/")}
        />
      </LoginContainer>
    </SitePage>
  );
};

export default SignIn;
