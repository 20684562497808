import { GrowLoading } from "@/components/GrowLoading";
import { GetProviderButtons } from "@/components/providerSetup/ServiceProviderSetup";
import { TextFormField } from "@/components/TextFormField";
import { UploadProfileImage } from "@/components/UploadProfileImage";
import { firebaseApp } from "@/config/firebase";
import { awaitData } from "@/hooks/awaitData";
import { makeRule, useForm } from "@/hooks/useForm";
import { UserProfile, useUserProfile } from "@/hooks/useUserProfile";
import { withProps } from "@/utils/withProps";
import { CardContent, Grid } from "@material-ui/core";
import * as React from "react";
import { useCallback, useState } from "react";
import styled from "styled-components";
import { useAsyncCallback } from "@/hooks/useAsyncCallback";

interface Props {
  buttons: GetProviderButtons;
  onNext: () => void;
}

const ProfileContainer = styled.div`
  display: flex;
  padding: 16px 0;
  justify-content: center;
`;

const NameContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 24px;
  flex-grow: 1;
  align-items: stretch;

  & > *:first-child {
    margin-top: 0;
  }
`;

const formFields = {
  image: {
    getErrors: makeRule(
      "Please upload a profile image",
      (name: string | null) => name !== null && name.length > 0
    ),

    field: UploadProfileImage
  },

  firstName: {
    getErrors: makeRule(
      "Please enter your first name",
      (name: string) => name.length > 0
    ),
    field: withProps(TextFormField, {
      label: "First Name",
      variant: "standard"
    })
  },

  lastName: {
    getErrors: makeRule(
      "Please enter your last name",
      (name: string) => name.length > 0
    ),
    field: withProps(TextFormField, {
      label: "Last name",
      variant: "standard"
    })
  }
};

const saveProfile = async (
  newImage: boolean,
  form: { firstName: string; lastName: string; image: string | null },
  user: UserProfile
) => {
  const db = firebaseApp.firestore();
  const storage = firebaseApp.storage();

  if (newImage) {
    const ref = storage.ref(`profiles/${user.key}.jpg`);
    await ref.putString(form.image, "data_url");
  }

  await db.doc(`/users/${user.key}`).update({
    firstName: form.firstName,
    lastName: form.lastName
  });
};

const ProviderProfileContent = ({
  buttons,
  user,
  onNext
}: Props & { user: UserProfile }) => {
  const [fields, value, errors, { setShowErrors }] = useForm(formFields, {
    firstName: user.firstName,
    lastName: user.lastName,
    image: user.profileImage
  });

  const [loading, setLoading] = useState(false);

  const handleNext = useAsyncCallback(async () => {
    if (errors.length > 0) {
      setShowErrors(true);
    } else {
      setLoading(true);
      const hasNewImage = value.image !== user.profileImage;
      await saveProfile(hasNewImage, value, user);
      setLoading(false);
      onNext();
    }
  }, [errors, value, user]);

  return (
    <div>
      <CardContent>
        <Grid container alignItems="center">
          <ProfileContainer>{fields.image}</ProfileContainer>
          <NameContainer>
            {fields.firstName}
            {fields.lastName}
          </NameContainer>
        </Grid>
      </CardContent>

      {buttons({ loading, onNext: handleNext })}
    </div>
  );
};

export const ProviderProfile = (props: Props) => {
  const user = useUserProfile();

  return awaitData(user, {
    loading() {
      return <GrowLoading />;
    },

    data: user => {
      return <ProviderProfileContent {...props} user={user} />;
    }
  });
};
