import { FormControl, InputLabel, Button } from "@material-ui/core";
import { format } from "date-fns";
import * as React from "react";
import styled from "styled-components";
import { formatAddress } from "../utils/formatAddress";
import { ReadonlyInput } from "./ReadonlyInput";
import { Booking } from "@/config/collections/booking";
import { ServiceProvider } from "@/config/collections/provider";

interface Props {
  booking: Booking;
  provider: ServiceProvider;
}

const Detail = styled(FormControl)`
  && {
    width: 100%;
    margin-top: 16px;
  }
`;

export default ({ booking, provider }: Props) => (
  <>
    <Detail>
      <InputLabel shrink={true}>Requested time</InputLabel>
      <ReadonlyInput
        onFocus={e => e.currentTarget.blur()}
        value={
          booking.appointmentStart === "ASAP"
            ? "ASAP"
            : format(
                booking.appointmentStart.seconds * 1000,
                "MMMM do yyyy, h:mm a"
              )
        }
      />
    </Detail>

    <Detail>
      <InputLabel shrink={true}>Address</InputLabel>
      <ReadonlyInput
        onFocus={e => e.currentTarget.blur()}
        value={
          booking.location.type === "client"
            ? formatAddress(booking.location.options)
            : formatAddress(provider.businessAddress)
        }
      />
      <div>
        <Button
          component="a"
          href={`https://maps.google.com/?q=${encodeURIComponent(
            formatAddress(booking.location.options)
          )}`}
          target={"_blank"}
          style={{ marginLeft: "-8px" }}
          size="small"
          color="primary"
        >
          Open Map
        </Button>
      </div>
    </Detail>
  </>
);
