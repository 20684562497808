import { useMemo } from "react";
import { useCollectionOnce } from "react-firebase-hooks/firestore";
import { firebaseApp } from "../config/firebase";
import { LOADING } from "./awaitData";

export const useServiceProviders = category => {
  const query = useMemo(() => {
    const query = firebaseApp
      .firestore()
      .collection("serviceProviders")
      .where("public", "==", true)
      .limit(5);

    if (category != null) {
      return query.where("serviceCategories", "array-contains", category);
    }

    return query;
  }, [category]);

  const [result, loading, error] = useCollectionOnce(query);

  const docs = useMemo(() => {
    if (result) {
      return result.docs.map(res => ({ ...res.data(), id: res.id }));
    }
    return null;
  }, [result]);

  if (loading) {
    return LOADING;
  }

  if (error) {
    throw error;
  }

  return docs;
};
