import React, { useReducer, useCallback } from "react";
import {
  List,
  Typography,
  CardContent,
  ListItem,
  LinearProgress
} from "@material-ui/core";
import { BookingService } from "./BookingService";
import styled from "styled-components";
import { Map } from "immutable";
import { useProviderServices } from "@/hooks/useProviderServices";
import { awaitData } from "@/hooks/awaitData";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useBookingContext } from "@/hooks/booking";

const ServiceListContainer = styled.div`
  padding-bottom: 16px;
  overflow-y: scroll;
`;

const CardLabel = styled(CardContent)`
  && {
    padding-bottom: 0;
  }
`;

const SelectBookingServices = ({ provider, buttons }) => {
  const bookingContext = useBookingContext();
  const services = useProviderServices(provider.id);

  const serviceSettings = bookingContext.booking.services;

  const updateSettings = useCallback(
    action => {
      if (action.type === "add" && action.settings.quantity !== 0) {
        bookingContext.setBookingService(action.id, action.settings);
      } else {
        bookingContext.removeBookingService(action.id);
      }
    },
    [bookingContext.booking]
  );

  return (
    <ServiceListContainer>
      {buttons({ disableNext: bookingContext.booking.services.size === 0 })}

      <CardLabel>
        <Typography component="h3" variant="h6">
          Let's setup your appointment. How can {provider.name} help you today?
        </Typography>
      </CardLabel>

      {awaitData(services, {
        loading() {
          return (
            <CardContent>
              <LinearProgress />
            </CardContent>
          );
        },

        data(services) {
          return (
            <List>
              {services.map(service => {
                return (
                  <BookingService
                    key={service.id}
                    id={service.id}
                    service={service}
                    selected={serviceSettings.has(service.id)}
                    settings={serviceSettings.get(service.id)}
                    onUpdateSettings={settings =>
                      updateSettings({
                        type: "add",
                        id: service.id,
                        settings: settings
                      })
                    }
                    onClear={() =>
                      updateSettings({
                        type: "remove",
                        id: service.id
                      })
                    }
                  />
                );
              })}
            </List>
          );
        }
      })}
    </ServiceListContainer>
  );
};

export default SelectBookingServices;
