import * as React from "react";
import Home from "@pages/index";
import { useRouter } from "@/utils/routes";
import { useServiceProvider } from "@/hooks/useServiceProvider";
import { awaitData } from "@/hooks/awaitData";
import { ProvideBookingContext, Booking } from "@/hooks/booking";
import BookAppointment from "@/components/BookAppointment";

const Provider = ({ match: { params } }) => {
  const { pid } = params;

  const serviceProvider = useServiceProvider(pid);

  return (
    <Home
      dialog={awaitData(serviceProvider, {
        loading() {
          return null;
        },

        data(provider) {
          return (
            <ProvideBookingContext
              defaultState={{ booking: new Booking({ provider }) }}
            >
              <BookAppointment provider={provider} />
            </ProvideBookingContext>
          );
        }
      })}
    />
  );
};

export default Provider;
