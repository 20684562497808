import { LOADING } from "@/hooks/awaitData";
import { useAsyncCallback } from "@/hooks/useAsyncCallback";
import { Map } from "immutable";
import { QueueingSubject } from "queueing-subject";
import * as React from "react";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { collection as rxCollection, doc } from "rxfire/firestore";
import { defer, merge, never, Observable, of } from "rxjs";
import {
  map,
  shareReplay,
  switchMap,
  take,
  withLatestFrom
} from "rxjs/operators";

export interface PageOptions {}

export interface DraftCollectionOptions<Path extends string, Data, Payloads> {
  path: Path;

  mutations: {
    [key in keyof Payloads]: (data: Data, payload: Payloads[key]) => Data;
  };
}

export const StoreContext = React.createContext({} as State);

export const useDraftState = () => {
  return useContext(StoreContext) as State;
};

export const useSavePatch = <Data, Payloads>(
  options: DraftCollectionOptions<string, Data, Payloads>,
  patch: (data: Data) => Partial<Data>
) => {
  const store = useContext(StoreContext);

  return useAsyncCallback(
    (id: string) => {
      return store.saveDraft(options.path, id, patch).toPromise();
    },
    [store, options, patch]
  );
};

export const useMakeDraft = <Data, Payloads>(
  options: DraftCollectionOptions<string, Data, Payloads>
) => {
  const store = useContext(StoreContext);

  return useAsyncCallback(
    async (id: string) => {
      const value = await store
        .getRemote(options.path, id)
        .pipe(take(1))
        .toPromise();

      store.updateDraft(options.path, id, () => value);
    },
    [store, options]
  );
};

export const useSelector = <T, Args extends any[]>(
  select: (state: State, ...args: Args) => Observable<T>,
  ...args: { [key in keyof Args]: Args[key] | typeof LOADING }
): T | typeof LOADING => {
  const store = useContext(StoreContext);

  const observable: Observable<typeof LOADING | T> = useMemo(() => {
    if (args.some(value => value === LOADING)) {
      return of(LOADING);
    }

    return select(store, ...(args as Args));
  }, args);

  const [value, setValue] = useState(LOADING as typeof LOADING | T);
  const [error, setError] = useState();

  if (error != null) {
    throw error;
  }

  useEffect(() => {
    if (observable) {
      const sub = observable.subscribe({
        next(value) {
          setValue(value);
        },

        complete() {},

        error(e) {
          setError(e);
        }
      });

      return () => {
        sub.unsubscribe();
      };
    }
  }, [observable]);

  return value;
};

export const selectCollection = <Data>(
  state: State,
  collection: DraftCollectionOptions<string, Data, any>,
  parent: string = ""
): Observable<Data[]> => {
  const collectionPath = collection.path;
  const path = collectionPath.slice(collectionPath.lastIndexOf("/") + 1);
  const fullPath = parent ? `${parent}/${path}` : path;

  return state.getAll(fullPath, {});
};

export const selectDraftDocument = <Data>(
  state: State,
  collection: DraftCollectionOptions<string, Data, any>,
  id: string
): Observable<Data> => {
  return state.getDraft(collection.path, id);
};

export const selectDocument = <Data>(
  state: State,
  collection: DraftCollectionOptions<string, Data, any>,
  id: string
): Observable<Data> => {
  return state.get(collection.path, id);
};

export const selectQuery = <T = any>(
  state: State,
  makeQuery: (
    firestore: firebase.firestore.Firestore
  ) => firebase.firestore.Query
): Observable<T[]> => {
  return (state.getQuery(makeQuery) as any) as Observable<T[]>;
};

export const useMutation = <Payloads, Data, Key extends keyof Payloads>(
  options: DraftCollectionOptions<string, Data, Payloads>,
  mutation: Key
) => {
  const draftState = useDraftState();

  return useCallback((id: string, payload: Payloads[Key]) => {
    draftState.updateDraft(options.path, id, data =>
      options.mutations[mutation](data, payload)
    );
  }, []);
};

export const useDeleteDocument = () => {
  const draftState = useDraftState();

  return useAsyncCallback(
    (key: string) => {
      return draftState.deleteDocument(key);
    },
    [draftState]
  );
};

export const useRemoveDraft = (
  collection: DraftCollectionOptions<any, any, any>
) => {
  const draftState = useDraftState();

  return useCallback(
    (key: string) => {
      draftState.removeDraft(collection.path, key);
    },
    [draftState]
  );
};

export const makeKey = (
  collection: DraftCollectionOptions<string, any, any>,
  ...keys: string[]
) => {
  const segments = collection.path.split("*");

  if (keys.length !== segments.length) {
    throw new Error(`not enough keys for path ${collection.path}`);
  }

  return segments.map((segment, index) => `${segment}${keys[index]}`).join("");
};

export const createDraftCollection = <Path extends string, Data, Payloads>(
  options: DraftCollectionOptions<Path, Data, Payloads>
) => {
  return options;
};

const getData = (
  snapshot:
    | firebase.firestore.QueryDocumentSnapshot
    | firebase.firestore.DocumentSnapshot
) => {
  return {
    ...snapshot.data(),
    key: snapshot.ref.path,
    id: snapshot.id
  };
};

type State = typeof createCollectionsState extends (
  ...args: any[]
) => infer store
  ? store
  : never;

export const createCollectionsState = (
  firestore: firebase.firestore.Firestore
) => {
  const setDraft$ = new QueueingSubject<{
    path: string;
    id: string;
    data: any;
  }>();

  const removeDraft$ = new QueueingSubject<{ path: string; id: string }>();

  const drafts$: Observable<Map<string, Map<string, any>>> = merge(
    of(Map() as Map<string, Map<string, any>>),
    setDraft$.pipe(
      withLatestFrom(defer(() => drafts$), (action, drafts) => {
        return drafts.updateIn([action.path, action.id], action.data);
      })
    ),
    removeDraft$.pipe(
      withLatestFrom(defer(() => drafts$), (action, drafts) =>
        drafts.removeIn([action.path, action.id])
      )
    )
  ).pipe(shareReplay(1));

  const makeDocumentRef = (key: string) => {
    return firestore.doc(key);
  };

  const store = {
    getQuery<T>(
      makeQuery: (
        store: firebase.firestore.Firestore
      ) => firebase.firestore.Query
    ) {
      return (rxCollection(makeQuery(firestore)).pipe(
        map(value => value.map(value => getData(value)))
      ) as any) as Observable<T[]>;
    },

    removeDraft(collection: string, id: string) {
      removeDraft$.next({ path: collection, id });
    },

    deleteDocument(id: string) {
      return makeDocumentRef(id).delete();
    },

    updateDraft(collection: string, id: string, data: (data: any) => any) {
      setDraft$.next({ path: collection, id, data });
    },

    saveDraft(collection: string, id: string, getPatch: (data: any) => any) {
      return drafts$.pipe(
        take(1),
        switchMap(draft => {
          const patch = getPatch(draft.getIn([collection, id]));
          const ref = makeDocumentRef(id);

          return ref
            .get()
            .then(doc => (doc.exists ? ref.update(patch) : ref.set(patch)));
        })
      );
    },

    getRemote(collection: string, id: string): Observable<any> {
      return doc(makeDocumentRef(id)).pipe(map(value => getData(value)));
    },

    getDraft(collection: string, id: string): Observable<any> {
      return drafts$.pipe(
        switchMap(draft => {
          if (draft.hasIn([collection, id])) {
            return of(draft.getIn([collection, id]));
          }

          return never();
        })
      );
    },

    get(collection: string, id: string): Observable<any> {
      return drafts$.pipe(
        switchMap(draft => {
          if (draft.hasIn([collection, id])) {
            return of(draft.getIn([collection, id]));
          }

          return store.getRemote(collection, id);
        })
      );
    },

    getAll(collection: string, options: PageOptions): Observable<any> {
      return rxCollection(firestore.collection(collection)).pipe(
        map(value => value.map(value => getData(value)))
      );
    }
  };

  return store;
};
