import { firebaseApp } from "../config/firebase";
import { LOADING } from "./awaitData";
import { useCurrentUser } from "./useCurrentUser";
import createUseContext from "constate";
import { useSelector } from "@/config/draftCollection";
import { doc } from "rxfire/firestore";
import { getDownloadURL } from "rxfire/storage";
import { catchError } from "rxjs/operators";
import { combineLatest, empty, of } from "rxjs";

export interface UserProfile {
  key: string;
  firstName: string;
  lastName: string;
  email: string;
  profileImage: string | null;
  serviceProvider: firebase.firestore.DocumentReference;
}

export const useUserProfile = createUseContext(() => {
  const { uid: id } = useCurrentUser() || { uid: LOADING };

  return useSelector((store, id) => {
    if (id === LOADING) {
      return empty();
    }

    const profileImageRef = firebaseApp.storage().ref(`/profiles/${id}.jpg`);
    const ref = firebaseApp.firestore().doc(`users/${id}`);

    return combineLatest(
      [
        doc(ref),
        getDownloadURL(profileImageRef).pipe(catchError(() => of(null)))
      ],
      (doc, image) => {
        return {
          ...(doc.data() as UserProfile),
          key: doc.id,
          id: doc.id,
          profileImage: image
        };
      }
    );
  }, id);
});
