import { GrowLoading } from "@/components/GrowLoading";
import { StepHeader } from "@/components/providerSetup/styles";
import { SiteLink } from "@/components/SiteLink";
import { useProvider } from "@/config/collections/provider";
import { useRequests } from "@/config/collections/requests";
import { awaitData } from "@/hooks/awaitData";
import { ServicePortal } from "@/layouts/ServicePortal";
import { CardContent, List, ListItem, ListItemText } from "@material-ui/core";
import { format } from "date-fns";
import * as React from "react";

const RequestsList = () => {
  const provider = useProvider();
  const requests = useRequests(
    awaitData(provider, { data: provider => provider.id })
  );

  return (
    <ServicePortal
      header={
        <CardContent style={{ paddingBottom: 0 }}>
          <StepHeader >Your Requests</StepHeader>
        </CardContent>
      }
    >
      <List>
        {awaitData(requests, {
          loading() {
            return <GrowLoading />;
          },

          data(requests) {
            return (
              <>
                {requests.map(value => {
                  const createDate = new Date(value.create.seconds * 1000);
                  return (
                    <ListItem
                      href={`/service/requests/${value.id}`}
                      component={SiteLink}
                      button
                      key={value.key}
                    >
                      <ListItemText
                        primary={`Appointment on ${format(createDate, "PPP")}`}
                      />
                    </ListItem>
                  );
                })}
              </>
            );
          }
        })}
      </List>
    </ServicePortal>
  );
};

export default RequestsList;
