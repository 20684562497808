import { ContactInformation } from "@/components/providerSetup/ContactInformation";
import { ProviderProfile } from "@/components/providerSetup/ProviderProfile";
import { ServiceProvider } from "@/config/collections/provider";
import { verifyReferralCode } from "@/config/firebase";
import { awaitData, LOADING } from "@/hooks/awaitData";
import { useRouter } from "@/utils/routes";
import {
  CardContent,
  CircularProgress,
  Grid,
  SnackbarContent,
  Typography
} from "@material-ui/core";
import { amber } from "@material-ui/core/colors";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import { ProviderLocation } from "./ProviderLocations";
import { ProviderServices } from "./ProviderServices";
import { ServiceIntro } from "./ServiceIntro";
import { GetProviderButtons } from "./ServiceProviderSetup";
import { StepHeader } from "./styles";
import Warning from "@material-ui/icons/Warning";
import theme from "@/config/theme";

export const WarningSnackbar = styled(SnackbarContent)`
  && {
    background-color: ${amber[700]};
    box-shadow: ${theme.shadows[1]};

    & > * {
      display: flex;
      align-items: center;
    }
  }
`;

export interface StepProps {
  buttons: GetProviderButtons;
  onNext: () => void;
  onBack: () => void;
  verified: null | boolean | typeof LOADING;
  referralCode?: string;
  provider: ServiceProvider;
}

export interface ProviderStep {
  id: string;
  label: string;
  title?: React.ReactChild;
  component: React.ComponentType<StepProps>;
}

export const steps: ProviderStep[] = [
  {
    id: "welcome",
    label: "Welcome",
    title: <StepHeader>Welcome to Hallo On-boarding!</StepHeader>,
    component: ({ buttons, verified }) => {
      return (
        <>
          <CardContent>
            <Typography variant="body1">
              We're glad to see you! Please complete this onboarding setup to
              get started.
            </Typography>
            <div style={{ marginTop: "16px" }}>
              {awaitData(verified, {
                loading() {
                  return (
                    <Grid container alignItems="center" wrap="nowrap">
                      <CircularProgress
                        size={16}
                        style={{ marginRight: "8px" }}
                      />
                      Verifying Referral
                    </Grid>
                  );
                },

                data(verified) {
                  if (verified === true) {
                    return (
                      <>
                        <Typography variant="h6">
                          You've been referred!
                        </Typography>
                        <Typography
                          style={{ marginTop: "8px" }}
                          variant="body1"
                        >
                          Through this link, you could be elegible to earn up to
                          a $500 dollar bonus on Hallo within your first 3
                          months on the platform. You can learn more about our
                          referral program{" "}
                          <a
                            href="https://hallò.com/referraltermsandconditions"
                            target="_blank"
                          >
                            here
                          </a>
                          .
                        </Typography>
                      </>
                    );
                  }

                  if (verified === false) {
                    return (
                      <WarningSnackbar
                        message={
                          <>
                            <Warning
                              style={{ marginRight: "8px", fontSize: "18px" }}
                            />
                            <Typography variant="body2">
                              This referral link is not valid, however you can
                              continue setup without refferal.
                            </Typography>
                          </>
                        }
                      />
                    );
                  }

                  console.log(verified);
                  return null;
                }
              })}
            </div>
          </CardContent>
          {buttons({})}
        </>
      );
    }
  },

  {
    id: "profile",
    label: "Profile",
    title: <StepHeader>Complete your profile</StepHeader>,
    component: ProviderProfile
  },

  {
    id: "contact",
    label: "Contact",
    title: <StepHeader>Let's get your contact information</StepHeader>,
    component: ContactInformation
  },

  {
    id: "locations",
    label: "Locations",
    title: <StepHeader>Which areas do you service?</StepHeader>,
    component: ProviderLocation
  },

  {
    id: "bio",
    label: "Bio",
    component: ServiceIntro
  },

  {
    id: "services",
    label: "Skills",
    component: ProviderServices
  },

  /*
  {
    id: "documents",
    label: "Documents",
    component: ProviderDocuments
  }
  */

  {
    id: "background",
    label: "Background & Identity",
    title: <StepHeader>Confirm your identity</StepHeader>,
    component: ({ buttons, onNext }) => (
      <>
        <CardContent>
          <Typography variant="body1">
            Sweet! You're almost done. Let's keep going. Next, we need to verify
            your identity and run a background check. Once you've completed the
            form below, we'll confirm your account and you'll be able to start
            booking appointments on Hallo.
          </Typography>
        </CardContent>
        {buttons({
          nextLabel: "Continue.",
          onNext: () => {
            window.open("https://halloapp.typeform.com/to/YZSMfT");
            onNext();
          }
        })}
      </>
    )
  },

  {
    id: "complete",
    label: "Finish",
    title: <StepHeader>Great! You're all set for now.</StepHeader>,
    component: ({ buttons, onNext }) => {
      const router = useRouter();

      return (
        <>
          <CardContent>
            <Typography variant="body1">
              We'll send you an email with next steps once we review you're
              information.
            </Typography>
          </CardContent>
          {buttons({
            nextLabel: "Back to Hallo",
            onNext: () => {
              router.history.push("/");
              onNext();
            }
          })}
        </>
      );
    }
  }
];
