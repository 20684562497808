import { CircularProgress } from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import * as React from "react";
import { ButtonsContainer } from "./styles";
import NavigateNext from "@material-ui/icons/NavigateNext";
import styled from "styled-components";

const BackButton = styled(Button)`
  margin-left: 8px;
`;

interface Props {
  onBack?: () => void;
  onNext?: () => void;
  loading?: boolean;
  nextLabel?: React.ReactChild;
}

export const StepButtons = ({ onBack, loading, onNext, nextLabel }: Props) => {
  const hideBack = onBack == null;
  const disableNext = onNext == null;

  return (
    <ButtonsContainer>
      {!hideBack && <BackButton onClick={onBack}>Back</BackButton>}
      <Button
        color="primary"
        onClick={onNext}
        disabled={disableNext || loading}
      >
        {nextLabel || "Continue"} <NavigateNext />
      </Button>
      {loading && <CircularProgress size={24} />}
    </ButtonsContainer>
  );
};
