import React, { useMemo, useState, useCallback } from "react";
import {
  Typography,
  Paper,
  colors,
  FormControl,
  FormLabel,
  CardContent,
  LinearProgress
} from "@material-ui/core";
import { useBookingContext } from "@/hooks/booking";
import { getRelativeDayLabel } from "../utils/time";
import { format, startOfDay } from "date-fns";
import { formatAddress } from "../utils/formatAddress";
import LoadScript from "react-load-script";
import { StripeProvider } from "react-stripe-elements";
import { Elements, CardElement } from "react-stripe-elements";
import styled from "styled-components";
import { ServicesTable } from "./ServicesTable";
import { confirmAppointmentPayment } from "../config/firebase";
import { useBookingLineItems } from "@/hooks/useBookingLineItems";
import { awaitData } from "@/hooks/awaitData";
import { useAsyncCallback } from "@/hooks/useAsyncCallback";
import ErrorIcon from "@material-ui/icons/Error";

const CreditCardInput = styled(Paper)`
  && {
    padding: 16px 12px;
    margin-top: 8px;
    background-color: ${colors.grey[200]};
  }
`;

const CreditCardControl = styled(FormControl)`
  && {
    margin-top: 32px;
    width: 100%;
  }
`;

const ErrorMessage = styled(Typography)`
  && {
    display: flex;
    align-items: center;
    margin-top: 16px;

    svg {
      margin-right: 8px;
    }
  }
`;

const errorMessages = {
  cardError:
    "We ran into an issue confirming your payment details. Please make sure they're correct.",
  chargeError:
    "We were able to confirm your payment details but couldn't confirm funds are available.",
  unknownError:
    "We ran into an unknown issue creating your appointment. Please try again later or contact support@hallopr.com.",
  priceChanged:
    "The price for these services was changed by the service provider since staring your order. Please see updated pricing above, we apploogize for any inconvenience."
};

export const AppointmentPayment = ({ buttons, onSuccess }) => {
  const {
    booking: { provider, appointmentStart, location, services: servicesMap },
    setPaymentToken,
    saveBooking
  } = useBookingContext();
  const today = useMemo(() => startOfDay(Date.now()), []);
  const [stripeReady, setStripReady] = useState(false);
  const [paymentElement, setPaymentElement] = useState(null);
  const [paymentInformation, setPaymentInformation] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [error, setError] = useState();
  const [lineItems, updateLineItems] = useBookingLineItems(
    provider.id,
    servicesMap
  );
  const stripe = useMemo(
    () =>
      stripeReady && new Stripe("pk_test_5N4waOKUqMGTRW7KOwOANpmc00SGzC4tcr"),
    [stripeReady]
  );

  const handleConfirmPayment = useAsyncCallback(async () => {
    setActionLoading(true);
    try {
      const { paymentMethod, error } = await stripe.createPaymentMethod(
        "card",
        paymentElement
      );

      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setError("paymentError");
        } else {
          setError("unknownError");
        }
        return;
      }

      const { id } = await saveBooking();

      try {
        await confirmAppointmentPayment({
          paymentMethod: paymentMethod.id,
          bookingId: id,
          totalQuotedPrice: lineItems.reduce((acc, item) => acc + item.cost, 0)
        });
      } catch (e) {
        if (e.details) {
          if (e.details.errorType === "paymentIssue") {
            setError("chargeError");
            return;
          }

          if (e.details.errorType === "priceChanged") {
            updateLineItems();
            setError("priceChanged");
            return;
          }
        }

        console.error(e);
        setError("unknownError");
        return;
      }

      onSuccess();
    } finally {
      setActionLoading(false);
    }
  }, [paymentElement, stripe, setPaymentToken, saveBooking, updateLineItems]);

  return (
    <>
      <CardContent>
        <Typography variant="body2">
          Great. We'll tell {provider.name} you'd like to meet{" "}
          <strong>
            {getRelativeDayLabel(appointmentStart, today)} at{" "}
            {format(appointmentStart, "h:mm a")}
          </strong>{" "}
          located at <strong>{formatAddress(location.options)}</strong>.{" "}
          {provider.name} will confirm the appointment after you confirm
          payment. You won't be changed until after your appointment.
        </Typography>
      </CardContent>
      {awaitData(lineItems, {
        loading: () => <LinearProgress />,
        data: items => <ServicesTable lineItems={items} />
      })}
      <CardContent>
        <LoadScript
          url="https://js.stripe.com/v3/"
          onLoad={() => setStripReady(true)}
        />

        {error != null && (
          <ErrorMessage variant="body2" gutterTop color="error">
            <ErrorIcon /> {errorMessages[error]}
          </ErrorMessage>
        )}

        {stripeReady && stripe && (
          <StripeProvider stripe={stripe}>
            <Elements>
              <form action="/charge" method="post" id="payment-form">
                <CreditCardControl for="card-element">
                  <FormLabel>Confirm Payment</FormLabel>
                  <CreditCardInput>
                    <CardElement
                      id="stripe-confirm-payment-element"
                      onReady={element => setPaymentElement(element)}
                      onChange={paymentInformation =>
                        setPaymentInformation(paymentInformation)
                      }
                    />
                  </CreditCardInput>
                </CreditCardControl>
              </form>
            </Elements>
          </StripeProvider>
        )}

        {buttons({
          nextLabel: "Confirm Payment",
          loading: actionLoading,
          disableNext:
            paymentInformation == null || !paymentInformation.complete,
          onNext: handleConfirmPayment
        })}
      </CardContent>
    </>
  );
};
