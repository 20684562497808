import React from "react";
import {
  Typography,
  Checkbox,
  FormControlLabel,
  ListItem,
  ListItemIcon,
  colors,
  TextField
} from "@material-ui/core";
import styled from "styled-components";
import { Add } from "@material-ui/icons";

const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const BookingService = ({
  selected,
  settings,
  onUpdateSettings,
  onClear,
  service
}) => {
  return (
    <>
      <ListItem button={!selected}>
        <div>
          <Header>
            <Typography variant="subtitle1" component="h5">
              {service.name}
            </Typography>
          </Header>
          <Typography component="p" variant="caption">
            {service.description}
          </Typography>
        </div>
        <ListItemIcon>
          <Checkbox
            checked={selected}
            onChange={e => {
              e.target.checked ? onUpdateSettings({ quantity: 1 }) : onClear();
            }}
          />
        </ListItemIcon>
      </ListItem>
      {selected && (
        <ListItem>
          <TextField
            onChange={e =>
              onUpdateSettings({ quantity: parseInt(e.target.value, 10) })
            }
            value={settings.quantity}
            type="number"
            margin="dense"
            variant="outlined"
            label="Quantity"
          />
        </ListItem>
      )}
    </>
  );
};
