import { Typography } from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import * as React from "react";
import styled from "styled-components";
import theme from "../config/theme";

interface Props {
  title: React.ReactNode;
  message?: React.ReactNode;
}

const FeatureCheckCircle = styled(CheckCircle)`
  && {
    margin: 0 auto;
    margin-bottom: 16px;
    font-size: 120px;
    color: ${theme.palette.primary.main};
  }
` as typeof CheckCircle;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`;

const ConfirmMessage = styled(Typography)`
  && {
    margin: 0 auto;
    max-width: 450px;
    text-align: center;
    margin-top: 16px;
  }
` as typeof Typography;

export const SuccessMessage = ({ title, message }: Props) => {
  return (
    <Container>
      <FeatureCheckCircle />
      <Typography variant="h5" component="h3" align="center">
        {title}
      </Typography>

      <ConfirmMessage variant="body1" component="p">
        {message}
      </ConfirmMessage>
    </Container>
  );
};
