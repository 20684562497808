import * as React from "react";
import styled, { css } from "styled-components";
import { useRef, useEffect } from "react";

interface Props {
  children: React.ReactChild;
  className?: string;
}

const scrollShadow = css`
  z-index: 10;
  content: "";
  display: block;
  position: absolute;
  height: 16px;
  left: 0;
  width: 100%;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2),
    0px 0px 4px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px -2px rgba(0, 0, 0, 0.12);
  opacity: 0;
  transition: opacity 0.25s;
`;

const ScrollContainer = styled.div`
  overflow-y: auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  & > * {
    min-height: min-content;
    flex-shrink: 0;
  }

  &:before {
    ${scrollShadow}
    top: -16px;
  }

  &:after {
    ${scrollShadow}
    bottom: -16px;
  }

  &.scroll-off-top {
    &:before {
      opacity: 1;
    }
  }

  &.scroll-off-bottom {
    &:after {
      opacity: 1;
    }
  }
`;

const ViewportContainer = styled.div`
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  display: flex;
`;

export const ScrollList = ({ children, className }: Props) => {
  const topPixel = useRef<HTMLElement>(null);
  const bottomPixel = useRef<HTMLElement>(null);
  const scrollContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const topPixelEl = topPixel.current;
    const bottomPixelEl = bottomPixel.current;
    const containerEl = scrollContainer.current;

    if (containerEl == null || bottomPixelEl == null || topPixelEl == null) {
      return;
    }

    const observer = new IntersectionObserver(
      targets => {
        const bottom = targets.find(el => el.target === bottomPixelEl);

        if (bottom) {
          if (!bottom.isIntersecting) {
            containerEl.classList.add("scroll-off-bottom");
          } else {
            containerEl.classList.remove("scroll-off-bottom");
          }
        }

        const top = targets.find(el => el.target === topPixelEl);

        if (top) {
          if (!top.isIntersecting) {
            containerEl.classList.add("scroll-off-top");
          } else {
            containerEl.classList.remove("scroll-off-top");
          }
        }
      },
      { root: containerEl }
    );

    observer.observe(topPixelEl);
    observer.observe(bottomPixelEl);

    return () => {
      observer.unobserve(topPixelEl);
      observer.unobserve(bottomPixelEl);
    };
  }, []);

  return (
    <ViewportContainer className={className}>
      <ScrollContainer ref={scrollContainer}>
        <span ref={topPixel} />
        {children}
        <span style={{ height: "1px", display: "block" }} ref={bottomPixel} />
      </ScrollContainer>
    </ViewportContainer>
  );
};
