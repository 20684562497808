import { BackButton } from "@/components/BackButton";
import { SiteLink } from "@/components/SiteLink";
import {
  firebaseApp,
  providerConfirmAppointmentCompleted
} from "@/config/firebase";
import theme from "@/config/theme";
import { useAsyncCallback } from "@/hooks/useAsyncCallback";
import { ServicePortal } from "@/layouts/ServicePortal";
import {
  CardContent,
  IconButton,
  InputBase,
  LinearProgress,
  Button
} from "@material-ui/core";
import { Send, Check } from "@material-ui/icons";
import fb from "firebase/app";
import * as React from "react";
import styled from "styled-components";

interface Message {
  key: string;
  messageType:
    | "normal"
    | "appointment-completed-confirmed"
    | "appointment-confirmed"
    | "payment-confirmed";
  params: {
    content: string;
  };
  senderRole: "customer" | "helper" | "server";
}

interface Props {
  messages: Message[];
  bookingId: string;
  header: any;
}

const MessagesContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: stretch;
  flex-direction: column;
`;

const SendWrapper = styled.div`
  position: relative;
  background-color: rgba(0, 0, 0, 0.05);
  display: flex;
  padding: 8px 16px;
`;
const SendField = styled(InputBase)`
  width: 100%;
  font-size: 16px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 0;
`;

const SendButton = styled(IconButton)`
  position: absolute;
  top: 0;
`;

const CustomerMessage = styled.div`
  padding: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  max-width: 80%;
  align-self: start;
  border-radius: 8px;
  margin-bottom: 16px;
`;

const HelperMessage = styled.div`
  padding: 8px;
  background-color: ${theme.palette.primary.main};
  max-width: 80%;
  align-self: end;
  border-radius: 8px;
  margin-bottom: 16px;
  color: #fff;
`;

const ServerMessage = styled.div`
  padding: 8px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 80%;
  align-self: center;
  border-radius: 8px;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.6);
`;

const MessagesWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const renderMessageContent = (message: Message) => {
  if (message.messageType === "appointment-completed-confirmed") {
    return "You confirmed the appointment was completed.";
  }

  if (message.messageType === "appointment-confirmed") {
    return "You confirmed you could meet with the client";
  }

  if (message.messageType === "payment-confirmed") {
    return null;
  }

  return message.params.content;
};

export const ChatBox = ({ header, booking, bookingId, messages }: Props) => {
  const [messageState, onChangeMessage] = React.useState();
  const [loading, setLoading] = React.useState();

  const sendMessage = useAsyncCallback(async () => {
    if (messageState) {
      setLoading(true);

      await firebaseApp
        .firestore()
        .collection(`bookings/${bookingId}/messages`)
        .add({
          messageType: "normal",
          sentDate: fb.firestore.FieldValue.serverTimestamp(),
          senderRole: "helper",
          params: {
            content: messageState
          }
        });
      setLoading(false);
      onChangeMessage("");
    }
  }, [bookingId, messageState]);

  const onConfirmCompleted = useAsyncCallback(async () => {
    setLoading(true);

    await providerConfirmAppointmentCompleted({
      bookingId
    }).finally(() => setLoading(false));

    setLoading(false);
  }, [bookingId]);

  return (
    <ServicePortal
      header={header}
      actions={
        <div>
          {booking.status === "appointment-confirmed" ? (
            <div style={{ padding: "16px" }}>
              Please confirm once the appointment is completed.
              <Button
                style={{ marginLeft: "-8px" }}
                color="primary"
                size="small"
                onClick={onConfirmCompleted}
              >
                Confirm Completed
              </Button>
            </div>
          ) : null}
          <SendWrapper>
            <SendField
              value={messageState}
              onChange={e => onChangeMessage(e.target.value)}
              variant="filled"
            ></SendField>
            <SendButton size="small" onClick={sendMessage}>
              <Send />
            </SendButton>
          </SendWrapper>
          {loading && <LinearProgress />}
        </div>
      }
    >
      <MessagesContainer>
        <MessagesWrapper>
          {messages
            .slice(0)
            .reverse()
            .map(message => {
              const content = renderMessageContent(message);

              if (content === null) {
                return;
              }

              if (message.senderRole === "server") {
                return (
                  <ServerMessage key={message.key}>{content}</ServerMessage>
                );
              }

              if (message.senderRole === "helper") {
                return <HelperMessage>{content}</HelperMessage>;
              }

              if (message.senderRole === "customer") {
                return <CustomerMessage>{content}</CustomerMessage>;
              }
            })}
        </MessagesWrapper>
      </MessagesContainer>
    </ServicePortal>
  );
};
