import styled from "styled-components";
import { Tab, Tabs } from "@material-ui/core";
import { PropsOf } from "@/utils/types";
import * as React from "react";

export const FilterTab = styled(Tab)`
  && {
    text-transform: unset;
    min-width: 0;
    height: 100%;
  }
`;

const WithSize = ({
  size,
  ...props
}: PropsOf<typeof Tabs> & { size: "small" | "large"; component: any }) => {
  return <Tabs {...props}></Tabs>;
};

export const FilterTabs = styled(WithSize).attrs({
  classes: { flexContainer: "tab-container" }
})`
  && {
    height: auto;

    .tab-container {
      height: 100%;

      & > ${FilterTab} {
        height: ${props => (props.size === "small" ? "40px" : "56px")};
      }
    }
  }
`;
