import { CircularProgress, Link, Typography } from "@material-ui/core";
import * as React from "react";
import { firebaseApp } from "../config/firebase";
import { makeRule, rules, useForm } from "@/hooks/useForm";
import { withProps } from "../utils/withProps";
import { LoginButton } from "./LoginButton";
import { TextFormField } from "./TextFormField";
import { ForgotPassword } from "./ForgotPassword";

const variant = "outlined";
const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const authMessages = {
  "auth/user-not-found": "No account with this email exists.",
  "auth/wrong-password": "Your password is not correct."
};

const loginForm = {
  email: {
    field: withProps(TextFormField, {
      label: "Email",
      variant,
      margin: "normal"
    }),
    getErrors: makeRule("Please enter a valid email", email =>
      emailRegex.test(email)
    )
  },

  password: {
    field: withProps(TextFormField, {
      label: "Password",
      type: "password",
      variant,
      margin: "normal"
    }),
    getErrors: rules(
      makeRule("Please enter your password.", password => password != "")
    )
  }
};

const initialState = {
  email: "",
  password: ""
};

export const LoginForm = ({ onSuccess }) => {
  const [showForgotPassword, setForgotPassword] = React.useState(false);
  const [loading, setLoading] = React.useState();
  const [errorCode, setErrorCode] = React.useState();
  const [fields, details, errors, { setShowErrors }] = useForm(
    loginForm,
    initialState
  );

  const loginUser = React.useCallback(() => {
    if (errors.length === 0) {
      setLoading(true);
      firebaseApp
        .auth()
        .signInWithEmailAndPassword(
          details.email.trim(),
          details.password.trim()
        )
        .finally(() => {
          setLoading(false);
        })
        .then(onSuccess)
        .catch(e => {
          setErrorCode(e.code);
        });
    } else {
      setShowErrors(true);
    }
  }, [details, errors, setShowErrors]);

  return (
    <>
      {showForgotPassword && (
        <ForgotPassword onClose={() => setForgotPassword(false)} />
      )}
      {errorCode && (
        <Typography variant="subtitle2" color="error">
          {authMessages[errorCode] || "We're sorry, an unknown error occured."}
        </Typography>
      )}

      {fields.email}
      {fields.password}

      <LoginButton
        disabled={loading}
        onClick={loginUser}
        color="primary"
        variant="contained"
        size="large"
      >
        {!loading ? "Sign In" : <CircularProgress />}
      </LoginButton>
      <Link
        component="button"
        onClick={() => setForgotPassword(true)}
        color="textSecondary"
      >
        Forgot Password?
      </Link>
    </>
  );
};

export default LoginForm;
