import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  Typography,
  LinearProgress
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { firebaseApp } from "../config/firebase";
import { getSiteExternalLink } from "../config/site";
import { useAsyncCallback } from "@/hooks/useAsyncCallback";
import { makeRule, useForm } from "@/hooks/useForm";
import { withProps } from "../utils/withProps";
import { SuccessMessage } from "./SuccessMessage";
import { TextFormField } from "./TextFormField";
import { SiteDialog } from "@/components/SiteDialog";

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const emailForm = {
  email: {
    field: withProps(TextFormField, {
      label: "Email",
      variant: "outlined",
      margin: "normal"
    }),
    getErrors: makeRule("Please enter a valid email", email =>
      emailRegex.test(email)
    )
  }
};

const Container = styled(CardContent)`
  && {
    width: 400px;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
  }
`;

const Header = styled(Typography)`
  && {
    margin-bottom: 8px;
  }
`;

export const ForgotPassword = ({ onClose }) => {
  const [fields, { email }, errors, { setShowErrors }] = useForm(emailForm, {
    email: ""
  });

  useEffect(() => {
    if (status === "not-found") {
      setStatus(null);
    }
  }, [email]);

  const [status, setStatus] = useState(null);

  const sendReset = useAsyncCallback(async () => {
    if (errors.length > 0) {
      setShowErrors();
    } else {
      setStatus("loading");
      await firebaseApp
        .auth()
        .sendPasswordResetEmail(email, {
          url: getSiteExternalLink("/")
        })
        .then(() => setStatus("done"))
        .catch(e => {
          if (e.code === "auth/user-not-found") {
            setStatus("not-found");
          } else {
            throw e;
          }
        });
    }
  }, [errors, email, setShowErrors]);

  if (status === "done") {
    return (
      <Dialog open={true}>
        <Container>
          <SuccessMessage
            title="Done. We've sent instructions."
            message="You should see an email in your inbox within the next few minutes with instructions on how to reset your password."
          />
        </Container>
        <DialogActions>
          <Button
            color="primary"
            style={{ margin: "0 auto" }}
            onClick={onClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <SiteDialog open={true}>
      <Container>
        <Header variant="h6">Reset your password</Header>
        {fields.email}

        {status === "not-found" && (
          <Typography color="error" variant="caption">
            We couldn't find a user with this email address.
          </Typography>
        )}
      </Container>

      <DialogActions>
        <Button style={{ marginRight: "auto" }}>Cancel</Button>

        <Button
          color="primary"
          onClick={sendReset}
          disabled={status === "loading"}
        >
          Send Reset
        </Button>
      </DialogActions>
      {status === "loading" && <LinearProgress />}
    </SiteDialog>
  );
};
