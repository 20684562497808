import { useBookingContext } from "@/hooks/booking";
import {
  CardContent,
  List,
  ListItem,
  ListItemIcon,
  Typography
} from "@material-ui/core";
import { Check } from "@material-ui/icons";
import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { AddressForm } from "./AddressForm";

const FormQuestionContainer = styled(CardContent)``;
const AddressContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: stretch;
  padding: 16px;

  && {
    & > * {
      margin-bottom: 8px;
    }
  }
`;

const QuestionList = styled(List)`
  && {
    padding: 0;
  }
`;

const ListSelectItem = ({ children, selected, onSelect }) => (
  <ListItem button onClick={onSelect} selected={selected}>
    {children}
    {selected && (
      <ListItemIcon>
        <Check color="primary" />
      </ListItemIcon>
    )}
  </ListItem>
);

const emptyAddress = {
  address1: "",
  address2: "",
  city: "",
  state: "PR",
  zipCode: ""
};

const andList = serviceAreas => {
  if (serviceAreas.length === 1) {
    return serviceAreas[0];
  }

  const items = [...serviceAreas];
  const lastItem = items.pop();

  return items.join(", ") + ` and ${lastItem}`;
};

export const SelectBookingLocation = ({ onNext: onNextPage, buttons }) => {
  const {
    booking: { location, provider },
    setLocation
  } = useBookingContext();

  const [address, setAddress] = useState(
    location && location.options ? location.options : emptyAddress
  );
  const [addressValid, setAddressValid] = useState(false);

  const onNext = useCallback(() => {
    if (location.type === "client") {
      setLocation(location.set("options", address));
    }

    onNextPage();
  }, [address, location, setLocation]);

  return (
    <div>
      {buttons({
        onNext,
        disableNext: location.type === "business" ? false : !addressValid
      })}

      <FormQuestionContainer>
        <Typography component="h4" variant="h6" style={{ fontWeight: 500 }}>
          Where would you like {provider.name} to meet you?
        </Typography>

        <Typography>
          {provider.name} serves customers in {andList(provider.serviceAreas)}
        </Typography>
      </FormQuestionContainer>

      {location.type === "client" && (
        <AddressContainer>
          <AddressForm
            value={address}
            onChange={setAddress}
            onSetValid={setAddressValid}
          />
        </AddressContainer>
      )}
    </div>
  );
};
