import { Tab, Tabs } from "@material-ui/core";
import * as React from "react";
import { SiteLink } from "./SiteLink";

interface Props {
  active: keyof typeof values;
  onChange: (name: string) => void;
  className?: string;
}

const values = {
  createAccount: 0,
  signIn: 1
};

export const LoginTabs = ({ active, onChange, className }: Props) => {
  const getTabProps = (name: string, href: string) =>
    onChange != null
      ? { onChange: () => onChange(name) }
      : { component: SiteLink, href };

  return (
    <Tabs
      className={className}
      value={values[active]}
      variant="fullWidth"
      indicatorColor="primary"
    >
      <Tab
        label="Create Account"
        {...getTabProps("createAccount", `/auth/join${window.location.search}`)}
      />
      <Tab
        label="Sign in"
        {...getTabProps("signIn", `/auth/sign-in${window.location.search}`)}
      />
    </Tabs>
  );
};
