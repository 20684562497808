import { Button } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import * as React from "react";
import { useState } from "react";
import { CreateServiceDialog } from "./CreateServiceDialog";

interface Props {
  className?: string;
  providerId: string;
}

export const CreateService = ({ className, providerId }: Props) => {
  const [createService, setCreateService] = useState(false);

  return (
    <>
      {createService && (
        <CreateServiceDialog
          providerId={providerId}
          onClose={() => setCreateService(false)}
        />
      )}

      <Button
        color={"primary"}
        size={"small"}
        className={className}
        onClick={() => setCreateService(true)}
      >
        <Add /> Create Service
      </Button>
    </>
  );
};
