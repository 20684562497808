import React, { useState } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Input,
  FilledInput,
  OutlinedInput,
  FormHelperText
} from "@material-ui/core";
import { makeFormField } from "../utils/makeFormField";

const inputs = {
  filled: FilledInput,
  standard: Input,
  outlined: OutlinedInput
};

export const SelectFormField = makeFormField(
  ({
    onSetPristine,
    options,
    label,
    errors,
    isError,
    variant = "standard",
    ...props
  }) => {
    const Input = inputs[variant];
    const inputLabel = React.useRef();
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
      setLabelWidth(inputLabel.current.offsetWidth);
    }, [label]);

    return (
      <FormControl variant={variant} margin="dense">
        <InputLabel ref={inputLabel} variant={variant} error={isError}>
          {label}
        </InputLabel>
        <Select
          onClose={e => onSetPristine(false)}
          error={isError}
          input={
            variant === "outlined" ? (
              <Input labelWidth={labelWidth} />
            ) : (
              <Input />
            )
          }
          variant={variant}
          {...props}
        >
          {options.map(option => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {isError && (
          <FormHelperText error={isError}>{errors[0]}</FormHelperText>
        )}
      </FormControl>
    );
  }
);
