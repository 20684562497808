import { CheckboxFormField } from "@/components/CheckboxFormField";
import { GrowLoading } from "@/components/GrowLoading";
import { StepHeader } from "@/components/providerSetup/styles";
import { ServiceProvider, useProvider } from "@/config/collections/provider";
import { firebaseApp } from "@/config/firebase";
import { awaitData, isLoading, Load, LOADING } from "@/hooks/awaitData";
import { Button, CardContent, InputLabel, Typography } from "@material-ui/core";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { useSnackbar } from "notistack";
import * as React from "react";

export const askForPermissioToReceiveNotifications = async (
  serviceProvider: any
) => {
  try {
    const messaging = firebaseApp.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();

    await firebaseApp
      .firestore()
      .doc(serviceProvider.key)
      .update({
        notificationSettings: [
          ...(serviceProvider.noticationSettings || []),
          {
            alertType: "push",
            token
          }
        ]
      });

    return token;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const turnOffPush = async (provider: ServiceProvider) => {
  firebaseApp
    .messaging()
    .getToken()
    .then(token => {
      const settings = provider.notificationSettings.filter(
        setting => setting.alertType === "push" && setting.token !== token
      );

      return firebaseApp
        .firestore()
        .doc(provider.key)
        .update({
          notificationSettings: settings
        });
    })
    .catch((e: any) => {
      console.error(e);
    });
};
const usePushNotificationsRegistered = (provider: ServiceProvider) => {
  const [hasToken, setHasToken] = React.useState(LOADING as Load<boolean>);

  React.useEffect(() => {
    if (Notification.permission === "granted" && !isLoading(provider)) {
      const token = firebaseApp
        .messaging()
        .getToken()
        .then(token => {
          const setting = provider.notificationSettings.find(
            setting => setting.alertType === "push" && setting.token === token
          );
          setHasToken(setting != null);
        })
        .catch((e: any) => {
          console.error(e);
          setHasToken(false);
        });
    } else {
      setHasToken(false);
    }
  }, [provider]);

  return hasToken;
};

const notificationSettings = {
  sendEmail: {
    field: CheckboxFormField
  }
};
const Notifications = () => {
  const provider = useProvider();
  const snackBar = useSnackbar();
  const pushNotifications = usePushNotificationsRegistered(provider);

  return (
    <>
      <CardContent style={{ paddingTop: 0, paddingBottom: 0 }}>
        <StepHeader>Notification Settings</StepHeader>
      </CardContent>
      {awaitData(provider, {
        loading() {
          return <GrowLoading />;
        },

        data(provider) {
          return (
            <>
              <CardContent>
                <InputLabel style={{ marginBottom: "8px" }}>
                  Push Notifications
                </InputLabel>
                {awaitData(pushNotifications, {
                  loading() {
                    return <GrowLoading />;
                  },

                  data(hasPush) {
                    console.log(hasPush);
                    if (hasPush) {
                      return (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center"
                          }}
                        >
                          <CheckCircle />
                          <Typography
                            variant="caption"
                            style={{ marginLeft: "8px" }}
                          >
                            {" "}
                            Enabled for this device{" "}
                          </Typography>{" "}
                          <Button
                            size="small"
                            color="primary"
                            style={{ marginLeft: "auto" }}
                            onClick={() => turnOffPush(provider)}
                          >
                            Turn Off
                          </Button>
                        </div>
                      );
                    }

                    return (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <Typography variant="caption">
                          Not enabled on this device{" "}
                        </Typography>{" "}
                        <Button
                          size="small"
                          color="primary"
                          style={{ marginLeft: "auto" }}
                          onClick={() => {
                            askForPermissioToReceiveNotifications(
                              provider
                            ).then(success => {
                              if (success === false) {
                                snackBar.enqueueSnackbar(
                                  "There was an issue enabling push notifications, please try again later.",
                                  { variant: "error" }
                                );
                              } else {
                                snackBar.enqueueSnackbar(
                                  "Enabled push notifications",
                                  {
                                    variant: "success"
                                  }
                                );
                              }
                            });
                          }}
                        >
                          Enable
                        </Button>
                      </div>
                    );
                  }
                })}
              </CardContent>
            </>
          );
        }
      })}
    </>
  );
};

export default Notifications;
