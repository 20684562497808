import * as React from "react";
import { HeroHeader } from "@/components/HeroHeader";
import { SitePage } from "@/layouts/SitePage";
import { SearchPage } from "@/components/SearchPage";

interface Props {
  dialog?: any;
}

export default ({ dialog }: Props) => {
  return (
    <SitePage gray>
      <HeroHeader />
      <SearchPage />
      {dialog}
    </SitePage>
  );
};
