import { SiteDialog } from "@/components/SiteDialog";
import {
  Button,
  CardContent,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";

const Content = styled(CardContent)`
  max-width: 500px;
`;

export const ConfirmButton = ({
  confirmTitle,
  confirmBody,
  confirmAction,
  denyAction,
  onConfirmed,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Button {...props} onClick={() => setOpen(true)} />
      <SiteDialog open={open}>
        <DialogTitle>{confirmTitle}</DialogTitle>

        <DialogContent>
          <DialogContentText variant="body1">{confirmBody}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button color="inherit" onClick={() => setOpen(false)}>
            {denyAction}
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setOpen(false);
              onConfirmed();
            }}
          >
            {confirmAction}
          </Button>
        </DialogActions>
      </SiteDialog>
    </>
  );
};
