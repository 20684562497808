import {
  createDraftCollection,
  useMutation,
  useSelector,
  selectDocument,
  selectDraftDocument,
  useSavePatch,
  useMakeDraft,
  useDraftState
} from "@/config/draftCollection";
import { take } from "rxjs/operators";
import { useEffect, useState, useCallback } from "react";
import { v4 as uuid } from "uuid";
import { LOADING, awaitData } from "@/hooks/awaitData";
import { useUserProfile, UserProfile } from "@/hooks/useUserProfile";
import { pick } from "ramda";
import { useRequireUser } from "@/hooks/useCurrentUser";
import { firebaseApp, createServiceProvider } from "../firebase";
import { useAsyncCallback } from "@/hooks/useAsyncCallback";

export interface Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipCode: string;
}

export interface ServiceProvider {
  businessAddress?: Address;
  contactNumber: number | null;
  featureImages: string[];
  name?: string;
  notificationSettings: {
    alertType: string;
    channel: { emailAddress: string; type: string };
    token: string;
  }[];
  serviceCategories: string[];
  serviceDescription?: string;
  serviceShortName?: string;
  referralCode?: string;
  expressConnectAccount?: string;
  serviceAreas: string[];
  public?: boolean;
  key: string;
  id: string;
}

export const providerCollection = createDraftCollection({
  path: "serviceProviders",

  mutations: {
    createProvider(
      provider: ServiceProvider,
      { id, key }: { id: string; key: string }
    ) {
      return {
        key,
        id,
        name: "",
        featureImages: [],
        public: false,
        notificationSettings: [],
        serviceCategories: [],
        serviceDescription: "",
        contactNumber: null,
        serviceShortName: "",
        serviceAreas: []
      } as ServiceProvider;
    },

    updateProvider(provider: ServiceProvider, patch: Partial<ServiceProvider>) {
      return {
        ...provider,
        ...patch
      };
    },

    setContactInfo(
      provider: ServiceProvider,
      info: { address: Address; contactNumber: number }
    ) {
      return {
        ...provider,
        businessAddress: info.address,
        contactNumber: info.contactNumber
      };
    }
  }
});

export const useProvider = () => {
  const userProfile = useUserProfile();
  return useSelector(
    selectDocument,
    providerCollection,
    awaitData(userProfile, { data: profile => profile.serviceProvider.path })
  );
};

export const useExistingOrNewProvider = (referralCode?: string) => {
  const setCode = useSaveProvider(["referralCode"]);
  const createProvider = useMutation(providerCollection, "createProvider");
  const updateProvider = useMutation(providerCollection, "updateProvider");
  const [id, setId] = useState(LOADING as typeof LOADING | string);
  useRequireUser();
  const userProfile = useUserProfile();
  const makeDraft = useMakeDraft(providerCollection);

  useEffect(() => {
    if (userProfile !== LOADING) {
      if (userProfile.serviceProvider != null) {
        makeDraft(userProfile.serviceProvider.path).then(() =>
          setId(userProfile.serviceProvider.path)
        );
      } else {
        if (id === LOADING) {
          const id = uuid();
          const key = `serviceProviders/${id}`;
          createProvider(key, { id, key });
          setId(key);
        }
      }
    }
  }, [userProfile]);

  useEffect(() => {
    if (referralCode != null && id !== LOADING) {
      updateProvider(id, { referralCode });
    }
  }, [referralCode, id]);

  return useSelector(selectDraftDocument, providerCollection, id);
};

export const useSaveProvider = (keys: (keyof ServiceProvider)[]) => {
  const saveData = useSavePatch(providerCollection, pick(keys));
  const userProfile = useUserProfile() as UserProfile;
  const draftState = useDraftState();

  return useAsyncCallback(
    async (id: string) => {
      if (userProfile.serviceProvider == null) {
        const value = await draftState
          .get(providerCollection.path, id)
          .pipe(take(1))
          .toPromise();
        await createServiceProvider(value);
      } else {
        await saveData(id);
      }
    },
    [saveData, draftState, userProfile]
  );
};

export const useSaveContactInformation = () => {
  const saveProvider = useSaveProvider(["businessAddress", "contactNumber"]);
  const saveContactInfo = useMutation(providerCollection, "setContactInfo");
  return useCallback(
    (id: string, contactNumber: number, address: Address) => {
      saveContactInfo(id, { address, contactNumber });
      return saveProvider(id);
    },
    [saveProvider, saveContactInfo]
  );
};

export const makePatchAndSave = <Keys extends keyof ServiceProvider>(
  keys: Keys[]
) => () => {
  const saveProvider = useSaveProvider(keys);
  const updateProvider = useMutation(providerCollection, "updateProvider");

  return useCallback(
    (id: string, patch: Pick<ServiceProvider, Keys>) => {
      updateProvider(id, patch);
      return saveProvider(id);
    },
    [saveProvider, updateProvider]
  );
};

export const useSaveServiceAreas = makePatchAndSave(["serviceAreas"]);

export const useSaveProfileDescription = makePatchAndSave([
  "serviceDescription"
]);
