import { PropsOf } from "@/utils/types";
import { TextField } from "@material-ui/core";
import * as React from "react";
import styled, { css } from "styled-components";
import { makeFormField } from "../utils/makeFormField";
const { default: NumberFormat } = require("react-number-format");

const normalMargin = css`
  && {
    margin-bottom: ${(props: PropsOf<typeof TextField>) =>
      props.helperText ? "0" : "20px"};
    margin-top: 8px;
  }
`;

const Field = styled(TextField)`
  ${props => props.margin === "normal" && normalMargin}
`;

export const NumberFormatField = makeFormField<
  string,
  PropsOf<typeof TextField> & {
    mask?: string;
    thousandSeperator?: string;
    prefix?: string;
  }
>(
  ({
    onSetPristine,
    value,
    mask,
    onChange,
    margin = "dense",
    errors,
    isError,
    ...props
  }) => {
    return (
      <NumberFormat
        type="text"
        value={value}
        onChange={onChange}
        format={mask}
        error={isError}
        onBlur={() => onSetPristine(false)}
        helperText={isError && errors[0]}
        margin={margin}
        {...props}
        customInput={Field}
      ></NumberFormat>
    );
  }
);
