import * as React from "react";
import { ComponentType } from "react";

export type PropsOf<T> = T extends ComponentType<infer props>
  ? props
  : ElProps<T>;
export type NotOpt<T> = T extends {} ? T : never;
export type ElProps<T> = T extends keyof React.ReactHTML
  ? React.ReactHTML[T] extends (props: infer props, ...args: any[]) => any
    ? NotOpt<props>
    : never
  : never;

export const t = <V>() => (null as any) as V;

export const props = <
  T extends keyof React.ReactHTML | ComponentType<any>,
  P extends {}
>(
  el: T,
  values: P
): ComponentType<PropsOf<T> & P> => {
  return props => {
    const newProps = { ...props };

    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        delete newProps[key];
      }
    }

    return React.createElement(el, newProps);
  };
};
